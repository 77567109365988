import React, { useEffect, useState } from "react";
import ConfirmDialog from "../../../components/actionDialogs/confirmDialog";
import Loader from "../../../components/loader/loader";
import Snackbar from "../../../components/snackbar/snackbar";
import CustomTable from "../../../components/table/table";
import {
  admin_domains_delete,
  admin_domains_list,
  admin_qualification_list,
} from "../admin.api";
import ManageDomain from "./manage/manage";
import "./view.css";
import jwtDecode from "jwt-decode";
import SearchButton from "../../../components/buttons/search/search-btn";
import TextField from "../../../components/inputs/textfield";
// import SearchButton from "../../../components/inputs/searchButton";

export default function View(props) {
  const [isActive, setIsActive] = useState(true);
  let adminType = Number(jwtDecode(props.state.payload).user_type);
  const [data, setdata] = useState([]);
  const [fdata, setFdata] = useState([]);
  const [addNew, setAddNew] = useState(false);
  const [view, setView] = useState(false);
  const [formData, setFormData] = useState({});
  const [activeData, setActiveData] = useState({});
  const [openAction, setOpenAction] = useState(false);
  const [edit, setEdit] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [search, setSearch] = useState("");

  const handleEventList = () => {
    setIsActive(true);
    admin_qualification_list().then((res) => {
      console.log(res);
      const qualArray = res.data.flatMap((item) =>
        item.children_recursive.flatMap((subItem) =>
          subItem.children_recursive.map((sub) => ({
            qualMain: item.name,
            qualSub: subItem.name,
            subStream: sub.name,
            subId: sub.id,
          }))
        )
      );
      setdata(qualArray);
      setFdata(qualArray)
      setIsActive(false);
    });
  };
  const handleAddNew = () => {
    setAddNew(true);
    setView(false);
  };
  const handleView = (data) => {
    setFormData(data);
    setAddNew(true);
    setView(true);
  };
  const handleDelete = (data) => {
    setActiveData(data);
    setOpenAction(true);
  };
  const handleClose = () => {
    setAddNew(false);
    setEdit(false);
    setFormData({});
    handleEventList();
  };

  const handleCancelDelete = () => {
    setOpenAction(false);
  };
  const handleDeleteConfirm = (data) => {
    setOpenAction(false);
    admin_domains_delete(data.uuid)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
      });
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      handleEventList();
    }
  };
  const handleSearch = () => {
    const searchArray = [];
    fdata.forEach((item, i) => {
      if (search && item.subStream.includes(search)) {
        searchArray.push(item);
      } else if (!search) {
        handleEventList();
      }
    });

    setdata(searchArray);
  };
  const handleClear = () => {
    setSearch("")
    handleEventList();
  }
  const handleEdit = (data) => {
    setFormData(data);
    setView(false);
    setEdit(true);
    setAddNew(true);
  };
  useEffect(() => {
    handleEventList();
  }, [!data]);
  console.log(data);
  return (
    <div>
      <Loader open={isActive} />
      <div className="view-container">
        <h6>
          <b>Qualifications</b>
        </h6>
        <hr />

        {/* <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={(e) => handleAddNew(e)}
        >
          Add New Domain
        </button> */}
        {/* <button type="button" className="btn   btn-primary btn-sm domain-btn">
          New Domain Suggestion
        </button> */}
        <div className="d-flex">
          <div className="col-5 ml-2 mr-2">
            <TextField
              id="outlined-basic"
              name="search"
              variant="outlined"
              value={search ?? "Search"}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="col-5 d-flex">
            <SearchButton {...props} onClick={() => handleSearch()} />
            <div className="mx-2">
              <div className="clearSearch" onClick={() => handleClear()}>Clear Search</div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <CustomTable
            {...props}
            columns={[
              { title: "Qualification Type", field: "qualMain" },
              { title: "Qualification", field: "qualSub" },
              { title: "Stream", field: "subStream" },
              // { title: "Qualification", field: "subId" },
            ]}
            actions={
              adminType === 2
                ? [
                    // { type: "view", handleClick: handleView },
                    { type: "edit", handleClick: handleEdit },
                    // { type: "delete", handleClick: handleDelete },
                  ]
                : [
                    // { type: "view", handleClick: handleView },
                    { type: "edit", handleClick: handleEdit },
                  ]
            }
            data={data}
          />
        </div>
        {/* <CustomTable
          {...props}
          columns={[
            { title: "New Domain Suggestion", field: "domain" },
            { title: "Action", field: "action", type: "button" },
          ]}
          data={[
            {
              domain: "Electronic / Electrod Manufacturing",
              action: "delt btn",
            },
            {
              domain: "Electronic / Electrod Manufacturing",
              action: "delt btn",
            },
            {
              domain: "Electronic / Electrod Manufacturing",
              action: "delt btn",
            },
          ]}
        /> */}
      </div>
      {addNew && (
        <ManageDomain
          open={addNew}
          edit={edit}
          view={view}
          handleClose={handleClose}
          formData={formData}
        />
      )}
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      {openAction && (
        <ConfirmDialog
          open={openAction}
          title="Delete Domain ?"
          data={activeData}
          message="Are you sure to delete this Domain?"
          secondary_action="Cancel"
          primary_action="Delete"
          handleClose={handleCancelDelete}
          handleConfirm={handleDeleteConfirm}
        />
      )}
    </div>
  );
}
