import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./style.css";

function PaymentDetailsModal(props) {
  const handleClose = () => {
    props.setDetailsOpen(false);
    // window.open(props.pdf, "_blank");
    // props.setPdf("");
  };
  console.log("opened");
  return (
    <div className="payment-modal-parent">
      <Modal
        {...props}
        show={props.detailsOpen}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="payment-modal">
          <Modal.Title id="contained-modal-title-vcenter">
            Payment Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className=""><table><tr><th></th></tr></table></div> */}
          <div className="">
            Thank you. You may kindly volunteer to a nominal service charge
            based on the job's qualification requirements. Please find the below
            details.
          </div>
         
          <br />
          
          
          
          <div>
            <table className="payment-table">
              <thead>
                <tr className="col-12">
                  <th className="col-6">Qualification Details</th>
                  <th className="col-6">Payment Expected (incl GST)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Below Graduation</td>
                  <td>₹500</td>
                </tr>
                <tr>
                  <td>Graduation</td>
                  <td>₹1000</td>
                </tr>
                <tr>
                  <td>Post-Graduation</td>
                  <td>₹2000</td>
                </tr>
                <tr>
                  <td>PHD</td>
                  <td>₹5000</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <div className="">
          <b>Please see the bank details.</b>
          </div>
          <br />
          <div>
            <table className="payment-table-bank">
              <tbody>
                <tr>
                  <td className="col-6 fw-bold">Bank Name</td>
                  <td>Canara Bank</td>
                </tr>
                <tr>
                  <td className="col-6 fw-bold">Bank Branch</td>
                  <td>Pattom</td>
                </tr>
                <tr>
                  <td className="col-6 fw-bold">Account Number</td>
                  <td>110133972146</td>
                </tr>
                <tr>
                  <td className="col-6 fw-bold">IFSC Code</td>
                  <td>CNRB0002791</td>
                </tr>
                <tr>
                  <td className="col-6 fw-bold">GST No</td>
                  <td>32AAMAK2734Q1Z7</td>
                </tr>
              </tbody>
            </table>
            <br />
            <b>Please contact on 7306433157 for any doubts.</b>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => handleClose()}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PaymentDetailsModal;
