import React from "react";
import { Route, Redirect } from "react-router-dom";
import AdminLayout from "../../layout/adminlayout/layout.component";

const AdminRoute = ({ component: Component, ...rest }) => {
  let admin = rest.state.isAdminAuthenticated;

  return (
    <Route
      {...rest}
      render={(props) =>
        admin ? (
          <AdminLayout {...props} {...rest} key={rest.location?.key}>
            <Component {...props} {...rest} key={rest.location?.key} />
          </AdminLayout>
        ) : (
          <Redirect replace to="/admin-login" />
        )
      }
    />
  );
};

export default AdminRoute;
