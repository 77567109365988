import React from "react";
import "./adminlayout.style.css";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import NavMenu from "./components/navmenu/navmenu";

export default function AdminLayout(props) {
  const children = props.children;
  return (
    <div className="AdminLayout">
      {props.state.isAdminAuthenticated && (
        <div className="admin-header-container">
          <Header {...props} />
        </div>
      )}
      <div
        className={
          props.state.isAdminAuthenticated
            ? "admin-body-container"
            : "admin-body-container full-hght"
        }
      >
        {props.state.isAdminAuthenticated && (
          <div className="navbar-container">
            <NavMenu {...props} />
          </div>
        )}
        <div
          className={
            props.state.isAdminAuthenticated ? "admin-body-items" : "w-100"
          }
        >
          {children}
        </div>
      </div>
      {props.state.isAdminAuthenticated && (
        <div className="admin-footer">
          <Footer {...props} />
        </div>
      )}
    </div>
  );
}
