import React, { useEffect, useState } from "react";
import ConfirmDialog from "../../../components/actionDialogs/confirmDialog";
import Loader from "../../../components/loader/loader";
import Snackbar from "../../../components/snackbar/snackbar";
import CustomTable from "../../../components/table/table";
import {
  admin_location_list,
  admin_spacearea_create,
  admin_spacearea_list,
} from "../admin.api";
import ManageArea from "./manage/manage";
import "./view.css";

export default function View(props) {
  const [locationData, setLocationData] = useState([]);
  const [baseLocationData, setBaseLocationData] = useState([]);
  const [spaceLocationData, setSpaceLocationData] = useState([]);
  const [snackMessage, setSnackMessage] = useState("");
  const [areaType, setAreaType] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [activeData, setActiveData] = useState({});
  const [openAction, setOpenAction] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [formData, setFormData] = useState({});
  const [edit, setEdit] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [view, setView] = useState(false);

  const fetchLocation = () => {
    setIsActive(true);
    admin_location_list().then((res) => {
      let tdata = res.data;
      tdata.forEach((item, index) => {
        item["slno"] = index + 1;
      });
      setLocationData(tdata);
      setIsActive(false);
    });
  };

  const fetchSpaceLocation = () => {
    setIsActive(true);
    admin_spacearea_list().then((res) => {
      setSpaceLocationData(res.data);
      setIsActive(false);
    });
  };
  const onInit = () => {
    fetchLocation();

    fetchSpaceLocation();
  };

  const handleViewFour = (data) => {
    setAreaType("ownland");
    setFormData(data);
    setAddNew(true);
    setView(true);
  };

  const handleEditFour = (data) => {
    setAreaType("ownland");
    setFormData(data);
    setView(false);
    setEdit(true);
    setAddNew(true);
  };

  const handleDelete = (data) => {
    setActiveData(data);
    setOpenAction(true);
  };
  const handleAddNew = (e, value) => {
    setAddNew(true);
    setView(false);
    setAreaType(value);
  };
  const handleCancelDelete = () => {
    setOpenAction(false);
  };
  const handleDeleteConfirm = (data) => {
    setOpenAction(false);
    // admin_tender_delete(data.uuid)
    //   .then((res) => {
    //     setSnackMessage(res.message);
    //     setIsSnackError(false);
    //     setSnackOpen(true);
    //   })
    //   .catch((error) => {
    //     setSnackMessage(error.message);
    //     setIsSnackError(true);
    //     setSnackOpen(true);
    //   });
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      // handleEventList();
    }
  };
  const handleClose = (id) => {
    setAddNew(false);
    setEdit(false);
    setFormData({});
    if (id) {
      fetchSpaceLocation();
    }
    // handleEventList();
  };
  useEffect(() => {
    onInit();
  }, []);
  const onSearch = () => {
    //
  };
  const handlesubmit = (id, data) =>
    admin_spacearea_create(data).then((res) => res);

  return (
    <div>
      <Loader open={isActive} />
      <div className="view-container">
        <h6>
          <b>Joint Venture</b>
        </h6>
        <hr />

        <br />
        <button
          type="button"
          className="btn btn-primary btn-sm mt-2"
          onClick={(e) => handleAddNew(e, "joint")}
        >
          Add New
        </button>
        <CustomTable
          {...props}
          columns={[
            { title: "SI No", field: "si" },
            { title: "Location", field: "location" },

            { title: "Status", field: "status" },
          ]}
          data={[
            {
              si: "1",
              location: "Kollam",

              status: "pending,approved btn",
            },
          ]}
          actions={[
            { type: "view", handleClick: handleViewFour },
            { type: "edit", handleClick: handleEditFour },
            { type: "delete", handleClick: handleDelete },
          ]}
        />
      </div>
      {addNew && (
        <ManageArea
          open={addNew}
          edit={edit}
          areaType={areaType}
          view={view}
          submit={handlesubmit}
          areaId={4}
          handleClose={handleClose}
          formData={formData}
          locationData={locationData}
        />
      )}
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      {openAction && (
        <ConfirmDialog
          open={openAction}
          title="Delete This Area ?"
          data={activeData}
          message="Are you sure to delete this area?"
          secondary_action="Cancel"
          primary_action="Delete"
          handleClose={handleCancelDelete}
          handleConfirm={handleDeleteConfirm}
        />
      )}
    </div>
  );
}
