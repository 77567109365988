import React, { useState } from "react";
import { useParams } from "react-router-dom";
import CustomTable from "../../../components/table/table";
import "./view.css";
import jwtDecode from "jwt-decode";
export default function View(props) {
  const [data, setData] = useState([]);
  const [isActive, setIsActive] = useState([]);
  const [url, setUrl] = useState("");
  const eventParams = useParams();
  let adminType = Number(jwtDecode(props.state.payload).user_type);

  const handleEdit = () => {};
  const handleDownload = () => {};

  return (
    <div>
      {/* <Loader open={isActive} /> */}
      <div className="view-container">
        <span className="d-flex justify-content-between">
          <h6 className="mb-0 pt-2">
            <b>Professional Body Details</b>
          </h6>
          {/*      
        <button type="button" className="btn btn-primary">
          Back to List
        </button> */}
        </span>
        <hr />
        {/* <button
          type="button"
          className="btn btn-secondary btn-sm  mb-3"
          onClick={(e) => {
            props.history.push("/event-list/");
          }}
        >
          Back
        </button> */}
        <div className="data">
          <div className="row">
            <div className="col-3">Company Name</div>
            <div className="col-1">:</div>
            <div className="col-7">sdf</div>
          </div>
          <div className="row">
            <div className="col-3">Logo</div>
            <div className="col-1">:</div>
            <div className="col-7">
              <img src="" alt="" />
            </div>
          </div>
          <div className="row">
            <div className="col-3">Website Address</div>
            <div className="col-1">:</div>
            <div className="col-7">sdf</div>
          </div>
          <div className="row">
            <div className="col-3">Location</div>
            <div className="col-1">:</div>
            <div className="col-7">bnnnnnnnn</div>
          </div>
          <div className="row">
            <div className="col-3">Short Description</div>
            <div className="col-1">:</div>
            <div className="col-7">mmmmmmmm</div>
          </div>
          <div className="row">
            <div className="col-3">Key Members</div>
            <div className="col-1">:</div>
            <div className="col-7 mt-00">
              <CustomTable
                className="mt-00"
                {...props}
                columns={[
                  { title: "Image", field: "image" },
                  { title: "Full Name", field: "name" },
                  { title: "Designation", field: "desi" },
                  { title: "Email", field: "email" },
                ]}
                data={[
                  {
                    image: "[image]",
                    name: "Davoodh",
                    desi: "CEO",
                    email: "dfghjk@ssl",
                  },
                  {
                    image: "[image]",
                    name: "Davoodh",
                    desi: "CEO",
                    email: "dfghjk@ssl",
                  },
                  {
                    image: "[image]",
                    name: "Davoodh",
                    desi: "CEO",
                    email: "dfghjk@ssl",
                  },
                ]}
              />
            </div>
          </div>

          {adminType === 1 && (
            <div className="d-flex justify-content-center">
              {/* <button type="button" className="btn btn-approve btn-success mr-2">
                Submit for Acceptance
              </button> */}
            </div>
          )}
          {adminType === 2 && (
            <div className="d-flex justify-content-center">
              {/* <button type="button" className="btn btn-approve btn-success mr-2">
                Submit for Acceptance
              </button> */}
            </div>
          )}
          {adminType === 3 && (
            <div className="d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-approve btn-success mr-2"
              >
                Approve
              </button>
              <br />
              <button type="button" className="btn btn-reject  btn-danger ">
                Reject
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
