import React, { Component } from "react";
import { FormValidate } from "../../../../components/formValidate";
import Snackbar from "../../../../components/snackbar/snackbar";
import {
  admin_notification_add,
  admin_notification_update,
} from "../../admin.api";
import "./style.css";

let required = [
  "banner_location_text_ma",
  "notification_title_ma",
  "notification_desc_ma",
  "notification_desc_en",
];
class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      formData: props.formData,
      edit: props.edit,
      msgData: props.msgData,
      submitted: false,
      snackMessage: "",
      isSnackError: false,
      snackOpen: false,
    };
  }

  componentWillReceiveProps = (props) => {
    this.setState({
      open: props.open,
      edit: props.edit,
      formData: props.formData,
      msgData: props.msgData,
    });
  };
  handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({ formData: { ...this.state.formData, [name]: value } });
  };
  snackbarClose = (flag) => {
    this.setState({
      snackMessage: "",
      isSnackError: false,
      snackOpen: false,
    });
    if (!flag) {
      this.props.close();
    }
  };
  handleSubmit = (e) => {
    const error = FormValidate(this.state.formData, required);
    const { edit, formData } = this.state;
    e.preventDefault();

    if (Object.keys(error).length === 0) {
      if (edit) {
        admin_notification_update(formData.uuid, formData)
          .then((res) => {
            this.setState({
              submitted: true,

              snackMessage: res.message,
              isSnackError: false,
              snackOpen: true,
            });
          })
          .catch((error) => {
            this.setState({
              submitted: false,
              snackMessage: error.message,
              isSnackError: true,
              snackOpen: true,
            });
          });
      } else {
        admin_notification_add(this.state.formData)
          .then((res) => {
            this.setState({
              submitted: true,

              snackMessage: res.message,
              isSnackError: false,
              snackOpen: true,
            });
          })
          .catch((error) => {
            this.setState({
              submitted: false,
              snackMessage: error.message,
              isSnackError: true,
              snackOpen: true,
            });
          });
      }
    } else {
      this.setState({
        submitted: false,
        snackMessage: "Please fill all mandatory fields",
        isSnackError: true,
        snackOpen: true,
      });
    }
  };

  render() {
    const { submitted, formData, open, edit, msgData } = this.state;
    const { view } = this.props;
    console.log(this.props.msgData);
    return (
      <React.Fragment>
        {open && (
          <React.Fragment>
            <div className="overlay">
              <div className="big-dia1 dialog1">
                <Snackbar
                  message={this.state.snackMessage}
                  error={this.state.isSnackError}
                  open={this.state.snackOpen}
                  close={this.snackbarClose}
                />
                <div className="row">
                  <div className="flexDisplay">
                    <div className="diaTitleCon1">
                      {edit
                        ? "Message Log"
                        : view
                        ? "Message Log"
                        : "Message Log"}
                    </div>
                    <div className="dia-close" onClick={this.props.close}>
                      <div className="diaCloseCon">
                        <div className="diaClose">&#10005;</div>
                      </div>
                    </div>
                  </div>
                  <div className="tilebar">
                    <hr />
                  </div>

                  <div
                    // style={{ display: "flex", flexDirection: "column" }}
                    className=" msgbox"
                  >
                    {msgData?.map((item) => (
                      <>
                        {item.superadmin_message_to_admin && (
                          <div className="w-100">
                            <div className="adminbox">
                              <small>Super Admin</small>
                              <p>{item.superadmin_message_to_admin}</p>
                              {item.message && item.message !== "null" && (
                                <p>{item.message ?? ""}</p>
                              )}
                            </div>
                          </div>
                        )}

                        {item.admin_message_to_superadmin && (
                          <div className="w-100">
                            <div>
                              <div className="superbox">
                                <small>Admin</small>
                                <p>{item.admin_message_to_superadmin}</p>
                                {item.message && item.message !== "null" && (
                                  <p>{item.message ?? ""}</p>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {item.admin_message_to_user && (
                          <div className="w-100">
                            <div>
                              <div className="superbox">
                                <small>Admin</small>
                                <p>{item.admin_message_to_user}</p>
                                {item.message && item.message !== "null" && (
                                  <p>{item.message ?? ""}</p>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                  </div>
                  <center>
                    <button
                      type="button"
                      onClick={this.props.close}
                      className="btn btn-closex btn-sm"
                    >
                      Close
                    </button>
                  </center>
                  {/* <form action="">
                    <div className="row book-form">
                     
                       <div className="col-12">
                        <CustomButton
                          label={edit ? "Update" : "Add"}
                          disabled={submitted}
                          onClick={(e) => this.handleSubmit(e)}
                        />
                      </div> 
                    </div>
                  </form> */}
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default Notification;
