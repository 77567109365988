import React from "react";
import jwtDecode from "jwt-decode";

export default function Header(props) {
  let adminType = Number(jwtDecode(props.state.payload).user_type);
  //

  const logOut = () => {
    //
    props.adminLogout();
  };
  return (
    <div className="container-fluid">
      <div className="row m-auto">
        <div
          className="col-4 d-flex align-items-center logo-link"
          // onClick={() => {
          //   props.history.push("/");
          // }}
        >
          <img
            src="/resources/logo/logo.png"
            className="mt0 header-logo-image "
            alt="logo"
          />

          {/* <div className="admin-header-title hideonMobile">
            Kerala Spaceparks
          </div> */}
        </div>

        <div className="col-4 d-flex align-items-center justify-content-center">
          <div className="d-flex">
            <div />
          </div>
        </div>
        {props.state.isAdminAuthenticated && (
          <div className="col-4 d-flex align-items-center justify-content-end">
            <div className="d-flex">
              {/* <div>Logged in as &nbsp;</div> */}
              <div className="adminType">
                {adminType === 1
                  ? "Admin"
                  : adminType === 2
                  ? "Super Admin"
                  : adminType === 3
                  ? "Intermediate Admin"
                  : ""}
                &nbsp;
              </div>
            </div>
            <div className="d-flex gap" onClick={(e) => logOut(e)}>
              <div>
                <img src="/resources/admin/icons/user.png" alt="logout" />
              </div>
              <div className="logout">Logout</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
