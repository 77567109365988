import React, { useState } from "react";
import "./style.css";

const Marquee = (props) => {
  const [isActive, setIsActive] = useState(true);

  function handleMouseEnter(e) {
    console.log("entered");

    setIsActive(false);
  }

  function handleMouseLeave(e) {
    console.log("left");
    setIsActive(true);
  }

  return (
    <div
      className="marquee-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="marquee">
        <div
          className={isActive ? "marquee-content" : "marquee-content inactive"}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Marquee;
