import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom.min";
import App from "./App";
import "./index.css";
import "aos/dist/aos.css";
import reportWebVitals from "./reportWebVitals";
import { unregister as unregisterServiceWorker } from "./serviceWorker.js";

import ClickJack from "./clickJack";
ReactDOM.render(
  <React.StrictMode>
    {window.self === window.top ? (
      <>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <App />
        </BrowserRouter>
      </>
    ) : (
      <ClickJack />
      // <></>
    )}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
unregisterServiceWorker();
