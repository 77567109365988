import React, { Component } from "react";
import CustomIcons from "../../../components/icons/kIcons.component";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { user_news_list } from "../../user/user.api";
import "./tenders.css";
import { useEffect } from "react";
import { useState } from "react";

function Careers(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    user_news_list().then((res) => {
      setData(res?.data);
      // console.log(res?.data)
     
    });
  }, []);
  const handleNav = (linkurl) => {
    let urlLower = linkurl;//.toLowerCase();
    let urlStart = urlLower.substring(0, 4);
    if (urlStart === "http") {
      window.open(`${urlLower}`, "_blank");
    } else {
      window.open(`//${urlLower}`, "_blank");
    }
  };
  console.log(data);
  return (
    <>
      <div className="container-fluid event-bg">
        <div className="row m-55">
          <h3>{props.state.language === "ma" ? "News and Updates" : "News and Updates"}</h3>
        </div>
      </div>
      <div className="container-fluid">
        <div className="table-container overflow-auto">
          <table className="">
            {data.length > 0 ? (
              <tr className="">
                <th className="table-head">Title</th>
                <th className="table-head ">Description</th>
                <th className="table-head ">Link</th>
                <th className="table-head ">Downloads</th>

              </tr>
            ) : (
              <div
                className="d-flex justify-content-center"
                style={{ fontSize: "20px" }}
              >
                No Recent Postings
              </div>
            )}
            {console.log(data)}
            {data.map((card, i) => {
              return (
                <>
                  <tr>
                    <td className="m-2 text-left">{card.title}</td>
                    <td className="m-2 text-left">{card.description}</td>
                    {card.link && card.link !== "null"  ?
                    <td className="m-2 text-left parserfont" style={{cursor:"pointer"}} onClick={() => handleNav(card.link)}>Click Here</td> : <td></td>}
                    {card?.file &&
                    <td className="m-2">
                      <a href={`https://kspace.inovace.in/${card.file}`} target="_blank">
                        Download File
                      </a>
                    </td>}
                  </tr>
                </>
              );
            })}
          </table>
        </div>
      </div>
    </>
  );
}
export default Careers;
