import React from "react";
import "./events.css";
import Loader from "../../../components/loader/loader";
import EventDetail from "../../../components/page/event/event-detail/event.details.component";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { public_event_details } from "../public.api";
import BreadCrumbsComp from "../../../components/navigator/breadcrumbs";
import { useState } from "react";

export default function EventDetails(props) {
  const eventParams = useParams();
  const [data, setData] = useState({});
  const [isActive, setIsActive] = useState(true);
  let fromHome = sessionStorage.getItem("continueFrom");
  const fetchEventDetails = () => {
    public_event_details(eventParams.id).then((res) => {
      setData(res.data);
      setIsActive(false);
      // setUrl(res.current_url);
    });
  };
  useEffect(() => {
    fetchEventDetails();
  }, []);
  return (
    <div>
      <Loader open={isActive} />
      {/* ======================= Events  =================================================================== */}
      <BreadCrumbsComp
        section={fromHome === "events" ? "homeeventDetails" : "eventDetails"}
      />
      <div className="events ">
        <div className="container">
          <div className="tile-pad">
            <EventDetail {...props} data={data} isBookable={true} />
            {/* <Dialog open={true} /> */}
          </div>
        </div>
      </div>
      {/* =================================================================================================== */}
    </div>
  );
}
