import React, { useEffect, useState } from "react";
import "./view.css";

export default function Add() {
  const [eduQual, setEduQual] = useState([]);
  const [count, setCount] = useState(0);
  useEffect(() => {
    setEduQual([
      {
        exam: "",
        board: "",
        year: "",
        mark: "",
        upload: "",
      },
      setCount(0),
    ]);
  }, [eduQual, count]);

  const handleAddMore = (e, i) => {
    let data = {
      exam: "",
      board: "",
      year: "",
      mark: "",
      upload: "",
    };
    let eduData = eduQual;
    eduData.push(data);
    setCount(count + 1);
  };
  return (
    <div className="add-per">
      <div className="view-container">
        <h6>
          <b>Add Professional Bodies</b>
        </h6>
        <hr />

        <div className="row">
          <div className="col-8 mb-2">
            <label for="exampleInputEmail1">Company Name</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="col-8 mb-2">
            <label for="exampleInputEmail1">Website Address</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="col-8 mb-2">
            <label for="exampleInputEmail1">Location</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="col-8 mb-2">
            <label for="exampleInputEmail1">Short Description</label>
            <textarea
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          {/* <div className="col-8 mb-2">
            <label for="exampleInputEmail1">Email</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div> */}
          <div className="col-8">
            <label for="formFileSm" className="form-label mb-0">
              Upload Logo
            </label>
            <input
              className="form-control form-control-sm"
              id="formFileSm"
              name="upload"
              type="file"
            />
            <small className="pers">
              *Upload only .jpg , .jpeg , format and Size should be less than
              1MB of width=90 Height=84
            </small>
          </div>

          <br />

          {/* ===================== */}
          <div className="col-8">
            <label for="exampleInputEmail1" className="mb-2 mt-2">
              <b>Key Members</b>
            </label>
            <>
              <div className="row edu mb-3">
                <div className="col-6">
                  <label for="exampleInputEmail1">Full Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    name="exam"
                    aria-describedby="emailHelp"
                  />
                </div>
                <div className="col-6">
                  <label for="exampleInputEmail1">Designation</label>
                  <input
                    type="text"
                    className="form-control"
                    name="board"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                </div>
                <div className="col-6">
                  <label for="exampleInputEmail1">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="board"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                </div>

                <div className="col-6">
                  <label for="formFileSm" className="form-label mb-0">
                    Upload Photo
                  </label>
                  <input
                    className="form-control form-control-sm"
                    id="formFileSm"
                    name="upload"
                    type="file"
                  />
                  <small className="pers">
                    *Upload only .jpg , .jpeg , format and Size should be less
                    than 1MB of width=58 Height=58
                  </small>
                </div>
              </div>
              {/* ------------------------- */}
              <div className="row edu mb-3">
                <div className="col-6">
                  <label for="exampleInputEmail1"> Full Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    name="exam"
                    aria-describedby="emailHelp"
                  />
                </div>
                <div className="col-6">
                  <label for="exampleInputEmail1">Designation</label>
                  <input
                    type="text"
                    className="form-control"
                    name="board"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                </div>
                <div className="col-6">
                  <label for="exampleInputEmail1">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="board"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                </div>

                <div className="col-6">
                  <label for="formFileSm" className="form-label mb-0">
                    Upload Photo
                  </label>
                  <input
                    className="form-control form-control-sm"
                    id="formFileSm"
                    name="upload"
                    type="file"
                  />
                  <small className="pers">
                    *Upload only .jpg , .jpeg , format and Size should be less
                    than 1MB of width=58 Height=58
                  </small>
                </div>
              </div>
              {/* -------------------------------- */}
              <div className="row edu mb-2">
                <div className="col-6">
                  <label for="exampleInputEmail1"> Full Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    name="exam"
                    aria-describedby="emailHelp"
                  />
                </div>
                <div className="col-6">
                  <label for="exampleInputEmail1">Designation</label>
                  <input
                    type="text"
                    className="form-control"
                    name="board"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                </div>
                <div className="col-6">
                  <label for="exampleInputEmail1">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="board"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                </div>

                <div className="col-6">
                  <label for="formFileSm" className="form-label mb-0">
                    Upload Photo
                  </label>
                  <input
                    className="form-control form-control-sm"
                    id="formFileSm"
                    name="upload"
                    type="file"
                  />
                  <small className="pers">
                    *Upload only .jpg , .jpeg , format and Size should be less
                    than 1MB of width=58 Height=58
                  </small>
                </div>
              </div>
            </>
          </div>
          {/* ----------edu close-------- */}
          <div className="col-8">
            <center>
              <button type="button" className="btn btn-dark mt-3">
                Submit for Acceptance
              </button>
            </center>
          </div>
          {/* ---------------- */}
        </div>
      </div>
    </div>
  );
}
