import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./style.css";

function PaymentModal(props) {
  const [dis, setDis] = useState(false);
  const handleClose = () => {
    // props.setPaymentOpen(false);
    setDis(true)
  };
  const handleAgree = () => {
    props.handleCompanyDownload();
    props.setPaymentOpen(false);
    // props.setPaymentOpen(false);
    // window.open(props.pdf, "_blank");
    // props.setPdf("");
  };
  // console.log("opened");
  return (
    <div className="payment-modal-parent">
      <Modal
        {...props}
        show={props.paymentOpen}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="payment-modal">
          <Modal.Title id="contained-modal-title-vcenter">
            Confirmation for Jobseeker Profile Download
          </Modal.Title>
        </Modal.Header>
        
          <Modal.Body>
            {/* <div className=""><table><tr><th></th></tr></table></div> */}
            {!dis ? (
            <>
            <div className="">
              Thank you for the interest. Kindly update the status of
              recruitment in real-time. We expect to get a voluntary payment
              upon successful appointment of the candidate.
            </div>
            <br />
            <div>
              <table className="payment-table">
                <thead>
                  <tr className="col-12">
                    <th className="col-6">Qualification Details</th>
                    <th className="col-6">Payment Expected (incl GST)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Below Graduation</td>
                    <td>₹500</td>
                  </tr>
                  <tr>
                    <td>Graduation</td>
                    <td>₹1000</td>
                  </tr>
                  <tr>
                    <td>Post-Graduation</td>
                    <td>₹2000</td>
                  </tr>
                  <tr>
                    <td>PHD</td>
                    <td>₹5000</td>
                  </tr>
                </tbody>
              </table>
            </div>
            </> ) : <div className="text-center" style={{fontSize : "19px"}}>You are still able to download the profile.</div> }
          </Modal.Body>
     

        <Modal.Footer>
          {!dis ? (
            <>
              <Button onClick={(e) => handleClose()}>Disagree</Button>
              <Button onClick={(e) => handleAgree()}>Agree</Button>
            </>
          ) : (
            <Button onClick={(e) => handleAgree()}>Okay</Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PaymentModal;
