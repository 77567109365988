import React from "react";
import "./loader.css";

export default function Loader(props) {
  return (
    <>
      {props.open && (
        <React.Fragment>
          {props.dark ? (
            <div className="loader-bg">
              <div className="loader-container">
                <div className="lds-spinner">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          ) : (
            <div className="loader-bg-light">
              <div className="loader-container">
                <div className="lds-spinner-light">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </>
  );
}
