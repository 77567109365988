import React from "react";
import CommonForm from "../../../components/commonform/commonForm";
import CustomIcons from "../../../components/icons/kIcons.component";

import "./style.css";

export default function Addproposal(props) {
  return (
    <div className="proj">
      {/* <CustomIcons iconName="phone1" iconClass="daticon" /> */}
      {/* <img src="../public/resources/pdf.png" alt="" /> */}
      <div>
        <div className="dash-banner ">
          <div className="tit-txt">
            Association with Professional Bodies/Institutions
          </div>
        </div>
      </div>
      <div className="container bodybox mt-3 mb-3">
        <div className="row">
          {/* ----------------------- */}
          <div className="col-md-4 col-sm-12  sox">
            <div className="card mx-ht">
              <div className="img">
                <div className="row">
                  <div className="col-3">
                    <img
                      src="/resources/img/logox.jpg"
                      className="clogo"
                      alt=""
                    />
                  </div>
                  <div className="col-9  rytbody">
                    <h5>
                      <b>Spacex Antrix</b>
                    </h5>
                    <p className="webs">
                      <CustomIcons iconName="globe" />
                      &nbsp; www.spacex.com
                    </p>
                    <p className="mt-1">
                      <CustomIcons iconName="location" />
                      &nbsp;&nbsp;Bangalore,India
                    </p>
                  </div>

                  <div className="col-12 nilo smal">
                    <small className="smil">
                      The National Aeronautics and Space Administration is an
                      independent agency of the U.S. federal government
                      responsible for the civilian space program, as well as
                      aeronautics and space research. NASA was established in
                      1958, succeeding the National Advisory Committee for
                      Aeronautics.
                    </small>
                  </div>
                </div>
              </div>
              <div className="card__overlay">
                <div className="overlay__text">
                  <div className="member">
                    <div className="col-12">
                      <h6 className="key">
                        <b>Key Members</b>
                      </h6>
                    </div>
                    <hr />
                    <div className="col-12">
                      <div className="d-flex justify-content-center mer">
                        <div className="col-4 ceo">
                          <center>
                            <img src="/resources/img/man1.png" alt="" />
                            <p>Alex Marthom</p>
                            <h6>CEO</h6>
                            <address>akhilkrish77@dhjjk.com</address>
                          </center>
                        </div>
                        <div className="col-4 ceo">
                          <center>
                            <img src="/resources/img/man1.png" alt="" />
                            <p>Alex Marthom</p>
                            <h6>CEO</h6>
                            <address>samplemail@mail.com</address>
                          </center>
                        </div>
                        <div className="col-4 ceo">
                          <center>
                            <img src="/resources/img/man2.png" alt="" />
                            <p>Alex Marthom</p>
                            <h6>CEO</h6>
                            <address>demo_sart@demo.com</address>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ----------------------- */}
          <div className="col-md-4 col-sm-12  sox">
            <div className="card mx-ht">
              <div className="img">
                <div className="row">
                  <div className="col-3">
                    <img
                      src="/resources/img/logox.jpg"
                      className="clogo"
                      alt=""
                    />
                  </div>
                  <div className="col-9  rytbody">
                    <h5>
                      <b>Spacex Antrix</b>
                    </h5>
                    <p className="webs">
                      <CustomIcons iconName="globe" />
                      &nbsp; www.spacex.com
                    </p>
                    <p className="mt-1">
                      <CustomIcons iconName="location" />
                      &nbsp;&nbsp;Bangalore,India
                    </p>
                  </div>

                  <div className="col-12 nilo smal">
                    <small className="smil">
                      The National Aeronautics and Space Administration is an
                      independent agency of the U.S. federal government
                      responsible for the civilian space program, as well as
                      aeronautics and space research. NASA was established in
                      1958, succeeding the National Advisory Committee for
                      Aeronautics.
                    </small>
                  </div>
                </div>
              </div>
              <div className="card__overlay">
                <div className="overlay__text">
                  <div className="member">
                    <div className="col-12">
                      <h6 className="key">
                        <b>Key Members</b>
                      </h6>
                    </div>
                    <hr />
                    <div className="col-12">
                      <div className="d-flex justify-content-center mer">
                        <div className="col-4 ceo">
                          <center>
                            <img src="/resources/img/man1.png" alt="" />
                            <p>Alex Marthom</p>
                            <h6>CEO</h6>
                            <address>akhilkrish77@dhjjk.com</address>
                          </center>
                        </div>
                        <div className="col-4 ceo">
                          <center>
                            <img src="/resources/img/man1.png" alt="" />
                            <p>Alex Marthom</p>
                            <h6>CEO</h6>
                            <address>samplemail@mail.com</address>
                          </center>
                        </div>
                        <div className="col-4 ceo">
                          <center>
                            <img src="/resources/img/man2.png" alt="" />
                            <p>Alex Marthom</p>
                            <h6>CEO</h6>
                            <address>demo_sart@demo.com</address>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ----------------------- */}
          <div className="col-md-4 col-sm-12  sox">
            <div className="card mx-ht">
              <div className="img">
                <div className="row">
                  <div className="col-3">
                    <img
                      src="/resources/img/logox.jpg"
                      className="clogo"
                      alt=""
                    />
                  </div>
                  <div className="col-9  rytbody">
                    <h5>
                      <b>Spacex Antrix</b>
                    </h5>
                    <p className="webs">
                      <CustomIcons iconName="globe" />
                      &nbsp; www.spacex.com
                    </p>
                    <p className="mt-1">
                      <CustomIcons iconName="location" />
                      &nbsp;&nbsp;Bangalore,India
                    </p>
                  </div>

                  <div className="col-12 nilo smal">
                    <small className="smil">
                      The National Aeronautics and Space Administration is an
                      independent agency of the U.S. federal government
                      responsible for the civilian space program, as well as
                      aeronautics and space research. NASA was established in
                      1958, succeeding the National Advisory Committee for
                      Aeronautics.
                    </small>
                  </div>
                </div>
              </div>
              <div className="card__overlay">
                <div className="overlay__text">
                  <div className="member">
                    <div className="col-12">
                      <h6 className="key">
                        <b>Key Members</b>
                      </h6>
                    </div>
                    <hr />
                    <div className="col-12">
                      <div className="d-flex justify-content-center mer">
                        <div className="col-4 ceo">
                          <center>
                            <img src="/resources/img/man1.png" alt="" />
                            <p>Alex Marthom</p>
                            <h6>CEO</h6>
                            <address>akhilkrish77@dhjjk.com</address>
                          </center>
                        </div>
                        <div className="col-4 ceo">
                          <center>
                            <img src="/resources/img/man1.png" alt="" />
                            <p>Alex Marthom</p>
                            <h6>CEO</h6>
                            <address>samplemail@mail.com</address>
                          </center>
                        </div>
                        <div className="col-4 ceo">
                          <center>
                            <img src="/resources/img/man2.png" alt="" />
                            <p>Alex Marthom</p>
                            <h6>CEO</h6>
                            <address>demo_sart@demo.com</address>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ----------------------- */}
          <div className="col-md-4 col-sm-12  sox">
            <div className="card mx-ht">
              <div className="img">
                <div className="row">
                  <div className="col-3">
                    <img
                      src="/resources/img/logox.jpg"
                      className="clogo"
                      alt=""
                    />
                  </div>
                  <div className="col-9  rytbody">
                    <h5>
                      <b>Spacex Antrix</b>
                    </h5>
                    <p className="webs">
                      <CustomIcons iconName="globe" />
                      &nbsp; www.spacex.com
                    </p>
                    <p className="mt-1">
                      <CustomIcons iconName="location" />
                      &nbsp;&nbsp;Bangalore,India
                    </p>
                  </div>

                  <div className="col-12 nilo smal">
                    <small className="smil">
                      The National Aeronautics and Space Administration is an
                      independent agency of the U.S. federal government
                      responsible for the civilian space program, as well as
                      aeronautics and space research. NASA was established in
                      1958, succeeding the National Advisory Committee for
                      Aeronautics.
                    </small>
                  </div>
                </div>
              </div>
              <div className="card__overlay">
                <div className="overlay__text">
                  <div className="member">
                    <div className="col-12">
                      <h6 className="key">
                        <b>Key Members</b>
                      </h6>
                    </div>
                    <hr />
                    <div className="col-12">
                      <div className="d-flex justify-content-center mer">
                        <div className="col-4 ceo">
                          <center>
                            <img src="/resources/img/man1.png" alt="" />
                            <p>Alex Marthom</p>
                            <h6>CEO</h6>
                            <address>akhilkrish77@dhjjk.com</address>
                          </center>
                        </div>
                        <div className="col-4 ceo">
                          <center>
                            <img src="/resources/img/man1.png" alt="" />
                            <p>Alex Marthom</p>
                            <h6>CEO</h6>
                            <address>samplemail@mail.com</address>
                          </center>
                        </div>
                        <div className="col-4 ceo">
                          <center>
                            <img src="/resources/img/man2.png" alt="" />
                            <p>Alex Marthom</p>
                            <h6>CEO</h6>
                            <address>demo_sart@demo.com</address>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ----------------------- */}
          <div className="col-md-4 col-sm-12  sox">
            <div className="card mx-ht">
              <div className="img">
                <div className="row">
                  <div className="col-3">
                    <img
                      src="/resources/img/logox.jpg"
                      className="clogo"
                      alt=""
                    />
                  </div>
                  <div className="col-9  rytbody">
                    <h5>
                      <b>Spacex Antrix</b>
                    </h5>
                    <p className="webs">
                      <CustomIcons iconName="globe" />
                      &nbsp; www.spacex.com
                    </p>
                    <p className="mt-1">
                      <CustomIcons iconName="location" />
                      &nbsp;&nbsp;Bangalore,India
                    </p>
                  </div>

                  <div className="col-12 nilo smal">
                    <small className="smil">
                      The National Aeronautics and Space Administration is an
                      independent agency of the U.S. federal government
                      responsible for the civilian space program, as well as
                      aeronautics and space research. NASA was established in
                      1958, succeeding the National Advisory Committee for
                      Aeronautics.
                    </small>
                  </div>
                </div>
              </div>
              <div className="card__overlay">
                <div className="overlay__text">
                  <div className="member">
                    <div className="col-12">
                      <h6 className="key">
                        <b>Key Members</b>
                      </h6>
                    </div>
                    <hr />
                    <div className="col-12">
                      <div className="d-flex justify-content-center mer">
                        <div className="col-4 ceo">
                          <center>
                            <img src="/resources/img/man1.png" alt="" />
                            <p>Alex Marthom</p>
                            <h6>CEO</h6>
                            <address>akhilkrish77@dhjjk.com</address>
                          </center>
                        </div>
                        <div className="col-4 ceo">
                          <center>
                            <img src="/resources/img/man1.png" alt="" />
                            <p>Alex Marthom</p>
                            <h6>CEO</h6>
                            <address>samplemail@mail.com</address>
                          </center>
                        </div>
                        <div className="col-4 ceo">
                          <center>
                            <img src="/resources/img/man2.png" alt="" />
                            <p>Alex Marthom</p>
                            <h6>CEO</h6>
                            <address>demo_sart@demo.com</address>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ----------------------- */}
          <div className="col-md-4 col-sm-12  sox">
            <div className="card mx-ht">
              <div className="img">
                <div className="row">
                  <div className="col-3">
                    <img
                      src="/resources/img/logox.jpg"
                      className="clogo"
                      alt=""
                    />
                  </div>
                  <div className="col-9  rytbody">
                    <h5>
                      <b>Spacex Antrix</b>
                    </h5>
                    <p className="webs">
                      <CustomIcons iconName="globe" />
                      &nbsp; www.spacex.com
                    </p>
                    <p className="mt-1">
                      <CustomIcons iconName="location" />
                      &nbsp;&nbsp;Bangalore,India
                    </p>
                  </div>

                  <div className="col-12 nilo smal">
                    <small className="smil">
                      The National Aeronautics and Space Administration is an
                      independent agency of the U.S. federal government
                      responsible for the civilian space program, as well as
                      aeronautics and space research. NASA was established in
                      1958, succeeding the National Advisory Committee for
                      Aeronautics.
                    </small>
                  </div>
                </div>
              </div>
              <div className="card__overlay">
                <div className="overlay__text">
                  <div className="member">
                    <div className="col-12">
                      <h6 className="key">
                        <b>Key Members</b>
                      </h6>
                    </div>
                    <hr />
                    <div className="col-12">
                      <div className="d-flex justify-content-center mer">
                        <div className="col-4 ceo">
                          <center>
                            <img src="/resources/img/man1.png" alt="" />
                            <p>Alex Marthom</p>
                            <h6>CEO</h6>
                            <address>akhilkrish77@dhjjk.com</address>
                          </center>
                        </div>
                        <div className="col-4 ceo">
                          <center>
                            <img src="/resources/img/man1.png" alt="" />
                            <p>Alex Marthom</p>
                            <h6>CEO</h6>
                            <address>samplemail@mail.com</address>
                          </center>
                        </div>
                        <div className="col-4 ceo">
                          <center>
                            <img src="/resources/img/man2.png" alt="" />
                            <p>Alex Marthom</p>
                            <h6>CEO</h6>
                            <address>demo_sart@demo.com</address>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ----------------------- */}
          <div className="col-md-4 col-sm-12  sox">
            <div className="card mx-ht">
              <div className="img">
                <div className="row">
                  <div className="col-3">
                    <img
                      src="/resources/img/logox.jpg"
                      className="clogo"
                      alt=""
                    />
                  </div>
                  <div className="col-9  rytbody">
                    <h5>
                      <b>Spacex Antrix</b>
                    </h5>
                    <p className="webs">
                      <CustomIcons iconName="globe" />
                      &nbsp; www.spacex.com
                    </p>
                    <p className="mt-1">
                      <CustomIcons iconName="location" />
                      &nbsp;&nbsp;Bangalore,India
                    </p>
                  </div>

                  <div className="col-12 nilo smal">
                    <small className="smil">
                      The National Aeronautics and Space Administration is an
                      independent agency of the U.S. federal government
                      responsible for the civilian space program, as well as
                      aeronautics and space research. NASA was established in
                      1958, succeeding the National Advisory Committee for
                      Aeronautics.
                    </small>
                  </div>
                </div>
              </div>
              <div className="card__overlay">
                <div className="overlay__text">
                  <div className="member">
                    <div className="col-12">
                      <h6 className="key">
                        <b>Key Members</b>
                      </h6>
                    </div>
                    <hr />
                    <div className="col-12">
                      <div className="d-flex justify-content-center mer">
                        <div className="col-4 ceo">
                          <center>
                            <img src="/resources/img/man1.png" alt="" />
                            <p>Alex Marthom</p>
                            <h6>CEO</h6>
                            <address>akhilkrish77@dhjjk.com</address>
                          </center>
                        </div>
                        <div className="col-4 ceo">
                          <center>
                            <img src="/resources/img/man1.png" alt="" />
                            <p>Alex Marthom</p>
                            <h6>CEO</h6>
                            <address>samplemail@mail.com</address>
                          </center>
                        </div>
                        <div className="col-4 ceo">
                          <center>
                            <img src="/resources/img/man2.png" alt="" />
                            <p>Alex Marthom</p>
                            <h6>CEO</h6>
                            <address>demo_sart@demo.com</address>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ----------------------- */}
          <div className="col-md-4 col-sm-12  sox">
            <div className="card mx-ht">
              <div className="img">
                <div className="row">
                  <div className="col-3">
                    <img
                      src="/resources/img/logox.jpg"
                      className="clogo"
                      alt=""
                    />
                  </div>
                  <div className="col-9  rytbody">
                    <h5>
                      <b>Spacex Antrix</b>
                    </h5>
                    <p className="webs">
                      <CustomIcons iconName="globe" />
                      &nbsp; www.spacex.com
                    </p>
                    <p className="mt-1">
                      <CustomIcons iconName="location" />
                      &nbsp;&nbsp;Bangalore,India
                    </p>
                  </div>

                  <div className="col-12 nilo smal">
                    <small className="smil">
                      The National Aeronautics and Space Administration is an
                      independent agency of the U.S. federal government
                      responsible for the civilian space program, as well as
                      aeronautics and space research. NASA was established in
                      1958, succeeding the National Advisory Committee for
                      Aeronautics.
                    </small>
                  </div>
                </div>
              </div>
              <div className="card__overlay">
                <div className="overlay__text">
                  <div className="member">
                    <div className="col-12">
                      <h6 className="key">
                        <b>Key Members</b>
                      </h6>
                    </div>
                    <hr />
                    <div className="col-12">
                      <div className="d-flex justify-content-center mer">
                        <div className="col-4 ceo">
                          <center>
                            <img src="/resources/img/man1.png" alt="" />
                            <p>Alex Marthom</p>
                            <h6>CEO</h6>
                            <address>akhilkrish77@dhjjk.com</address>
                          </center>
                        </div>
                        <div className="col-4 ceo">
                          <center>
                            <img src="/resources/img/man1.png" alt="" />
                            <p>Alex Marthom</p>
                            <h6>CEO</h6>
                            <address>samplemail@mail.com</address>
                          </center>
                        </div>
                        <div className="col-4 ceo">
                          <center>
                            <img src="/resources/img/man2.png" alt="" />
                            <p>Alex Marthom</p>
                            <h6>CEO</h6>
                            <address>demo_sart@demo.com</address>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ----------------------- */}
          <div className="col-md-4 col-sm-12  sox">
            <div className="card mx-ht">
              <div className="img">
                <div className="row">
                  <div className="col-3">
                    <img
                      src="/resources/img/logox.jpg"
                      className="clogo"
                      alt=""
                    />
                  </div>
                  <div className="col-9  rytbody">
                    <h5>
                      <b>Spacex Antrix 77</b>
                    </h5>
                    <p className="webs">
                      <CustomIcons iconName="globe" />
                      &nbsp; www.spacex.com
                    </p>
                    <p className="mt-1">
                      <CustomIcons iconName="location" />
                      &nbsp;&nbsp;Bangalore,India
                    </p>
                  </div>

                  <div className="col-12 nilo smal">
                    <small className="smil">
                      The National Aeronautics and Space Administration is an
                      independent agency of the U.S. federal government
                      responsible for the civilian space program, as well as
                      aeronautics and space research. NASA was established in
                      1958, succeeding the National Advisory Committee for
                      Aeronautics.
                    </small>
                  </div>
                </div>
              </div>
              <div className="card__overlay">
                <div className="overlay__text">
                  <div className="member">
                    <div className="col-12">
                      <h6 className="key">
                        <b>Key Members</b>
                      </h6>
                    </div>
                    <hr />
                    <div className="col-12">
                      <div className="d-flex justify-content-center mer">
                        <div className="col-4 ceo">
                          <center>
                            <img src="/resources/img/man1.png" alt="" />
                            <p>Alex Marthom</p>
                            <h6>CEO</h6>
                            <address>akhilkrish77@dhjjk.com</address>
                          </center>
                        </div>
                        <div className="col-4 ceo">
                          <center>
                            <img src="/resources/img/man1.png" alt="" />
                            <p>Alex Marthom</p>
                            <h6>CEO</h6>
                            <address>samplemail@mail.com</address>
                          </center>
                        </div>
                        <div className="col-4 ceo">
                          <center>
                            <img src="/resources/img/man2.png" alt="" />
                            <p>Alex Marthom</p>
                            <h6>CEO</h6>
                            <address>demo_sart@demo.com</address>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
