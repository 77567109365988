import React, { useEffect, useState } from "react";
import CustomIcons from "../../../../components/icons/kIcons.component";

export default function TechPropTile(props) {
  const [data, setData] = useState(props.data);
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);

  const handleDownload = (e) => {
    let path = `${result}${data.file_upload_proposal}`;
    window.open(path, "_blank");
  };
  const handleEDownload = (e) => {
    let path = `${result}${e}`;
    window.open(path, "_blank");
  };

  useEffect(() => {
    setData(props.data);
  }, [props.data]);
  return (
    <div className="card proposal-gaps">
      <div className="row">
        <div className="col-12 col-md-12">
          <div className="user-tech-prop-tile-data-container">
            <div className="row pt-2">
              <div className=" text-center user-tech-prop-tile-title ">
                {data.project_proposal_title}
              </div>
            </div>
            <div className="column pt-2">
            <div className=" row user-tech-prop-tile-title-sub pb-2">
                <span className="text-black col-3">Organization </span>
                <span className="col-1">:</span>
              
                {data.organization?.name !== "Others" ? data.organization?.name : data?.other_organization}
              </div>
              <div className=" row user-tech-prop-tile-title-sub pb-2">
                <span className="text-black col-3">Category </span>{" "}
                <span className="col-1">:</span>
                
                {data?.category?.name !== "Others" ? data?.category?.name : data?.other_category}
              </div>

              
              <div className=" row user-tech-prop-tile-title-sub pb-2">
                <span className="text-black col-3">Description </span>
                <span className="col-1">:</span>
                <div className="col-7 px-0">{data.short_details_proposal ?? ""}</div>
                
              </div>
              {data?.technology_exploratory_note && (
                <div className=" row user-tech-prop-tile-title-sub pb-2">
                  <span className="text-black col-3">
                    Download Technology Explanatory note
                  </span>
                  <span className="col-1">:</span>
                  <div
                    className="px-0 text-primary col-4"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleEDownload(data.technology_exploratory_note)}
                  >
                    Download
                  </div>
                </div>
              )}
              {data.file_upload_proposal &&
                <div className=" row user-tech-prop-tile-title-sub pb-2">
                  <span className="text-black col-3">
                    Download Product Image
                  </span>
                  <span className="col-1">:</span>
                  <div
                    className=" text-primary px-0 col-4"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleDownload(e)}
                  >
                    Download
                  </div>
                </div>}
                {data?.link && (
                <div className=" row user-tech-prop-tile-title-sub pb-2">
                  <span className="text-black col-3">
                    Visit Link{" "}
                  </span>
                  <span className="col-1">:</span>
                  <div
                    className="px-0 text-primary col-4"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      window.open(data?.link, "_blank");
                    }}
                  >
                    Visit
                  </div>
                </div>
              )}
                {data?.website_url && (
                <div className=" row user-tech-prop-tile-title-sub pb-2">
                  <span className="text-black col-3">
                    Visit Website{" "}
                  </span>
                  <span className="col-1">:</span>
                  <div
                    className="px-0 text-primary col-4"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      window.open(data?.website_url, "_blank");
                    }}
                  >
                    Visit
                  </div>
                </div>
              )}
             
            </div>
           
          </div>
          
         
        </div>

       
      </div>
    </div>
  );
}
