import React from "react";
import "./style.css";

export default function ConfirmDialog(props) {
  const handleConfirm = () => {
    props.handleConfirm(props.data);
  };
  const handleClose = () => {
    props.handleClose();
  };

  return (
    <React.Fragment>
      {props.open && (
        <div className="overlay">
          <div className="action-dialog-cont">
            <div className="action-title-cont">{props.title} </div>
            <div>
              <p className="action-msg">{props.message}</p>
            </div>
            <div className="actn-btn-cntr-cont">
              <button
                className="btn btn-primary act-btn"
                onClick={(e) => handleConfirm(e)}
              >
                {props.primary_action}
              </button>
              <button
                className="btn btn-secondary act-btn"
                onClick={(e) => handleClose(e)}
              >
                {props.secondary_action}
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
