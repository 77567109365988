export const Orders = [
  // {
  //   id: 1,
  //   title:
  //     " For various posts in Kerala Institute for Entrepreneurship Development (KIED) on contract basis.",
  //   file: "https://drive.google.com/file/d/12enDgNxWjb3LA53RJ6NNdrz3I3Quhhtm/view?usp=sharing",
  // },
  // {
  //   id: 2,
  //   title:
  //     "KIED invites applications from competent and qualified candidates to the post of Deputy Manager, Assistant Manager, Junior Manager and Multi-Purpose Staff in Centre of Excellence (CoE) & Enterprise Development Centre (EDC) on contract basis.",
  //   file: "https://drive.google.com/file/d/1epYYUOvSkNeBYk7MTyg_xwnwcwiH19UE/view?usp=sharing",
  // },
];
