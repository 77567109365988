import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MapContainer from "./heatmap/maps";
import { Helmet } from "react-helmet";
import { data } from "./heatmap/index";
import CustomIcons from "../../../../components/icons/kIcons.component";

import { count_show } from "../../../../pages/user/user.api";

export default function Footer(props) {
  const [count, setCount] = useState(0);
  const scount = () => {
    count_show()
      .then((res) => {
        setCount(res.count);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    scount();
  }, []);
  return (
    <>
      <div className="footer">
        <div className="footer-main-items-cont">
          <div className="kspace-foot hideonMobile">
            <div className="kspace-foot-desc">
              <div className="heat-map-cont">
                <img
                  src="/resources/logo/logo2.png"
                  className="footer-logo-image"
                  alt="logo"
                />
                {/* <a
                href="https://clustrmaps.com/site/1bnlz"
                target="_blank"
                title="Visit tracker"
              >
                <img
                  width="100%"
                  src="//clustrmaps.com/map_v2.png?cl=009fe3&w=450&t=tt&d=SXREzjoL5sxuv1JWKWEQ4vEjECD_6zKMQN9XnT2nixU&co=f5fbfe&ct=000000"
                />
              </a> */}

                {/* <img
                  width="100%"
                  src="//clustrmaps.com/map_v2.png?cl=009fe3&w=450&t=tt&d=BcBA6KkgUka_400LyBvyVWm5TKhcvO02xXVKZMueXMI&co=f5fbfe&ct=000000"
                /> */}
              </div>
            </div>
            {/* <div className="footer-icons-cont">
            <div className="insta">
              <img src="/resources/img/footer/ig.png" />
            </div>
            <div className="insta">
              <img src="/resources/img/footer/fb.png" />
            </div>
            <div className="insta">
              <img src="/resources/img/footer/tw.png" />
            </div>
            <div className="insta">
              <img src="/resources/img/footer/yt.png" />
            </div>
            <div className="insta">
              <img src="/resources/img/footer/ln.png" />
            </div>
          </div> */}
          </div>
          <div className="kspace-footer-links">
            <div className="kspace-footer-item-title">Useful Links</div>
            <div className="kspace-footer-item-values">
              <div>
                <a
                  href="/orders"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  G.O / Circulars
                </a>
              </div>
            </div>
            {/* <div className="kspace-footer-item-values">
            <div>Circulars</div>
          </div> */}
            {/* <div className="kspace-footer-item-values">
            <div>Space Related Links</div>
          </div> */}
            <div className="kspace-footer-item-values">
              <div>
                {" "}
                <a
                  href="/gallery/images"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Gallery
                </a>
              </div>
            </div>
            <div className="kspace-footer-item-values">
              <div>
                <a
                  href="/contact"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Contact us
                </a>
              </div>
            </div>
          </div>
          <div className="kspace-footer-other-links">
            {" "}
            <div className="kspace-footer-item-title">Others</div>
            <div className="kspace-footer-item-values">
              <div>
                <a
                  href="/advisors"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Spacepark Advisors
                </a>
              </div>
            </div>
            <div className="kspace-footer-item-values">
              <div style={{ cursor: "pointer" }}>
                <a
                  href="/disclaimer"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Disclaimer
                </a>
              </div>
            </div>
            <div className="kspace-footer-item-values">
              <div>
                <a
                  href="/privacy-policy"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Privacy Policy
                </a>
              </div>
            </div>
            <div className="kspace-footer-item-values">
              <div>
                <a
                  href="/terms&conditions"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Terms & Conditions
                </a>
              </div>
            </div>
            {/* <div className="kspace-footer-item-values">
              <div>FAQ’s</div>
            </div> */}
          </div>
          <div className="kspace-footer-qa">
            {" "}
            <div className="kspace-footer-item-title">Have a question?</div>
            <div className="d-flex">
              <div className="icon-f-c">
                <img src="/resources/img/footer/location.png" />
              </div>
              <div className="kspace-footer-contact">
                <div>
                  <p className="addressLine">Kerala Spacepark (KSPACE)</p>
                  <p className="addressLine"> 1st Floor, Divisional office</p>
                  <p className="addressLine">  Jeevan Prakash, LIC Building, Pattom  PO,</p>
                  <p className="addressLine">Thiruvananthapuram- 695004</p>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="icon-f-c">
                {" "}
                <img src="/resources/img/footer/phone.png" />
              </div>
              <div className="kspace-footer-contact">
                <div> +91 7306433189 , +91 7306433165</div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="icon-f-c">
                {" "}
                <img src="/resources/img/footer/mail.png" />
              </div>
              <div className="kspace-footer-contact">
                <div>
                  <a href="mailto:info@kspace.org.in"> info@kspace.org.in</a>
                </div>
                {/* <div>
                  <a href="mailto:ceo@kspace.org.in"> ceo@kspace.org.in</a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="footi">
          <div className="social-buttons">
            <a
              href="#"
              className="social-buttons__button social-button social-button--facebook"
              aria-label="Facebook"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
            <a
              href="#"
              className="social-buttons__button social-button social-button--twitter"
              aria-label="LinkedIn"
            >
              {/* <i className="fab fa-twitter"></i> */}
              <img src="/twitter.png" alt="x" className="twitter_icon"/>
            </a>
            <a
              href="#"
              className="social-buttons__button social-button social-button--youtube"
              aria-label="Youtube"
            >
              <i className="fab fa-youtube"></i>
            </a>
            <a
              href="#"
              className="social-buttons__button social-button social-button--insta"
              aria-label="GitHub"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="#"
              className="social-buttons__button social-button social-button--linkedin"
              aria-label="Linkedin"
            >
              <i className="fab fa-linkedin-in"></i>
            </a>
          </div>
        </div>
        <div className="copy-cont">
          <div className="copyrt">
            Copyright © Kerala Spacepark. All Rights Reserved
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-end">
          <span className="copyrt" style={{ color: "red" }}>
            Visitor Count : {count}
          </span>
        </div>
      </div>
    </>
  );
}
