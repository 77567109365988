import React, { useEffect, useState } from "react";
import { MenuDatas } from "./menu.config";
import "./menu.style.css";
import CustomIcons from "../../../../components/icons/kIcons.component";
import jwtDecode from "jwt-decode";
export default function MenuComp(props) {
  let lang = props.state.language;
  const homeData = {
    path: "/",
    isDirect: true,
  };
  const handlePreventClose = (e) => {
    e.stopPropagation();
  };
  let userType = props.state.payload ? jwtDecode(props.state.payload) : {};
  console.log(userType);
  let activeUserType = userType?.user?.user_type || null;
  let activeUserStatus = userType?.user?.status || null;
  console.log(activeUserType);
  return (
    <div className="new-menu-bg anima" onClick={(e) => props.handleClose(e)}>
      <div
        className="new-menu-container"
        onClick={(e) => handlePreventClose(e)}
      >
        <div className="menu-main-section">
          {MenuDatas.map((item) => (
            <React.Fragment key={item.id + "main"}>
              {item.hideOnAuth ? (
                <>
                  {!props.auth && (
                    <>
                      <div className="new-menu-item" key={item.id}>
                        <div className="new-menu-title">
                          {lang === "ma" ? item.label_ma : item.label_en}
                        </div>
                        <div className="new-menu-subItem">
                          {item.children.map((ch) => (
                            <>
                              {ch.show && (
                                <div
                                  className="new-menu-item-data"
                                  key={ch.id}
                                  onClick={(e) => props.handleMenuClick(e, ch)}
                                >
                                  {/* <div>
                                 <CustomIcons
                                   iconName="arrowRight"
                                   iconClass="submenus-icon"
                                 />
                               </div> */}
                                  <div className="new-submenus-label">
                                    {lang === "ma" ? ch.label_ma : ch.label_en}
                                  </div>
                                </div>
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="new-menu-item" key={item.id}>
                    <div className="new-menu-title">
                      {lang === "ma" ? item.label_ma : item.label_en}
                    </div>
                    <div className="new-menu-subItem">
                      {item.children.map((ch) => (
                        <>
                          {ch.show && (
                            <>
                              <div
                                className="new-menu-item-data"
                                key={ch.id}
                                onClick={(e) => props.handleMenuClick(e, ch)}
                              >
                                <div className="new-submenus-label">
                                  {lang === "ma" ? ch.label_ma : ch.label_en}
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </React.Fragment>
          ))}
        </div>
        <div className="new-menu-home">
          <div
            className="new-home-icon-cont"
            onClick={(e) => props.handleMenuClick(e, homeData)}
          >
            <CustomIcons iconName="home" iconClass="new-menu-home-icon" />
          </div>
        </div>
      </div>
    </div>
  );
}
