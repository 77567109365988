import axios from "axios";

export const refreshToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = `*`;
  } else {
    axios.defaults.headers.common["Authorization"] = null;
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = `*`;
  }
};
export const refreshAdminToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = `*`;
  } else {
    axios.defaults.headers.common["Authorization"] = null;
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = `*`;
  }
};
export const refreshTempToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = `*`;
  } else {
    axios.defaults.headers.common["Authorization"] = null;
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = `*`;
  }
};
