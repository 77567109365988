import React from "react";
import { Orders } from "./data";
import "./tenders.css";
import "../home/home.css";
import "../gallery/events.css";

function GovOrders(props) {
  return (
    <>
      <div className="container-fluid event-bg">
        <div className="row m-55">
          <h3>
            {props.state.language === "ma"
              ? "Government Orders and Circulars"
              : "Government Orders and Circulars  "}
          </h3>
        </div>
      </div>
      <div className="container-fluid">
        <div className="table-container">
          <table className="">
            {Orders.length > 0 ? (
              <tr className="">
                <th className="table-head">Company</th>
                <th className="table-head ">Contact</th>
              </tr>
            ) : (
              <div
                className="d-flex justify-content-center"
                style={{ fontSize: "20px" }}
              >
                No Recent Postings
              </div>
            )}

            {Orders.map((card, i) => {
              return (
                <tr>
                  <td className="m-2 text-left">{card.title}</td>
                  <td className="m-2">
                    <a href={`${card.file}`} target="_blank">
                      Download File
                    </a>
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      </div>
    </>
  );
}

export default GovOrders;
