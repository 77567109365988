import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Placeholder from "react-bootstrap/Placeholder";
export default function AboutTileContainer(props) {
  const [isActive, setIsActive] = useState(props.isActive);
  useEffect(() => {
    setIsActive(props.isActive);
  }, [props.isActive]);
  let titleDatas = {
    immediate_mission: {
      en: { Title: "Immediate Mission" },
      ma: { Title: "Immediate Mission" },
    },
  };
  let lang = props.state.language;
  console.log(props.state.language);

  return (
    <div className="about-tile-container">
      {isActive ? (
        <>
          <div className="w-100">
            <Placeholder as={Card.Text} animation="glow">
              {/* <Placeholder xs={7} /> <Placeholder xs={4} />{" "} */}
              <Placeholder
                xs={12}
                style={{
                  height: "50px",
                  width: "50px",
                  borderRadius: "50%",
                  color: "#009FE3",
                }}
              />
            </Placeholder>
          </div>

          <div className="">
            <Placeholder as={Card.Text} animation="glow">
              {/* <Placeholder xs={7} /> <Placeholder xs={4} />{" "} */}
              <Placeholder
                xs={12}
                style={{
                  height: "30px",
                  width: "150px",
                  margin: "20px 0px",
                  color: "#009FE3",
                }}
              />
            </Placeholder>
          </div>
          <div className="">
            {" "}
            <Placeholder
              as={Card.Text}
              animation="glow"
              style={{ width: "300px", color: "#009FE3" }}
            >
              {/* <Placeholder xs={7} /> <Placeholder xs={4} />{" "} */}
              <Placeholder xs={12} />
              <Placeholder xs={12} />
            </Placeholder>
          </div>
        </>
      ) : (
        <>
          <div className="">
            {props.section&&
            <img
              src={`/resources/img/${props.section}.png`}
              alt=""
              className="about-right-logo"
            />}
          </div>

          <div className="about-tile-header">
            {props.section === "immediate_mission"
              ? props.state.language === "ma"
                ? "ഇമ്മീഡിയേറ്റ് മിഷൻ"
                : "Immediate Mission"
              : props.section === "mission"
              ? props.state.language === "ma"
                ? "മിഷൻ"
                : "Mission"
              : props.state.language === "ma"
              ? "വിഷൻ"
              : "Vision"}
          </div>
          <div className="about-descs">{props.data[lang]?.description}</div>
        </>
      )}
    </div>
  );
}
