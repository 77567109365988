import React from "react";
import { Route, Redirect } from "react-router-dom";
import Layout from "../../layout/userlayout/layout.component";

const RegRoute = ({ component: Component, ...rest }) => {
  let valid = rest.state.isValid;
  let mobile_number = rest.phone;
  console.log(rest);
  return (
    <Route
      {...rest}
      render={(props) =>
        valid ? (
          <Layout
            {...props}
            {...rest}
            key={rest.location?.key}
            mobile_number={rest.phone}
          >
            <Component
              {...props}
              {...rest}
              mobile_number={rest.phone}
              key={rest.location?.key}
            />
          </Layout>
        ) : (
          <Redirect to="/registration" />
        )
      }
    />
  );
};

export default RegRoute;
