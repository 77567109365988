import React from "react";
import CustomIcons from "../../icons/kIcons.component";
import "./dashtile.css";

export default function DashTile(props) {
  return (
    <div
      onClick={(e) => props.history.push(props.link)}
      className={
        props.index % 2 === 1
          ? "dash-tile-container even"
          : "dash-tile-container"
      }
      // style={{ cursor: "pointer" }}
    >
      <div
        className={
          props.index % 2 === 1
            ? "dash-icon-container even"
            : "dash-icon-container"
        }
      >
        <CustomIcons
          iconName="bank"
          iconClass={props.index % 2 === 1 ? "tile-icon even" : "tile-icon"}
        />
      </div>
      <div className="dash-tile-data">
        <div className="dashdataitems">
          <div
            className={
              props.index % 2 === 1 ? "dash-tile-label even" : "dash-tile-label"
            }
          >
            {props.label}
          </div>
        </div>
        <div className="dashdataitems">
          <div
            className={
              props.index % 2 === 1 ? "dash-tile-count even" : "dash-tile-count"
            }
          >
            {props.count}
          </div>
        </div>
      </div>
    </div>
  );
}
