import React from "react";
export default function VideoTile(props) {
  let lang = props.state.language;

  return (
    <div className="">
      {/* <img src={props.data[lang]?.videos} className="gallery__img" alt="" /> */}
      <video controls width="100%" height="100%">
        <source src={props.data[lang]?.videos} type="video/mp4" />
      </video>
    </div>
  );
}
