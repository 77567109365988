import React, { Component } from "react";
import { FormValidate } from "../../../../../components/formValidate";
import CustomButton from "../../../../../components/inputs/button";
import TextField from "../../../../../components/inputs/textfield";
import Loader from "../../../../../components/loader/loader";
import Snackbar from "../../../../../components/snackbar/snackbar";
import { admin_scope_add } from "../../../admin.api";
import "./dialog.css";

let required = ["description_en", "description_ma"];
class Dialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      formData: props.formData,
      edit: props.edit,
      submitted: false,
      snackMessage: "",
      isActive: false,
      isSnackError: false,
      snackOpen: false,
    };
  }

  componentWillReceiveProps = (props) => {
    this.setState({
      open: props.open,
      edit: props.edit,
      formData: props.formData,
    });
  };
  handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({ formData: { ...this.state.formData, [name]: value } });
  };
  snackbarClose = (flag) => {
    this.setState({
      snackMessage: "",
      isSnackError: false,
      snackOpen: false,
    });
    if (!flag) {
      this.props.handleClose();
    }
  };
  handleSubmit = (e) => {
    const error = FormValidate(this.state.formData, required);
    //
    const { edit, formData } = this.state;
    e.preventDefault();

    if (Object.keys(error).length === 0) {
      this.setState({ isActive: true });
      const form = new FormData();
      form.append("title_en", formData.title_en);
      form.append("title_ma", formData.title_ma);
      form.append("description_en", formData.description_en);

      form.append("description_ma", formData.description_en);
      form.append("small_image", null);

      if (formData.id) form.append("scope_id", formData.id);
      if (edit) {
        admin_scope_add(form)
          .then((res) => {
            this.setState({
              submitted: true,
              isActive: false,
              snackMessage: res.message,
              isSnackError: false,
              snackOpen: true,
            });
          })
          .catch((error) => {
            this.setState({
              submitted: false,

              isActive: false,
              snackMessage: error.message,
              isSnackError: true,
              snackOpen: true,
            });
          });
      } else {
        admin_scope_add(form)
          .then((res) => {
            this.setState({
              submitted: true,
              isActive: false,

              snackMessage: res.message,
              isSnackError: false,
              snackOpen: true,
            });
          })
          .catch((error) => {
            this.setState({
              submitted: false,
              isActive: false,
              snackMessage: error.message,
              isSnackError: true,
              snackOpen: true,
            });
          });
      }
    } else {
      this.setState({
        submitted: false,
        snackMessage: "Please fill all mandatory fields",
        isSnackError: true,
        snackOpen: true,
      });
    }
  };
  // handleFileChange = (e) => {
  //   let flag = false;
  //   var reader = new FileReader();
  //   reader.readAsDataURL(e.target.files[0]);
  //   reader.onload = function (e) {
  //     var image = new Image();
  //     image.src = e.target.result;

  //     image.onload = function () {
  //       var height = this.height;
  //       var width = this.width;

  //       if (height === 625 || width === 1440) {
  //         flag = true;
  //       }
  //     };
  //   };
  //   setTimeout(() => {
  //     if (flag) {
  //       this.setState({
  //         formData: {
  //           ...this.state.formData,
  //           [e.target.name]: e.target.files[0],
  //         },
  //       });
  //     } else {
  //       this.setState({
  //         snackMessage: "Incorrect image dimension",
  //         isSnackError: true,
  //         snackOpen: true,
  //       });
  //     }
  //   }, 200);
  // };

  render() {
    const { submitted, formData, open, edit, isActive } = this.state;
    const { view } = this.props;
    return (
      <React.Fragment>
        {open && (
          <React.Fragment>
            <div className="overlay">
              <Loader open={isActive} />
              <div className="big-dia-scope dialog">
                <Snackbar
                  message={this.state.snackMessage}
                  error={this.state.isSnackError}
                  open={this.state.snackOpen}
                  close={this.snackbarClose}
                />
                <div className="row">
                  <div className="flexDisplay">
                    <div className="diaTitleCon">
                      {edit
                        ? "Edit Scope of Activity"
                        : view
                        ? "Scope of Activity"
                        : "Add Scope of Activity"}
                    </div>
                    <div className="dia-close" onClick={this.props.handleClose}>
                      <div className="diaCloseCon">
                        <div className="diaClose">&#10005;</div>
                      </div>
                    </div>
                  </div>
                  <div className="tilebar d-flex justify-content-start">
                    <hr />
                  </div>
                  <form action="">
                    <div className="row book-form">
                      <div>
                        <div className="col-12">
                          <TextField
                            id="outlined-basic"
                            label="Scope [Eng]"
                            variant="outlined"
                            multiline
                            disabled={view}
                            onChange={(e) => this.handleChange(e)}
                            value={formData.title_en ?? ""}
                            name="title_en"
                            required
                          />
                        </div>
                        <div className="col-12">
                          <TextField
                            id="outlined-basic"
                            label="Scope [Mal]"
                            variant="outlined"
                            disabled={view}
                          
                            multiline
                            onChange={(e) => this.handleChange(e)}
                            value={formData.title_ma ?? ""}
                            name="title_ma"
                            required
                          />
                        </div>
                        {/* <div className="col-12">
                          <TextField
                            id="outlined-basic"
                            label="Image"
                            name="small_image"
                            type="file"
                            variant="outlined"
                            disabled={view}
                            // value={formData.small_image ?? ""}
                            onChange={(e) => this.handleChange(e)}
                            // required
                          />
                        </div> */}
                      </div>
                      {!view && (
                        <div className="col-12">
                          <CustomButton
                            label={edit ? "Update" : "Add"}
                            disabled={submitted}
                            onClick={(e) => this.handleSubmit(e)}
                          />
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default Dialog;
