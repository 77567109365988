import React from "react";
import "./style.css";

const CustomButton=(props)=> {
  console.log(props)
  return (
    <div className="button-to-center">
      <button {...props} className="commonButtonbutton">
        {props.label && (
          <label className="button-label-style">{props.label}</label>
        )}
      </button>
    </div>
  );
}
export default CustomButton
