import React, { useEffect, useState } from "react";

import ConfirmDialog from "../../../components/actionDialogs/confirmDialog";
import Loader from "../../../components/loader/loader";
import Snackbar from "../../../components/snackbar/snackbar";
import CustomTable from "../../../components/table/table";
import { admin_event_delete, admin_event_list } from "../admin.api";
import ManageEvent from "./manage/manage";
import "./view.css";
import jwtDecode from "jwt-decode";

export default function View(props) {
  const [data, setdata] = useState([]);
  const [view, setView] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [formData, setFormData] = useState({});
  const [edit, setEdit] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [activeData, setActiveData] = useState({});
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  let adminType = Number(jwtDecode(props.state.payload).user_type);
  console.log(jwtDecode(props.state.payload));
  const handleEventList = () => {
    setIsActive(true);
    admin_event_list().then((res) => {
      let tempData = res.data;
      tempData.forEach((item, index) => {
        item["slno"] = index + 1;
      });
      setdata(tempData);
      setIsActive(false);
    });
  };
  useEffect(() => {
    handleEventList();
  }, []);
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      handleEventList();
    }
  };
  const handleAddNew = () => {
    setAddNew(true);
    setFormData({ event_organized_type: "kspace" });
    setView(false);
  };
  const handleClose = () => {
    setAddNew(false);
    setEdit(false);
    setFormData({});
    handleEventList();
  };
  const handleEdit = (data) => {
    setFormData(data);
    setView(false);
    setEdit(true);
    setAddNew(true);
  };
  const handleDelete = (data) => {
    setActiveData(data);
    setOpenAction(true);
  };
  const handleCancelDelete = () => {
    setOpenAction(false);
  };
  const handleDeleteConfirm = (data) => {
    setOpenAction(false);
    admin_event_delete(data.uuid)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
      });
  };
  const handleView = (data) => {
    let path = `/event-list/${data.uuid}`;
    props.history.push(path);
  };
  return (
    <div>
      <Loader open={isActive} />
      <div className="view-container">
        <h6>
          <b>Events</b>
        </h6>
        <hr />
        {/* Admin */}
        {adminType === 1 && (
          <>
            {" "}
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={(e) => handleAddNew(e)}
            >
              Add New
            </button>
            <CustomTable
              {...props}
              columns={[
                { title: "No", field: "slno", type: "dynId" },
                { title: "Image", type: "image", field: "event_image" },
                { title: "Event Name", field: "event_title_en" },
                { title: "Organizer", field: "oraganized_by_en" },
                { title: "Date", field: "event_date", type: "date" },
                { title: "Location", field: "location_en" },
                // { title: "Status", field: "status", type: "StatusType" },
              ]}
              data={data}
              actions={[
                { type: "view", handleClick: handleView },
                { type: "edit", handleClick: handleEdit },
                // { type: "delete", handleClick: handleDelete },
              ]}
            />
          </>
        )}
        {/* superAdmin */}
        {adminType === 2 && (
          <>
            {" "}
            <CustomTable
              {...props}
              columns={[
                { title: "No", field: "slno", type: "dynId" },
                { title: "Image", type: "image", field: "event_image" },
                { title: "Event Name", field: "event_title_en" },
                { title: "Organizer", field: "oraganized_by_en" },
                { title: "Date", field: "event_date", type: "date" },
                { title: "Location", field: "location_en" },
                // { title: "Status", field: "status", type: "StatusType" },
              ]}
              data={data}
              actions={[
                { type: "view", handleClick: handleView },
                // { type: "edit", handleClick: handleEdit },
                { type: "delete", handleClick: handleDelete },
              ]}
            />
          </>
        )}
        {/* inter-admin */}
        {adminType === 3 && (
          <>
            <CustomTable
              {...props}
              columns={[
                { title: "No", field: "slno", type: "dynId" },
                { title: "Image", type: "image", field: "event_image" },
                { title: "Event Name", field: "event_title_en" },
                { title: "Organizer", field: "oraganized_by_en" },
                { title: "Date", field: "event_date", type: "date" },
                { title: "Location", field: "location_en" },
                // { title: "Status", field: "status", type: "StatusType" },
              ]}
              data={data}
              actions={[
                { type: "view", handleClick: handleView },
                // { type: "edit", handleClick: handleEdit },
                // { type: "delete", handleClick: handleDelete },
              ]}
            />
          </>
        )}
      </div>
      {addNew && (
        <ManageEvent
          open={addNew}
          edit={edit}
          view={view}
          handleClose={handleClose}
          formData={formData}
        />
      )}
      {openAction && (
        <ConfirmDialog
          open={openAction}
          title="Delete Event ?"
          data={activeData}
          message="Are you sure to delete this Event?"
          secondary_action="Cancel"
          primary_action="Delete"
          handleClose={handleCancelDelete}
          handleConfirm={handleDeleteConfirm}
        />
      )}
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
    </div>
  );
}
