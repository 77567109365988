import { useEffect } from "react";
import { withRouter } from "react-router-dom";

function ScrollToTop({ onRouteChange, history }) {
  // Don't make scroll on this URLs
  // const scrollWhiteListPath = ["/pricing", "/events"]

  useEffect(() => {
    const unlisten = history.listen(() => {
      let scrollEnabled = true;
      onRouteChange(history.location.pathname);
      // scrollWhiteListPath.forEach((path) => {
      //   if (history.location.pathname.includes(path)) {
      //     scrollEnabled = false
      //   }
      // })

      if (scrollEnabled) {
        window.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
}

export default withRouter(ScrollToTop);
