import apis from "../../../api/api.actions";
import {
  refreshAdminToken,
  refreshTempToken,
  refreshToken,
} from "../../../config";
// import { refreshActive } from "../../../App";
export const authLogin = (data) =>
  apis.auth("admin/login", data).then((res) => res);

export const userRegConfirm = (data) =>
  apis.createData("phoneno-verification-status", data).then((res) => res);

export const userLogin = (data) =>
  apis.createData("user/login", data).then((res) => res);

export const userReg = (data) =>
  apis.createData("phoneno-verification", data).then((res) => res);
export const verifyOtp = (data) =>
  apis.createData("verify-otp", data).then((res) => res);

export const refreshApi = (data) =>
  apis.createData("refresh", data).then((res) => res);
