import React from "react";

import "./resourse.css";

export default function ResourseTile(props) {
  const expertiseIn = (data) => {
    let expin = "";
    let dataArray = [];
    data.forEach((item, i) => {
      if (i > 0) {
        dataArray.push(" | ");
      }
      dataArray.push(item.expertise_name);
    });
    console.log(dataArray);
    expin = String(dataArray) || "";
    return expin;
  };

  return (
    <div className="resourse-tile">
      
      {props?.photo && !props?.candidate ? (
        <div className="resource-image-cont">
          <img src={props.photo} alt="" className="resource-image" />
        </div>
      ) : (
        // <div className="resource-word-cont">
        //   {props?.candidate && <div className="candidate-word">{props?.name} </div>}
        // </div>
        <></>
      )}

      <div
        className={`${
          props?.candidate
            ? "candidate-desc-container"
            : "resource-desc-container"
        }`}
      ><div className="status-ribbon w-100">
         {props.candidate && props.status && props.status === 4 ? (
          <div className="text-success bolded_text">Payment Done</div>
        ) : props?.status === 3 ? (
          <div className="text-info bolded_text">Joined Successfully</div>
        ) : props?.status === 2 ? (
          <div className="text-success bolded_text">Offer Letter Issued</div>
        ) : props?.status === 1 ? (
          <div className="text-info bolded_text">Interview Scheduled</div>
        ) :props?.status === 0 ? (
          <div className="text-info bolded_text">Downloaded</div>
        ) : (
          ""
        )}
        </div>
        <div className="res-title">{props.title}</div>
        <div className="res-email ">{props.email}</div>
       

        {props.expIn && (
          <>
            <div className="res-exp-heading">Expertise in</div>
            <p className="expertize-decs">
              {/* Mentor ,Consultant, Manager/ Executive */}
              {props.expIn && props.expIn.length !== 0 && (
                <>
                  <>
                    {props.expIn.map((item, i) => (
                      <>
                        {item.expertise_name}{" "}
                        {i + 1 !== props.expIn.length && <>&nbsp;| &nbsp;</>}
                      </>
                    ))}
                  </>
                </>
              )}
            </p>
          </>
        )}
        {props.candidate && (
          <>
            <div className="res-exp-heading">Qualification</div>
            <p className="expertize-decs">{props?.qualification}</p>
          </>
        )}
        {/* {props.candidate && (
          <>
            <div className="res-exp-heading">Interests</div>
            <p className="expertize-decs">
             {props?.qualification}
            </p>
          </>
        )} */}

        <div className="exp-read-more">
          <button
            className="read-more-button"
            onClick={(e) => {
              props.history.push(props.path);
            }}
          >
            Read More
          </button>
        </div>
        <div>
          
        </div>
      </div>
    </div>
  );
}
