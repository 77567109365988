import React, { useState } from "react";
import jwtDecode from "jwt-decode";

import CustomIcons from "../../../../components/icons/kIcons.component";
import {
  adminMenu,
  intMdAdminMenu,
  superAdminMenu,
} from "../../../../utilities/reducers/admincontext";

export default function NavMenu(props) {
  let adminType = Number(jwtDecode(props.state.payload).user_type);
  const [menu, setMenu] = useState(
    adminType === 3
      ? intMdAdminMenu.menus
      : adminType === 2
      ? superAdminMenu.menus
      : adminMenu.menus
  );
  const [count, setCount] = useState(0);
  console.log(window.location.pathname);
  const renderMenu = (child, leve) => {
    let level = leve + 1;
    return (
      <>
        {menu &&
          menu.map((sub, ind) => (
            <>
              {sub.parent === child.id && (
                <>
                  <li
                    className={
                      // getId() === sub.id ||
                      window.location.pathname === sub.path
                        ? `navlist active sub`
                        : `navlist sub`
                    }
                    onClick={(e) => handlepath(level, e, sub, ind)}
                    style={{ marginLeft: 10 * level }}
                  >
                    <CustomIcons iconName={sub.icon ?? "view"} />
                    {sub.name}
                    <>
                      {sub.child &&
                        (sub.expand ? (
                          <span className="putright">
                            <CustomIcons
                              iconName={"down"}
                              iconClass="iconclass"
                            />
                          </span>
                        ) : (
                          <span className="putright">
                            <CustomIcons iconName={"down"} />
                          </span>
                        ))}
                    </>
                  </li>
                  {sub.expand && <>{renderMenu(sub, level)}</>}
                </>
              )}
            </>
          ))}
      </>
    );
  };
  const handlepath = (level, e, item, ind) => {
    console.log(item);
    localStorage.setItem("activeId", item.id);
    if (item?.path) {
      props.history.push(item.path);
    } else {
      menu[ind]["expand"] = item.expand ? false : true;

      setMenu(menu);

      setCount(count + 1);
    }
  };
  const getId = () => {
    let actId = localStorage.getItem("activeId");
    return parseInt(actId);
  };

  return (
    <div className="navbar">
      <div id="wrapper">
        <div className="sidebar">
          <ul className="sidebar-nav">
            {menu &&
              menu.map((item, index) => (
                <>
                  {!item.parent && (
                    <>
                      <li
                        className={
                          // getId() === item.id
                          // ? // ||
                          window.location.pathname === item.path
                            ? `navlist active`
                            : `navlist`
                        }
                        onClick={(e) => handlepath(0, e, item, index)}
                      >
                        <CustomIcons iconName={item.icon ?? "view"} />
                        {item.name}
                        <>
                          {item.child &&
                            (item.expand ? (
                              <span className="putright">
                                <CustomIcons
                                  iconName={"down"}
                                  iconClass="iconclass"
                                />
                              </span>
                            ) : (
                              <span className="putright">
                                <CustomIcons iconName={"down"} />
                              </span>
                            ))}
                        </>
                      </li>
                      {item.expand && <>{renderMenu(item, 0)}</>}
                    </>
                  )}
                </>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
