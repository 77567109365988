import React, { useEffect, useState } from "react";
import CustomTable from "../../../../components/table/table";
import { admin_banners_list } from "../../admin.api";
import TextField from "../../../../components/inputs/textfield";
import "./view.css";

export default function View(props) {
  const [data, setdata] = useState([]);
  const handleEventList = () => {
    admin_banners_list().then((res) => {
      setdata(res.data);
    });
  };
  useEffect(() => {
    handleEventList();
  }, []);
  const handleEdit = () => {};
  return (
    <div>
      <div className="view-container">
        <h6>
          <b>Add New Banner</b>
        </h6>
        <hr />

        <div className="row">
          <div className="col-7">
            <div className="form-group">
              <label for="exampleInputEmail1">Banner Text</label>
              <input
                type="text"
                className="form-control mt-1"
                aria-describedby=""
                placeholder=""
              />
            </div>
          </div>
          <div className="col-7 mt-3">
            <label for="exampleInputEmail1">Upload Banner image</label>
            <TextField
              type="file"
              name="file"
              id="file"
              className="inputfile bannerfile"
            />
            <small className="uploadsmall">
              *Upload only .jpg |.png |.jpeg and dimension of 1440*625
            </small>
          </div>
        </div>
        <button
          type="button"
          className="btn btn-primary btn-sm mt-3 float-right"
        >
          Submit
        </button>
      </div>
    </div>
  );
}
