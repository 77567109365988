import React from "react";
import { Route, Redirect } from "react-router-dom";
import Layout from "../../layout/userlayout/layout.component";
import jwtDecode from "jwt-decode";

const PrivateRoute = ({ component: Component, ...rest }) => {
  let user = rest.state.isUserAuthenticated;
  console.log(user);
  let admin = rest.state.isAdminAuthenticated;
  let mobile_number = rest.phone;
  let userData = rest.state.payload ? jwtDecode(rest.state.payload) : null;
  let activeUserType = userData?.user?.user_type || null;
  console.log(rest);
  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          <>
            {rest.specialAccess ? (
              <>
                {rest.specialAccess.length !== 0 &&
                  rest.specialAccess.map((item) => (
                    <>
                      {item === activeUserType ? (
                        <Layout
                          {...props}
                          {...rest}
                          key={rest.location?.key}
                          mobile_number={rest.phone}
                        >
                          <Component
                            {...props}
                            {...rest}
                            mobile_number={rest.phone}
                            key={rest.location?.key}
                          />
                        </Layout>
                      ) : (
                        <Redirect to="/dashboard" />
                      )}
                    </>
                  ))}
              </>
            ) : (
              <Layout {...props} {...rest} key={rest.location?.key}>
                <Component
                  {...props}
                  {...rest}
                  mobile_number={rest.phone}
                  key={rest.location?.key}
                />
              </Layout>
            )}
          </>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
