import React, { Component } from "react";
import CustomButton from "../../../../components/inputs/button";
import TextField from "../../../../components/inputs/textfield";
import Snackbar from "../../../../components/snackbar/snackbar";
import "./dialog.css";

class Dialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      formData: { area_management_id: props.areaId, ...props.formData },
      edit: props.edit,
      submitted: false,
      snackMessage: "",
      isSnackError: false,
      snackOpen: false,
    };
  }

  componentWillReceiveProps = (props) => {
    this.setState({
      open: props.open,
      edit: props.edit,
      formData: { area_management_id: props.areaId, ...props.formData },
    });
  };
  handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({ formData: { ...this.state.formData, [name]: value } });
  };
  handleNumberChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    var validNumber = new RegExp(/^\d*\.?\d*$/);
    if (validNumber.test(e.target.value)) {
      this.setState({ formData: { ...this.state.formData, [name]: value } });
    }
  };
  handleCheckChange = (e) => {
    let checked = e.target.checked;
    let name = e.target.name;

    if (checked) {
      this.setState({
        formData: { ...this.state.formData, tender_publish_status: 1 },
      });
    } else {
      this.setState({
        formData: { ...this.state.formData, tender_publish_status: 0 },
      });
    }
  };
  snackbarClose = (flag) => {
    this.setState({
      snackMessage: "",
      isSnackError: false,
      snackOpen: false,
    });
    if (!flag) {
      this.props.handleClose(1);
    }
  };

  handleSubmit = (e) => {
    // const error = FormValidate(this.state.formData, required);
    console.log("hi");
    const { edit, formData } = this.state;
    e.preventDefault();
    this.setState({
      submitted: true,
    });

    // if (Object.keys(error).length === 0) {
    this.props
      .submit(formData.uuid, formData)
      .then((res) => {
        this.setState({
          submitted: false,

          snackMessage: res.message,
          isSnackError: false,
          snackOpen: true,
        });
      })
      .catch((error) => {
        this.setState({
          submitted: false,
          snackMessage: error.message,
          isSnackError: true,
          snackOpen: true,
        });
      });
    // } else {
    //   this.setState({
    //     submitted: false,
    //     snackMessage: "Please fill all mandatory fields",
    //     isSnackError: true,
    //     snackOpen: true,
    //   });
    // }
  };
  handleSelect = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        parent_id: e.target.value,
      },
    });
  };
  render() {
    const { submitted, formData, open, edit } = this.state;
    const { view } = this.props;
    console.log(submitted);
    return (
      <React.Fragment>
        {open && (
          <React.Fragment>
            <div className="overlay">
              <div className="big-dia dialog">
                <Snackbar
                  message={this.state.snackMessage}
                  error={this.state.isSnackError}
                  open={this.state.snackOpen}
                  close={this.snackbarClose}
                />
                <div className="row">
                  <div className="flexDisplay">
                    {this.props.areaType === "location" && (
                      <div className="diaTitleCon">
                        {edit
                          ? "Edit Location"
                          : view
                          ? "Location"
                          : "Add Location"}
                      </div>
                    )}
                    {this.props.areaType === "builtup" && (
                      <div className="diaTitleCon">
                        {edit
                          ? "Edit Built-up Space on Rent/Month"
                          : view
                          ? "Built-up Space on Rent/Month"
                          : "Add Built-up Space on Rent/Month"}
                      </div>
                    )}
                    {this.props.areaType === "equiped" && (
                      <div className="diaTitleCon">
                        {edit
                          ? "Edit Equipped Facility"
                          : view
                          ? "Equipped Facility"
                          : "Add Equipped Facility"}
                      </div>
                    )}
                    {this.props.areaType === "landlease" && (
                      <div className="diaTitleCon">
                        {edit
                          ? "Land on 33 years lease"
                          : view
                          ? "Land on 33 years lease"
                          : "Add Land on 33 years lease"}
                      </div>
                    )}
                    {this.props.areaType === "joint" && (
                      <div className="diaTitleCon">
                        {edit
                          ? "Edit Joint Venture"
                          : view
                          ? "Joint Venture"
                          : "Add Joint Venture"}
                      </div>
                    )}
                    {this.props.areaType === "ownland" && (
                      <div className="diaTitleCon">
                        {edit
                          ? "Edit Own Land"
                          : view
                          ? "Own Land"
                          : "Add Own Land"}
                      </div>
                    )}
                    <div
                      className="dia-close"
                      onClick={(e) => this.props.handleClose()}
                    >
                      <div className="diaCloseCon">
                        <div className="diaClose">&#10005;</div>
                      </div>
                    </div>
                  </div>
                  <div className="tilebar">
                    <hr />
                  </div>
                  <form  onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="row book-form">
                      <div>
                        {(this.props.areaType === "builtup" ||
                          this.props.areaType === "ownland" ||
                          this.props.areaType === "joint" ||
                          this.props.areaType === "equiped" ||
                          this.props.areaType === "landlease") && (
                          <div className="col-12">
                            <div className="fieldset">
                              <label className="mb-1">Location</label>
                              <select
                                className="form-select form-control"
                                aria-label="Default select example"
                                disabled={view}
                                required
                                name="location_id"
                                onChange={this.handleChange}
                                value={formData.location_id}
                              >
                                <option value="">Location</option>
                                {this.props.locationData?.map((item) => (
                                  <option value={item.id}>
                                    {item.locations}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        )}
                        {(this.props.areaType === "builtup" ||
                          this.props.areaType === "equiped") && (
                          <div className="col-12">
                            <TextField
                              id="outlined-basic"
                              label="Area in Sq.ft"
                              name="area_in_sqft"
                              variant="outlined"
                              disabled={view}
                              type="text"
                              value={formData.area_in_sqft ?? ""}
                              onChange={(e) => this.handleNumberChange(e)}
                              required 
                            />
                          </div>
                        )}
                        {(this.props.areaType === "builtup" ||
                          this.props.areaType === "equiped") && (
                          <div className="col-12">
                            <TextField
                              id="outlined-basic"
                              label="Rupees / Sq.ft"
                              name="rupees_in_sqft"
                              variant="outlined"
                              disabled={view}
                              type="text"
                              value={formData.rupees_in_sqft ?? ""}
                              onChange={(e) => this.handleNumberChange(e)}
                              required
                            />
                          </div>
                        )}

                        {this.props.areaType === "equiped" && (
                          <div className="col-12">
                            <TextField
                              id="outlined-basic"
                              label="Facility"
                              name="facility"
                              variant="outlined"
                              disabled={view}
                              type="text"
                              value={formData.facility ?? ""}
                              onChange={(e) => this.handleChange(e)}
                              required
                            />
                          </div>
                        )}

                        {this.props.areaType === "landlease" && (
                          <div className="col-12">
                            <TextField
                              id="outlined-basic"
                              label="Area in acres"
                              name="area_in_acres"
                              variant="outlined"
                              disabled={view}
                              type="text"
                              value={formData.area_in_acres ?? ""}
                              onChange={(e) => this.handleNumberChange(e)}
                              required
                            />
                          </div>
                        )}
                        {this.props.areaType === "landlease" && (
                          <div className="col-12">
                            <TextField
                              id="outlined-basic"
                              label="Rs/Lakhs/Acres"
                              name="rupees_in_lakhs_or_acres"
                              variant="outlined"
                              disabled={view}
                              type="text"
                              value={formData.rupees_in_lakhs_or_acres ?? ""}
                              onChange={(e) => this.handleNumberChange(e)}
                              required
                            />
                          </div>
                        )}
                      </div>
                      {!view && (
                        <div className="col-12">
                          <CustomButton
                            label={edit ? "Update" : "Add"}
                            disabled={submitted}
                            onClick={this.handleSubmit}
                            // type="submit"
                          />
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default Dialog;
