export const builtup = {
  area_type: "built_up_space",
  area_management: [
    {
      id: 1,
      location_id: "",
      location: "",
      area_in_sqft: "",
    },
    {
      id: 2,
      location_id: "",
      location: "",
      area_in_sqft: "",
    },
    {
      id: 3,
      location_id: "",
      location: "",
      area_in_sqft: "",
    },
  ],
};
export const euip = {
  area_type: "equipped_facility",
  area_management: [
    {
      id: 1,
      location_id: "",
      location: "",

      area_in_sqft: "",
    },
    {
      id: 2,
      location_id: "",
      location: "",

      area_in_sqft: "",
    },
    {
      id: 3,
      location_id: "",
      location: "",

      area_in_sqft: "",
    },
  ],
};
export const landon = {
  area_type: "land_on",
  area_management: [
    {
      id: 1,
      location_id: "",

      location: "",
      area_in_acres: "",
    },
    {
      id: 2,
      location_id: "",
      location: "",

      area_in_acres: "",
    },
    {
      id: 3,
      location_id: "",
      location: "",

      area_in_acres: "",
    },
  ],
};
export const jointVenture = {
  area_type: "joint_venture",
  area_management: [
    {
      location_id: "",
      location: "",
      area_in_sqft: "",
      investment_model: "",
      management_plan: "",
    },
    {
      location_id: "",
      location: "",
      area_in_sqft: "",
      investment_model: "",
      management_plan: "",
    },
    {
      location_id: "",
      location: "",
      area_in_sqft: "",
      investment_model: "",
      management_plan: "",
    },
  ],
};
export const ownland = {
  area_type: "own_land",
  area_management: [
    {
      location_id: "",
      location: "",
      land_address: "",
      nature_of_holding: "",
    },
  ],
};
