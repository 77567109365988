import "bootstrap/dist/css/bootstrap.min.css";
import jwtDecode from "jwt-decode";
import { useEffect, useReducer, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { withRouter } from "react-router-dom";
import Unexpectederror from "./components/error/unexpectedError";
import { refreshAdminToken, refreshTempToken, refreshToken } from "./config";
import { refreshApi } from "./pages/auth/login/auth.api";
import KspaceRouter from "./routes/routes";
// import { Beforeunload } from "react-beforeunload";
import { authReducer } from "./utilities/reducers/auth.reducer";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return <Unexpectederror error={error} reset={resetErrorBoundary} />;
};
// let activeUser = null;
// export const refreshActive = (usertype) => {
//
//
//   activeUser = Number(usertype);
// };
let token = sessionStorage.getItem("token") ?? null;


let activeUserType = null;
if (token !== null && token !== undefined && token !== "") {
  console.log(jwtDecode(token))
  activeUserType = jwtDecode(token).user_type || jwtDecode(token).user?.user_type;
  console.log(activeUserType);
}

let loginType = "";
console.log(activeUserType);


if (activeUserType !== null && activeUserType !== undefined && activeUserType !== "") {
  if (activeUserType == 1 || activeUserType == 2 || activeUserType == 3) {
    loginType = "admin_user";
  } else {
    loginType = "public_user";
  }
} else {
  loginType = null;
}

console.log(loginType)


export const Logout = () => {
  refreshToken(null);
  //
  localStorage.clear();
  sessionStorage.clear();

  window.open("/", "_self");
};
export const adminLogout = () => {
  refreshToken(null);
  //
  localStorage.clear();
  sessionStorage.clear();

  window.open("/admin-login", "_self");
};
function App() {
  // let token = localStorage.getItem("kspaceToken");
  // let admin_token = localStorage.getItem("kspace_admin_token");
  // let temp_token = localStorage.getItem("kspace_reg_token");
  // let userType = localStorage.getItem("userType");
  // let lang = localStorage.getItem("lang");
  // let localuser = localStorage.getItem("admin_type")
  // ? Number(localStorage.getItem("admin_type"))
  // : null;

  let intial = {
    isUserAuthenticated: loginType === "public_user" ? true : false,
    isAdminAuthenticated: loginType === "admin_user" ? true : false,
    language: "en",
    isValid: false,
    payload: token ?? "",
  };
  const [state, dispatch] = useReducer(authReducer, intial);

  function refreshPage() {
    window.location.reload(false);
  }

  const Logout = () => {
    localStorage.clear();
    sessionStorage.clear()

    refreshToken(null);
    refreshAdminToken(null);
    refreshTempToken(null);
    intial.isAdminAuthenticated = false;
    intial.isUserAuthenticated = false;
    intial.isValid = false;

    // token = null;
    // admin_token = null;
    // temp_token = null;
    // userType = null;
    dispatch({ type: "LOGOUT" });

    dispatch({ type: "ADMINLOGOUT" });
    window.open("/", "_self");
  };

  const adminLogout = () => {
    localStorage.clear();
    sessionStorage.clear()

    refreshToken(null);
    refreshAdminToken(null);
    refreshTempToken(null);
    intial.isAdminAuthenticated = false;
    intial.isUserAuthenticated = false;
    intial.isValid = false;
    intial.payload = "";



    dispatch({ type: "ADMINLOGOUT" });
    window.open("/admin-login", "_self");
  };
  const setLang = (lang) => {
    dispatch({ type: lang });
  };

  const IsLogged = (data) => {
    refreshToken(data);

    dispatch({ type: "LOGIN", payload: data });
  };

  const TempUser = (data) => {
    refreshTempToken(localStorage.getItem("kspace_reg_token"));
    dispatch({ type: "ISVALID", payload: data });
  };

  const AdminLogin = (data) => {
    refreshAdminToken(data);

    dispatch({ type: "ADMINLOGIN", payload: data });
  };


  const userLogin = () => {
    localStorage.clear();
    window.open("/login", "_self");
  };

  const refectchTockenDetail = () => {
    refreshApi()
      .then((res) => {
        sessionStorage.setItem("token", res.token)
        let actUser = jwtDecode(res.token).user_type;

        if (actUser === 1 || actUser === 2 || actUser === 3) {
          AdminLogin(res.token);
        } else {
          IsLogged(res.token);
        }
      })
      .catch((err) => console.log(""));
  };
  useEffect(() => {

    setInterval(() => {
      if (state.payload) {
        refectchTockenDetail();
      }
    }, 200000);
  }, []);

  if (state.isUserAuthenticated) {
    refreshToken(state.payload);
  } else if (state.isAdminAuthenticated) {
    refreshAdminToken(state.payload);
  } else {
    refreshTempToken(null);
  }
  const [phone, setPhone] = useState("");

  return (
    <div>
      {/* <Beforeunload onBeforeunload={() => "You’ll lose your data"}> */}
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => refreshPage}
      >
        <KspaceRouter
          state={state}
          logout={Logout}
          adminLogout={adminLogout}
          userLogin={userLogin}
          login={IsLogged}
          valid={TempUser}
          setlang={setLang}
          phone={phone}
          setPhone={setPhone}
          adminLogin={AdminLogin}
        />
      </ErrorBoundary>
      {/* </Beforeunload> */}
    </div>
  );
}
export default withRouter(App);
