import React, { useState, useEffect } from "react";
import CommonForm from "../../../components/commonform/commonForm";
import CustomIcons from "../../../components/icons/kIcons.component";
import { spaceParkAdvisorsList } from "../public.api";
import { public_orgstruc_list } from "../public.api";
import Loader from "../../../components/loader/loader";
import OrgStructure from "../../../components/page/home/org-structures/org.stucture.component";

import "./style.css";

export default function List(props) {
  const [data, setData] = useState([]);
  const [isActive, setIsActive] = useState(true);

  const fetchEvents = () => {
    public_orgstruc_list().then((res) => {
      setData(res.data);
      // setUrl(res.current_url);
      setIsActive(false);
      console.log(data);
    });
  };
  useEffect(() => {
    fetchEvents();
  }, []);
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);

  console.log(data);

  return (
    <div className="proj">
      <Loader open={isActive} />
      {/* <CustomIcons iconName="phone1" iconClass="daticon" /> */}
      {/* <img src="../public/resources/pdf.png" alt="" /> */}
      {/* <div>
        <div className="dash-banner ">
          <div className="tit-txt">Governing Body</div>
        </div>
      </div> */}
      <div>
        <div className="dash-banner ">
          <div className="tit-txt">
            {props.state.language === "ma"
              ? "എക്സിക്യൂട്ടീവ് കൗൺസിൽ"
              : "Executive Council"}
          </div>
        </div>
      </div>
      <div className="container adbox mt-3 ">
        {/* <div className="hedp">Governing body of Kerala Spaceparks</div> */}

        <div className="row">
          {data &&
            Object.keys(data).map((key) => (
              <React.Fragment>
                {"1" === key && (
                  <React.Fragment>
                    <div className="strec-item-cont">
                      <OrgStructure
                        {...props}
                        data={data[key]}
                        fullwidth={true}
                        isActive={isActive}
                      />
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            ))}
        </div>

        {/* ---------------------------------------------------- */}
        {/* <div className="hedp">Executive Council of Kerala Spaceparks</div> */}
      </div>
    </div>
  );
}
