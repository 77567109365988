import React from "react";
import { Route, Redirect } from "react-router-dom";
import Layout from "../../layout/userlayout/layout.component";
const AuthRoute = ({ component: Component, ...rest }) => {
  let admin = rest.state.isAdminAuthenticated;
  let user = rest.state.isUserAuthenticated;
  let temp = rest.state.isValid;
  return (
    <Route
      {...rest}
      render={(props) =>
        !user && !temp ? (
          <Layout {...props} {...rest} key={rest.location?.key}>
            <Component {...props} {...rest} key={rest.location?.key} />
          </Layout>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default AuthRoute;
