import React from "react";

export default function Unexpectederror({ error, reset }) {
  return (
    <div>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      {/* <button onClick={reset}>Try again</button> */}
    </div>
  );
}
