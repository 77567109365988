import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import OrgStructure from "../../../../components/page/home/org-structures/org.stucture.component";
import { public_orgstruc_list } from "../../public.api";
export default function OrgStructureComp(props) {
  const [data, setdata] = useState([]);
  const [activeItem, setActiveItem] = useState("0");
  const [url, setUrl] = useState("");
  const [isActive, setIsActive] = useState(false);

  const handleOrgList = () => {
    setIsActive(true);
    public_orgstruc_list().then((res) => {
      setdata(res.data);
      setUrl(res.current_url);
      setIsActive(false);
    });
  };
  useEffect(() => {
    handleOrgList();
  }, []);
  //
  return (
    <div className="org-structure">
      {/* <div className="org-strecture-title">
        {props.state.language === "ma"
          ? "സംഘടനാ ഘടന"
          : "Organizational Structure"}
      </div> */}
      <div className="strec-cont">
        <div className="tab-cont-cont">
          <div className="tab-cont">
            <div
              className={
                activeItem === "0" ? "tab-switch activeTab" : "tab-switch"
              }
              onClick={(e) => setActiveItem("0")}
            >
              <div>
                {props.state.language === "ma" ? "ഭരണസമിതി" : "Governing Body"}
              </div>
              <div>
                <hr
                  className={
                    activeItem === "0"
                      ? "org-border-active"
                      : "org-border-inactive"
                  }
                />
              </div>
            </div>
            <div
              className={
                activeItem === "1" ? "tab-switch activeTab" : "tab-switch"
              }
              onClick={(e) => setActiveItem("1")}
            >
              <div>
                {props.state.language === "ma"
                  ? "എക്സിക്യൂട്ടീവ് കൗൺസിൽ"
                  : "Executive Council"}
              </div>
              <div>
                <hr
                  className={
                    activeItem === "1"
                      ? "org-border-active"
                      : "org-border-inactive"
                  }
                />
              </div>
            </div>
            <div
              className={
                activeItem === "2" ? "tab-switch activeTab" : "tab-switch"
              }
              onClick={(e) => setActiveItem("2")}
            >
              <div>
                {props.state.language === "ma" ? "കോർ ടീം" : "Core Team"}
              </div>
              <div>
                <hr
                  className={
                    activeItem === "2"
                      ? "org-border-active"
                      : "org-border-inactive"
                  }
                />
              </div>
            </div>
          </div>
        </div>
        {isActive ? (
          <></>
        ) : (
          <>
            {data &&
              Object.keys(data).map((key) => (
                <React.Fragment>
                  {activeItem === key && (
                    <React.Fragment>
                      <div className="strec-item-cont">
                        <OrgStructure
                          {...props}
                          data={data[key]}
                          fullwidth={true}
                          isActive={isActive}
                        />
                      </div>
                    </React.Fragment>
                  )}
                </React.Fragment>
              ))}
          </>
        )}
      </div>
    </div>
  );
}
