import React from "react";
import SearchButton from "../../../../components/buttons/search/search-btn";
import CustomTable from "../../../../components/table/table";
import "./view.css";

export default function View(props) {
  const onSearch = () => {
    //
  };
  return (
    <div>
      <div className="view-container">
        <h6>
          <b>New Proposals</b>
        </h6>
        <hr />

        <div className="row font-z">
          <div className="col-3">
            <select className="form-select" aria-label="Default select example">
              <option selected>Select Application Status</option>
              <option value="1">Pending</option>
              <option value="2">Accepted</option>
              <option value="3">Three</option>
            </select>
          </div>
          <div className="col-3">
            <select className="form-select" aria-label="Default select example">
              <option selected>Select Domain</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
          <div className="col-1 d-flex">
            <SearchButton {...props} onClick={onSearch} />
          </div>
        </div>
        <CustomTable
          {...props}
          columns={[
            { title: "Company Name", field: "name" },
            { title: "Application ID", field: "id" },
            { title: "Email", field: "email" },

            { title: "Action", field: "action", type: "button" },
            {
              title: "Current Project Status",
              field: "status",
              type: "button",
            },
          ]}
          data={[
            {
              name: "Adarsh company",
              id: "6526A",
              email: "rfghjkgh",

              action: "view,BUTTON",
              status:
                "Agrement execution | contrction started | facility errection | faclity commissioning ",
            },
          ]}
        />
      </div>
    </div>
  );
}
