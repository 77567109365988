import React, { useEffect, useState } from "react";
import ConfirmDialog from "../../../components/actionDialogs/confirmDialog";
import Loader from "../../../components/loader/loader";
import Snackbar from "../../../components/snackbar/snackbar";
import CustomTable from "../../../components/table/table";
import {
  admin_news_delete,
  admin_news_list,
} from "../admin.api";
import ManagePolicies from "./manage/manage";
import "./view.css";
import jwtDecode from "jwt-decode";
export default function View(props) {
  let adminType = Number(jwtDecode(props.state.payload).user_type);

  const [data, setdata] = useState([]);
  const [addNew, setAddNew] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [formData, setFormData] = useState({});
  const [edit, setEdit] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [activeData, setActiveData] = useState({});
  const [view, setView] = useState(false);
  const handleEventList = () => {
    setIsActive(true);
    admin_news_list().then((res) => {
      let tdata = res.data;
      tdata.forEach((item, index) => {
        item["slno"] = index + 1;
      });
      setdata(tdata);
      setIsActive(false);
    });
  };
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  const handleDownload = (item) => {
    let path = `${result}${item.file}`;
    window.open(path, "_blank");
  };
  useEffect(() => {
    handleEventList();
  }, []);
  const handleClose = () => {
    setAddNew(false);
    setEdit(false);
    setFormData({});
    handleEventList();
  };
  const handleEdit = (data) => {
    console.log(data)
    setFormData(data);
    setView(false);
    setEdit(true);
    setAddNew(true);
  };
  const handleAddNew = () => {
    setFormData({news_enable_disable :1})
    setAddNew(true);
    setView(false);
  };
  const handleDelete = (data) => {
    console.log(data)
    setActiveData(data);
    setOpenAction(true);
  };
  const handleCancelDelete = () => {
    setOpenAction(false);
  };
  const handleDeleteConfirm = (data) => {
    setOpenAction(false);
    setIsActive(true);
    admin_news_delete(data.id)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setIsActive(false);
        setSnackOpen(true);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setIsActive(false);
        setSnackOpen(true);
      });
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      handleEventList();
    }
  };
  const handleView = (data) => {
    setFormData(data);
    setAddNew(true);
    setView(true);
  };
  return (
    <div>
      <Loader open={isActive} />
      <div className="view-container">
        <h6>
          <b>News and Updates</b>
        </h6>
       
        <hr />
        {adminType === 1 && (
          <button
            type="button"
            className="btn btn-primary btn-sm mb-2"
            onClick={(e) => handleAddNew(e)}
          >
            Add New
          </button>
        )}
        <CustomTable
          {...props}
          columns={[
            { title: "Sl. No", type: "dynId" },
            { title: "Title", field: "title" },
            { title: "Description", field: "description" },
            { title: "Link", field: "link" },
            { title: "File", field: "file", type: "download" },
          ]}
          data={data}
          actions={
            adminType === 2
              ? [
                  { type: "view", handleClick: handleView },
                  { type: "edit", handleClick: handleEdit },
                  { type: "delete", handleClick: handleDelete },
                ]
              : [
                  { type: "view", handleClick: handleView },
                  { type: "edit", handleClick: handleEdit },
                ]
          }
          handleDownload={handleDownload}
        />
      </div>
      {addNew && (
        <ManagePolicies
          open={addNew}
          edit={edit}
          view={view}
          handleClose={handleClose}
          formData={formData}
        />
      )}
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      {openAction && (
        <ConfirmDialog
          open={openAction}
          title="Delete News ?"
          data={activeData}
          message="Are you sure to delete this news?"
          secondary_action="Cancel"
          primary_action="Delete"
          handleClose={handleCancelDelete}
          handleConfirm={handleDeleteConfirm}
        />
      )}
    </div>
  );
}
