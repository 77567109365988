import React, { Component } from "react";
import CustomButton from "../../../../components/inputs/button";
import TextField from "../../../../components/inputs/textfield";
import Snackbar from "../../../../components/snackbar/snackbar";
import {
  admin_event_booking_accept,
  interadmin_event_booking_accept,
  admin_event_booking_reject,
  interadmin_event_booking_reject,
} from "../../admin.api";
import "./dialog.css";

let required = [
  "banner_location_text_ma",
  "notification_title_ma",
  "notification_desc_ma",
  "notification_desc_en",
];
class Dialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      formData: props.formData,
      adminType: props.adminType,
      edit: props.edit,
      submitted: false,
      snackMessage: "",
      isSnackError: false,
      snackOpen: false,
    };
  }

  componentWillReceiveProps = (props) => {
    this.setState({
      open: props.open,
      edit: props.edit,
      formData: props.formData,
      adminType: props.adminType,
    });
  };
  handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({ formData: { ...this.state.formData, [name]: value } });
  };
  snackbarClose = (flag) => {
    this.setState({
      snackMessage: "",
      isSnackError: false,
      snackOpen: false,
    });
    if (!flag) {
      this.props.handleClose();
    }
  };
  handleApprove = (e) => {
    interadmin_event_booking_accept(this.state.formData.uuid)
      .then((res) => {
        this.setState({
          submitted: true,

          snackMessage: res.message,
          isSnackError: false,
          snackOpen: true,
        });
      })
      .catch((error) => {
        this.setState({
          submitted: false,
          snackMessage: error.message,
          isSnackError: true,
          snackOpen: true,
        });
      });
  };
  handleRecommend = (e) => {
    admin_event_booking_accept(this.state.formData.uuid)
      .then((res) => {
        this.setState({
          submitted: true,

          snackMessage: res.message,
          isSnackError: false,
          snackOpen: true,
        });
      })
      .catch((error) => {
        this.setState({
          submitted: false,
          snackMessage: error.message,
          isSnackError: true,
          snackOpen: true,
        });
      });
  };

  handleRecommend = (e) => {
    admin_event_booking_accept(this.state.formData.uuid)
      .then((res) => {
        this.setState({
          submitted: true,

          snackMessage: res.message,
          isSnackError: false,
          snackOpen: true,
        });
      })
      .catch((error) => {
        this.setState({
          submitted: false,
          snackMessage: error.message,
          isSnackError: true,
          snackOpen: true,
        });
      });
  };

  handleReject = () => {
    interadmin_event_booking_reject(this.state.formData.uuid)
      .then((res) => {
        this.setState({
          submitted: true,

          snackMessage: res.message,
          isSnackError: false,
          snackOpen: true,
        });
      })
      .catch((error) => {
        this.setState({
          submitted: false,
          snackMessage: error.message,
          isSnackError: true,
          snackOpen: true,
        });
      });
  };

  render() {
    const { submitted, formData, adminType, open, edit } = this.state;
    const { view } = this.props;
    console.log(this.state.formData);

    return (
      <React.Fragment>
        {open && (
          <React.Fragment>
            <div className="overlay">
              <div className="dia-big-event-actiity dialog">
                
                <Snackbar
                  message={this.state.snackMessage}
                  error={this.state.isSnackError}
                  open={this.state.snackOpen}
                  close={this.snackbarClose}
                />
                <div className="row">
                  <div className="flexDisplay">
                    <div className="diaTitleCon">
                      {edit
                        ? "Edit Events"
                        : view
                        ? "Event Booking Details"
                        : "Add Events"}
                    </div>
                    <div className="dia-close" onClick={this.props.handleClose}>
                      <div className="diaCloseCon">
                        <div className="diaClose">&#10005;</div>
                      </div>
                    </div>
                  </div>
                  <div className="tilebar">
                    <hr />
                  </div>
                  <form action="">
                    <div className="row event-booking-form">
                      <div className="col-6">
                        <TextField
                          id="outlined-basic"
                          disabled={view}
                          label=" Event Name"
                          variant="outlined"
                          onChange={(e) => this.handleChange(e)}
                          value={formData.event_name_en ?? ""}
                          name="event_title_en"
                          required
                        />
                      </div>

                      <div className="col-6">
                        <TextField
                          id="outlined-basic"
                          disabled={view}
                          label="Organizer"
                          variant="outlined"
                          onChange={(e) => this.handleChange(e)}
                          value={formData.oraganized_by_en ?? ""}
                          name="oraganized_by_en"
                          required
                        />
                      </div>

                      <div className="col-6">
                        <TextField
                          id="outlined-basic"
                          disabled={view}
                          label="User Name"
                          variant="outlined"
                          onChange={(e) => this.handleChange(e)}
                          value={formData.full_name ?? ""}
                          name="full_name"
                          required
                        />
                      </div>
                      <div className="col-6">
                        <TextField
                          id="outlined-basic"
                          disabled={view}
                          label="Address"
                          variant="outlined"
                          onChange={(e) => this.handleChange(e)}
                          value={formData.address ?? ""}
                          name="address"
                          type="text"
                          required
                        />
                      </div>
                      <div className="col-6">
                        <TextField
                          id="outlined-basic"
                          disabled={view}
                          label="Contact number"
                          variant="outlined"
                          onChange={(e) => this.handleChange(e)}
                          value={formData.contact_no ?? ""}
                          name="contact_no"
                          type="number"
                          required
                        />
                      </div>
                      <div className="col-6">
                        <TextField
                          id="outlined-basic"
                          disabled={view}
                          label="Event Booking Date"
                          variant="outlined"
                          onChange={(e) => this.handleChange(e)}
                          value={formData.event_booking_date ?? ""}
                          name="event_booking_date"
                          // type="date"
                          required
                        />
                      </div>
                      <div className="col-6">
                        <TextField
                          id="outlined-basic"
                          disabled={view}
                          label="Amount Paid"
                          variant="outlined"
                          onChange={(e) => this.handleChange(e)}
                          value={formData.paid_amount ?? ""}
                          name="paid_amount"
                          required
                        />
                      </div>
                      <div className="col-6">
                        <TextField
                          id="outlined-basic"
                          disabled={view}
                          label="Reference Number"
                          variant="outlined"
                          onChange={(e) => this.handleChange(e)}
                          value={formData.reference_no ?? ""}
                          name="reference_no"
                          type="text"
                          required
                        />
                      </div>

                      {adminType === 1 && (
                        <React.Fragment>
                          {formData.booking_status === "0" && (
                            <center>
                              <CustomButton
                                label=" Recommend"
                                onClick={(e) => {
                                  this.handleRecommend(e);
                                }}
                              />
                              {/* Request to Approve */}

                              {/* <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={(e) => this.handleReject(e)}
                                >
                                  Reject
                                </button> */}
                            </center>
                          )}
                        </React.Fragment>
                      )}

                      {adminType === 3 && (
                        <React.Fragment>
                          <center>
                            {formData.booking_status === "3" && (
                              <React.Fragment>
                                <button
                                  type="button"
                                  className="btn btn-success event-booking-buttons"
                                  onClick={(e) => {
                                    this.handleApprove(e);
                                  }}
                                >
                                  {" "}
                                  Approve
                                </button>

                                <button
                                  type="button"
                                  className="btn btn-danger event-booking-buttons"
                                  onClick={(e) => this.handleReject(e)}
                                >
                                  Reject
                                </button>
                              </React.Fragment>
                            )}
                          </center>
                        </React.Fragment>
                      )}

                      {/* {!this.props.view&&
                      <div className="col-12">
                        <CustomButton
                          label={edit ? "Update" : "Add"}
                          disabled={submitted}
                          onClick={(e) => this.handleSubmit(e)}
                        />
                      </div>} */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default Dialog;
