import React, { useEffect, useState } from "react";
import ManageOrganizationList from "./manage/manage";
import CustomTable from "../../../../components/table/table";
import ConfirmDialog from "../../../../components/actionDialogs/confirmDialog";
import Snackbar from "../../../../components/snackbar/snackbar";
import "./view.css";
import Loader from "../../../../components/loader/loader";
import { admin_orgs, admin_org_list, admin_org_delete } from "../../admin.api";
import jwtDecode from "jwt-decode";
export default function View(props) {
  const [data, setdata] = useState([]);
  const [isMembers, setIsMembers] = useState(false);
  let adminType = Number(jwtDecode(props.state.payload).user_type);
  const [addNew, setAddNew] = useState(false);
  const [formData, setFormData] = useState({});
  const [edit, setEdit] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [activeData, setActiveData] = useState({});
  const [isActive, setIsActive] = useState(true);
  const [view, setView] = useState(false);
  const [activeMembers, setActiveMembers] = useState(0);
  const [types, setTypes] = useState([]);
  const [members, setMembers] = useState([]);
  const handleMembersList = () => {
    setIsActive(true);
    admin_org_list().then((res) => {
      let tempData = res.data;
      let activeSelection = [];
      let id = activeMembers;
      tempData.forEach((item, index) => {
        item["slno"] = index + 1;
      });
      setdata(tempData);

      if (activeMembers === 0) {
        setMembers([]);
      } else {
        tempData.forEach((item) => {
          if (Number(item.id) === Number(id)) {
            activeSelection = item.children;
            activeSelection.forEach((item, index) => {
              item["slno"] = index + 1;
            });
          }
        });
        setMembers(activeSelection);
      }
      setIsActive(false);
    });
  };
  const handleEventList = () => {
    admin_orgs().then((res) => {
      let tempData = res.data;
      let typeData = [];
      if (tempData) {
        tempData.forEach((item, index) => {
          if (item.parent_id === 0) {
            typeData.push(item);
          }
        });
        setTypes(typeData);
        handleMembersList();
      }
    });
  };
  useEffect(() => {
    handleEventList();
  }, []);
  const handleClose = () => {
    setAddNew(false);
    setEdit(false);
    setFormData({});

    handleEventList();
  };
  const handleEdit = (data) => {
    setFormData(data);
    setView(false);
    setEdit(true);
    setIsMembers(true);
    setAddNew(true);
  };
  const handleEditStrec = (data) => {
    setFormData(data);
    setView(false);
    setEdit(true);
    setIsMembers(false);
    setAddNew(true);
  };
  const handleAddNew = () => {
    setAddNew(true);
    setView(false);
    setIsMembers(true);
  };
  const handleAddNewStrec = () => {
    setAddNew(true);
    setView(false);
    setIsMembers(false);
  };
  const handleDelete = (data) => {
    setActiveData(data);
    setOpenAction(true);
  };
  const handleCancelDelete = () => {
    setOpenAction(false);
  };
  const handleDeleteConfirm = (data) => {
    setOpenAction(false);
    setIsActive(true);
    admin_org_delete(data.uuid)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setIsActive(false);
        setSnackOpen(true);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setIsActive(false);
        setSnackOpen(true);
      });
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      handleEventList();
    }
  };
  const handleViewStrec = (data) => {
    setFormData(data);
    setAddNew(true);
    setIsMembers(false);
    setView(true);
  };
  const handleView = (data) => {
    setFormData(data);
    setAddNew(true);
    setIsMembers(true);
    setView(true);
  };
  const handleSelect = (e) => {
    let id = e.target.value;
    setActiveMembers(id);
    let activeSelection = [];
    if (id === 0) {
      setMembers([]);
    } else {
      data.forEach((item) => {
        if (Number(item.id) === Number(id)) {
          activeSelection = item.children;
          activeSelection.forEach((item, index) => {
            item["slno"] = index + 1;
          });
        }
      });
      setMembers(activeSelection);
    }
  };
  return (
    <div>
      <Loader open={isActive} />
      <div className="view-container">
        <h6>
          <b>Organizational Structure </b>
        </h6>
        <hr />
        {/* <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={(e) => handleAddNewStrec(e)}
        >
          Add New Structure
        </button> */}
        <CustomTable
          {...props}
          columns={[
            { title: "Structure", field: "main_category" },
            { title: "Chief Name", field: "title_en" },
            { title: "Designation", field: "designation_en" },
            { title: "Image", field: "image", type: "image" },
          ]}
          data={types}
          actions={[
            { type: "view", handleClick: handleViewStrec },
            { type: "edit", handleClick: handleEditStrec },
            // { type: "delete", handleClick: handleDelete },
          ]}
        />
      </div>
      <div className="view-container">
        <h6>
          <b> Organizational Structure - Members</b>
        </h6>
        <hr />
        <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={(e) => handleAddNew(e)}
        >
          Add New Member
        </button>
        <hr />
        <div className="row">
          <div className="col-12">
            <label className="control-label mylabel">Structure</label>
            <select
              className="form-select input-group.mandatory"
              aria-label="Default select example"
              name="main_category"
              disabled={view}
              onClick={(e) => handleSelect(e)}
            >
              <option value={0} selected>
                Select Structure
              </option>
              {types.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.main_category}
                </option>
              ))}
            </select>
          </div>
        </div>

        <CustomTable
          {...props}
          columns={[
            { title: "SI No", field: "slno" },
            { title: "Member Name", field: "title_en" },
            { title: "Designation", field: "designation_en" },
            { title: "Image", field: "image", type: "image" },
          ]}
          data={members}
          actions={
            adminType === 2
              ? [
                  { type: "view", handleClick: handleView },
                  { type: "edit", handleClick: handleEdit },
                  { type: "delete", handleClick: handleDelete },
                ]
              : [
                  { type: "view", handleClick: handleView },
                  { type: "edit", handleClick: handleEdit },
                ]
          }
        />
      </div>
      {addNew && (
        <ManageOrganizationList
          open={addNew}
          edit={edit}
          isMembers={isMembers}
          view={view}
          handleClose={handleClose}
          formData={formData}
          options={types}
        />
      )}
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      {openAction && (
        <ConfirmDialog
          open={openAction}
          title="Delete Organization ?"
          data={activeData}
          message="Are you sure to delete this Organization?"
          secondary_action="Cancel"
          primary_action="Delete"
          handleClose={handleCancelDelete}
          handleConfirm={handleDeleteConfirm}
        />
      )}
    </div>
  );
}
