import React from "react";
import CustomIcons from "../../../../../components/icons/kIcons.component";

export default function DashApplicationDetails(props) {
  console.log(
    props?.data?.application_id,
    props?.userType,
    props?.appData?.application_status
  );
  return (
    <React.Fragment>
      <div>
        <div className="row muli">
          <div className="col-12 col-md-6 dash-content-detail-items">
            <div className="userDashb h-100">
              <div className="u-logo">
                <div className="user-name-logo">
                  {props?.userType !== 8 &&
                    props.data?.company_name?.substring(0, 1)}
                  {props?.userType == 8 &&
                    props.appData?.institution_name?.substring(0, 1)}
                  {props?.userType == 20 &&
                    props.appData?.name?.substring(0, 1)}
                </div>
              </div>
              <div className="user-name-details d-flex flex-column justify-content-between">
                <div>
                  {props?.userType !== 9 &&
                    props?.userType !== 10 &&
                    props?.userType !== 8 && (
                      <div className="dashCompanyName">
                        {" "}
                        {props.data?.company_name}
                      </div>
                    )}
                  {props?.userType == 9 && (
                    <div className="dashCompanyName">
                      {" "}
                      {props.appData?.first_name} {props.appData?.last_name}
                    </div>
                  )}
                  {props?.userType == 10 && (
                    <div className="dashCompanyName">
                      {" "}
                      {props.appData?.first_name} {props.appData?.last_name}
                    </div>
                  )}
                  {props?.userType == 8 && (
                    <div className="dashCompanyName">
                      {" "}
                      {props.appData?.institution_name}
                    </div>
                  )}
                  {props?.userType == 20 && (
                    <div className="dashCompanyName">
                      {" "}
                      {props.appData?.name}
                    </div>
                  )}
                  <div className="dashPhoneNumber"> {props.data?.email}</div>
                </div>{" "}
                {(props.userType === 0 || props.userType === 4) && (
                  <div className="dash-application-details-container">
                    <div className="dashuserApplicationId">
                      Application ID : {props?.data?.application_id}
                    </div>
                    <div className="dashuserStatus">
                      <div className="">
                        {" "}
                        {/* {props.status?.common_user_status} */}
                        {props?.appData?.application_status === 0
                          ? "Submitted"
                          : props.appData?.application_status === 1
                          ? "In Progress"
                          : props.appData?.application_status === 2
                          ? "Accepted"
                          : props.appData?.application_status === 3
                          ? "In Progress"
                          : props.appData?.application_status === 5
                          ? "Reverted"
                          : props.appData?.application_status === 4
                          ? "Rejected"
                          : props.appData?.application_status === 6
                          ? "Agreement Execution"
                          : ""}
                      </div>
                    </div>
                  </div>
                )}
                {(props.userType === 9 ||
                  props.userType === 8 ||
                  props.userType === 10) && (
                  <div className="dash-application-details-container">
                    <div className="dashuserApplicationId">
                      Application Status :
                    </div>
                    <div className="dashuserStatus ">
                      <div className="">
                        {" "}
                        {props.appData?.approval_status === 0
                          ? "Submitted"
                          : props.appData?.approval_status === 1
                          ? "In Progress"
                          : props.appData?.approval_status === 2
                          ? "Accepted"
                          : props.appData?.approval_status === 3
                          ? "In Progress"
                          : props.appData?.approval_status === 5
                          ? "Reverted"
                          : props.appData?.approval_status === 4
                          ? "Rejected"
                          : ""}
                      </div>
                    </div>
                  </div>
                )}
                {props.userType === 20 && (
                  <div className="dash-application-details-container">
                    <div className="dashuserApplicationId">
                      Application Status :
                    </div>
                    <div className="dashuserStatus ">
                      <div className="">
                        {" "}
                        {props.appData?.status === 0
                          ? "Pending"
                          : props.appData?.status === 1
                          ? "Submitted"
                          : props.appData?.status === 2
                          ? "Accepted"
                          : props.appData?.status === 3
                          ? "Reverted"
                          : props.appData?.status === 5
                          ? "Rejected"
                          : props.appData?.status === 4
                          ? "Submitted"
                          : ""}
                      </div>
                    </div>
                  </div>
                )}
                {(props.userType === 6 ||
                  // props.userType === 4 ||
                  props.userType === 5) && (
                  <div className="dash-application-details-container">
                    <div className="dashuserApplicationId">
                      Application Status :
                    </div>
                    <div className="dashuserStatus ">
                      <div className="">
                        {" "}
                        {props.appData?.project_status === 0
                          ? "Submitted"
                          : props.appData?.project_status === 1
                          ? "In Progress"
                          : props.appData?.project_status === 2
                          ? "Accepted"
                          : props.appData?.project_status === 3
                          ? "In Progress"
                          : props.appData?.project_status === 5
                          ? "Reverted"
                          : props.appData?.project_status === 4
                          ? "Rejected"
                          : ""}
                      </div>
                    </div>
                  </div>
                )}
                {props?.userType === 0 && props?.userType !== 20 &&
                  props?.appData?.application_status !== 4 && (
                    <div
                      className="dashuserprof"
                      onClick={(e) => props.handleMenus("profile")}
                    >
                      <div>My Application </div>
                    </div>
                  )}
                {props.userType !== 0 &&
                  props.userType !== 7 &&
                  props?.userType !== 20 &&
                  (props.appData?.approval_status !== 3 ||
                    props.appData?.project_status !== 3) && (
                    <div
                      className="dashuserprof"
                      onClick={(e) => props.handleMenus("profile")}
                    >
                      <div>My Application</div>
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 dash-content-detail-items">
            <div className="kspa-response">
              <div className="message-from-kspace-btn">KSPACE Message</div>
              {props.userType === 0 && (
                <div className="message-from-kspace p-2">
                  {props.appData?.user_message &&
                  props.appData?.application_status === 5 ? (
                    <div className="">{props.appData?.user_message}</div>
                  ) : (
                    <div className="dash-app-dettail-no-msg">
                      No message from KSPACE
                    </div>
                  )}
                </div>
              )}
              {props.userType !== 0 && (
                <div className="message-from-kspace p-2">
                  {props.appData?.user_message &&
                  (props.appData?.approval_status === 5 ||
                    props.appData?.project_status === 5 || props.appData?.status === 3) ? (
                    <div className="">{props.appData?.user_message}</div>
                  ) : (
                    <div className="dash-app-dettail-no-msg">
                      No message from KSPACE
                    </div>
                  )}
                </div>
              )}
              {props.userType === 0 &&
                props.appData?.application_status === 5 && (
                  <div className="message-from-kspace-action-cont">
                    {props?.status?.file_name && (
                      <div className="file-from-kspace">
                        <a
                          href={`${props.url}/${props?.status?.file_name}`}
                          target="_blank"
                        >
                          <CustomIcons
                            iconName="dashattachment"
                            iconClass="dash-attachment-icon"
                          />
                        </a>
                      </div>
                    )}
                    <div
                      className="update-current-propsosal blink"
                      onClick={(e) => {
                        props.updateProposal(props.userType);
                      }}
                    >
                      Update
                    </div>
                  </div>
                )}
              {props.userType !== 0 && props.appData?.approval_status === 5 && (
                <div className="message-from-kspace-action-cont">
                  {/* {props.status?.sample_file && (
                    <div className="file-from-kspace">
                      <a
                        href={`${props.url}/${props.status?.sample_file}`}
                        target="_blank"
                      >
                        <CustomIcons
                          iconName="dashattachment"
                          iconClass="dash-attachment-icon"
                        />
                      </a>
                    </div>
                  )} */}
                  <div
                    className="update-current-propsosal blink"
                    onClick={(e) => {
                      props.updateProposal(props.userType);
                    }}
                  >
                    Update
                  </div>
                </div>
              )}
              {props.userType !== 0 && props.appData?.project_status === 5 && (
                <div className="message-from-kspace-action-cont">
                  {/* {props.status?.sample_file && (
                    <div className="file-from-kspace">
                      <a
                        href={`${props.url}/${props.status?.sample_file}`}
                        target="_blank"
                      >
                        <CustomIcons
                          iconName="dashattachment"
                          iconClass="dash-attachment-icon"
                        />
                      </a>
                    </div>
                  )} */}
                  <div
                    className="update-current-propsosal blink"
                    onClick={(e) => {
                      props.updateProposal(props.userType);
                    }}
                  >
                    Update
                  </div>
                </div>
              )}
              {props.userType !== 0 && props?.userType===20 &&  (
                <div className="message-from-kspace-action-cont">
                  {/* {props.status?.sample_file && (
                    <div className="file-from-kspace">
                      <a
                        href={`${props.url}/${props.status?.sample_file}`}
                        target="_blank"
                      >
                        <CustomIcons
                          iconName="dashattachment"
                          iconClass="dash-attachment-icon"
                        />
                      </a>
                    </div>
                  )} */}
                  <div
                    className="update-current-propsosal blink"
                    onClick={(e) => {
                      props.updateProposal(props.userType);
                    }}
                  >
                    {props.userType !== 20 ? 
                    "Update" : "Application Update" }
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
