import React, { useEffect, useState, useCallback } from "react";
import GalleryTile from "../../../../components/page/home/gallery/gallery.tile.component";
import { public_gallery_list } from "../../public.api";
import VideoComp from "./videos";

export default function Gallery(props) {
  const [data, setdata] = useState([]);
  const [url, setUrl] = useState("");
  const [activeMenu, setActiveMenu] = useState("image");
  const [preview, setPreview] = useState(false);
  const [keyV, setKeyV] = useState(0);
  const [activeSelection, setActiveSelection] = useState(null);
  const handleEventList = () => {
    public_gallery_list().then((res) => {
      setdata(res.data);
      setUrl(res.current_url);
    });
  };
  useEffect(() => {
    handleEventList();
    window.scrollTo(0, 0);
    console.log(window);
  }, []);
  const handlePreview = (index, flag) => {
    if (flag) {
      setActiveSelection(index);
    } else {
      setActiveSelection(null);
    }
  };
  const keyBoardActions = (event) => {
    // setActiveSelection((c) => c);
    if (event.keyCode === 27) {
      handlePreview();
    }
    // } else if (event.keyCode === 37) {
    //   handleBackward(event);
    // } else if (event.keyCode === 39) {
    //   handleForward(event);
    // }
  };

  useEffect(() => {
    document.addEventListener("keydown", keyBoardActions);

    return () => {
      document.removeEventListener("keydown", keyBoardActions);
    };
  }, []);

  const mangeViewMore = (e) => {
    let path = "";
    sessionStorage.setItem("continueFrom", "gallery");
    if (activeMenu === "image") {
      props.history.push("/gallery/images");
    } else if (activeMenu === "video") {
      props.history.push("/gallery/video");
    }
  };
  let lang = props.state.language;
  const handleBackward = (e) => {
    if (e) {
      e.stopPropagation();
    }
    setActiveSelection((c) => c);
    if (activeSelection) {
      let sel = Number(activeSelection);
      if (sel === 0) {
        setActiveSelection(`${data.length - 1}`);
      } else {
        setActiveSelection(`${sel - 1}`);
      }
    }
    setKeyV(keyV + 1);
  };
  const handleForward = (e) => {
    if (e) {
      e.stopPropagation();
    }
    setActiveSelection((c) => c);
    let max = data.length - 1;
    if (activeSelection) {
      let sel = Number(activeSelection);
      console.log(sel);
      console.log(max);
      if (sel === max) {
        setActiveSelection("0");
      } else {
        setActiveSelection(`${sel + 1}`);
      }
    }
    setKeyV(keyV + 1);
  };
  console.log(activeSelection);
  return (
    <>
      {activeSelection && (
        <div className="gallery-preview " onClick={() => handlePreview()}>
          <div className="w-100 p-3 position-relative">
            <img
              src={data[activeSelection][lang]?.images}
              className="w-100 curser-zoomout"
              alt=""
            />
            <div
              className="gallery-arrow left "
              onClick={(e) => handleBackward(e)}
            >
              &lt;
            </div>
            <div
              className="gallery-arrow right "
              onClick={(e) => handleForward(e)}
            >
              &gt;
            </div>
          </div>
        </div>
      )}

      <div className="">
        <div className="tab-cont-cont">
          <div className=" tab-cont">
            <div
              className={
                activeMenu === "image"
                  ? "tab-switch activeTab w-50"
                  : "tab-switch w-50"
              }
              onClick={(e) => {
                setActiveMenu("image");
              }}
            >
              <div>
                {" "}
                {props.state.language === "ma" ? "ചിത്രങ്ങൾ" : "Images"}
              </div>
              <div>
                <hr
                  className={
                    activeMenu === "image"
                      ? "gal-border-active"
                      : "org-border-inactive"
                  }
                />
              </div>
            </div>
            <div
              className={
                activeMenu === "video"
                  ? "tab-switch activeTab w-50"
                  : "tab-switch w-50"
              }
              onClick={(e) => {
                setActiveMenu("video");
              }}
            >
              <div>
                {" "}
                {props.state.language === "ma" ? "വീഡിയോകൾ" : "Videos"}
              </div>
              <div>
                <hr
                  className={
                    activeMenu === "video"
                      ? "gal-border-active"
                      : "org-border-inactive"
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {activeMenu === "image" && (
          <div className="gallery-data-container">
            <div className="gallery">
              {data?.map((item, index) => (
                <GalleryTile
                  data={item}
                  handlePreview={(e, f) => handlePreview(e, f)}
                  activeIndex={activeSelection}
                  count={index}
                  {...props}
                  type={activeMenu}
                />
              ))}
            </div>
          </div>
        )}
        {activeMenu === "video" && <VideoComp {...props} />}
        <div style={{ padding: "50px 50px 0px" }}>
          <div className="all-to-center ">
            <button
              className="read-more-btn-gallery"
              onClick={(e) => {
                mangeViewMore(e);
              }}
            >
              See All
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
