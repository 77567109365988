import React, { useEffect, useState } from "react";
import MultiCarousal from "../../../../pages/public/home/components/components/multicarousals/multi.carousal";
export default function PartnerSection(props) {
  const [isActive, setIsActive] = useState(props.isActive);
  const [dataD, setDataD] = useState([]);
  useEffect(() => {
    setIsActive(props.isActive);
  }, [props.isActive]);
  let lang = props.state.language;
  // console.log()
  useState(() => {
    setDataD([])
    
    let newArray = props.data.filter((item) => item.type === props.active)
   
    setDataD(newArray);
  }, [props?.key]);

  return (
    <div className="pt-4">
      {/* <div className="chief-item"> */}
      {/* <div>
          <img
            className="org-avatar chief"
            src={props.data?.Image}
            alt=""
          />
        </div> */}
      {/* <div className="org-title">{props.data[lang]?.Title}</div> */}
      {/* <div className="org-designation">
          <b> Chief Minister of Kerala</b>
        </div> */}
      {/* <div className="org-designation">{props.data[lang]?.Designation}</div> */}
      {/* </div> */}
      {dataD &&
      <div className="org-sub-containers " key={props?.active}>
        
        {dataD.length < 4 ? (
           <div className="d-flex justify-content-evenly align-items-center w-100">
          {dataD.map((item, index) => (
            <>
           
              <img
                className="par-avatar-partners-small"
                key={index}
                src={item.image}
                alt=""
              />
              
            </>
          ))}</div>
        ) : (
          <MultiCarousal key={props.active} speed = {1500}>
            {dataD?.map((item, index) => (
              <>
                <img
                  className="par-avatar-partners"
                  key={index}
                  src={item.image}
                  alt=""
                />
              </>
            ))}
          </MultiCarousal>
        )}
        

      
      </div>}
    </div>
  );
}
