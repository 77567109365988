export const authReducer = (state, action) => {
  console.log(action);
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        payload: action.payload,
        isAdminAuthenticated: false,
        isUserAuthenticated: true,
        isValid: false,
      };
    case "LOGOUT":
      return {
        ...state,

        isAdminAuthenticated: false,
        isUserAuthenticated: false,
        isValid: false,
      };
    case "ADMINLOGIN":
      return {
        ...state,
        payload: action.payload,
        isAdminAuthenticated: true,
        isValid: false,
        isUserAuthenticated: false,
      };
    case "ADMINLOGOUT":
      return {
        ...state,
        isAdminAuthenticated: false,
        isUserAuthenticated: false,
        isValid: false,
      };
    case "ISVALID":
      return {
        ...state,

        isAdminAuthenticated: false,
        isUserAuthenticated: false,
        isValid: true,
        payload: action.payload,
      };
    case "SWITCHMAL":
      return {
        ...state,
        language: "ma",
        // isValid: false,
      };
    case "SWITCHENG":
      return {
        ...state,
        language: "en",
        // isValid: false,
      };
    case "SETDATA":
      return {
        ...state,
        payload: action.payload,
      };

    default:
      throw new Error();
  }
};
