import React, { Component } from "react";
import { FormValidate } from "../../../../../components/formValidate";
import CustomButton from "../../../../../components/inputs/button";
import TextField from "../../../../../components/inputs/textfield";
import Loader from "../../../../../components/loader/loader";
import Snackbar from "../../../../../components/snackbar/snackbar";
import { admin_videos_add, admin_videos_update } from "../../../admin.api";
import "./dialog.css";

let required = ["video_title_ma", "video_title_en", "status", "videos"];
class Dialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      formData: props.formData,
      edit: props.edit,
      submitted: false,
      fileChanged: false,
      isActive: false,
      snackMessage: "",
      isSnackError: false,
      snackOpen: false,
    };
  }

  componentWillReceiveProps = (props) => {
    this.setState({
      open: props.open,
      edit: props.edit,
      formData: props.formData,
    });
  };
  handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({ formData: { ...this.state.formData, [name]: value } });
  };
  snackbarClose = (flag) => {
    this.setState({
      snackMessage: "",
      isSnackError: false,
      snackOpen: false,
    });
    if (!flag) {
      this.props.handleClose();
    }
  };
  handleSubmit = (e) => {
    const error = FormValidate(this.state.formData, required);
    const { edit, formData } = this.state;
    e.preventDefault();

    if (Object.keys(error).length === 0) {
      this.setState({ isActive: true });
      const form = new FormData();
      form.append("video_title_en", formData.video_title_en);
      form.append("video_title_ma", formData.video_title_ma);
      form.append("status", formData.status);
      form.append("file_name", formData.file_name);
      form.append("videos", this.state.fileChanged ? formData.videos : null);
      if (formData.id) form.append("video_id", formData.id);
      if (edit) {
        admin_videos_update(formData.uuid, form)
          .then((res) => {
            this.setState({
              submitted: true,
              isActive: false,
              snackMessage: res.message,
              isSnackError: false,
              snackOpen: true,
            });
          })
          .catch((error) => {
            this.setState({
              submitted: false,
              isActive: false,
              snackMessage: error.message,
              isSnackError: true,
              snackOpen: true,
            });
          });
      } else {
        admin_videos_add(form)
          .then((res) => {
            this.setState({
              submitted: true,
              isActive: false,
              snackMessage: res.message,
              isSnackError: false,
              snackOpen: true,
            });
          })
          .catch((error) => {
            this.setState({
              submitted: false,
              isActive: false,
              snackMessage: error.message,
              isSnackError: true,
              snackOpen: true,
            });
          });
      }
    } else {
      this.setState({
        submitted: false,
        snackMessage: "Please fill all mandatory fields",
        isSnackError: true,
        snackOpen: true,
      });
    }
  };
  handleFileChange = (e) => {
    let size = e.target.files[0]?.size ?? 0;
    // var reader = new FileReader();
    // reader.readAsDataURL(e.target.files[0]);
    // reader.onload = function (e) {
    //   var image = new Image();
    //   image.src = e.target.result;

    //   image.onload = function () {
    //     var height = this.height;
    //     var width = this.width;

    //     if (height === 625 || width === 1440) {
    //       flag = true;
    //     }
    //   };
    // };

    let type = e.target.files[0].name.split(".").pop();

    if (type === "mp4") {
      let mb = size / Math.pow(1024, 2);

      if (mb < 21) {
        this.setState({
          fileChanged: true,
          formData: {
            ...this.state.formData,
            file_name: e.target.files[0].name,
            [e.target.name]: e.target.files[0],
          },
        });
      } else {
        this.setState({
          snackMessage: "Video size is above 20MB",
          isSnackError: true,
          snackOpen: true,
        });
      }
    } else {
      this.setState({
        snackMessage: "File should be in .mp4 format",
        isSnackError: true,
        snackOpen: true,
      });
    }
  };
  render() {
    const { submitted, formData, open, isActive, edit, view } = this.state;
    return (
      <React.Fragment>
        {open && (
          <React.Fragment>
            <Loader open={isActive} />
            <div className="overlay">
              <div className="big-dia dialog">
                <Snackbar
                  message={this.state.snackMessage}
                  error={this.state.isSnackError}
                  open={this.state.snackOpen}
                  close={this.snackbarClose}
                />
                <div className="row">
                  <div className="flexDisplay">
                    <div className="diaTitleCon">
                      {edit ? "Edit Videos" : view ? "Videos" : "Add Videos"}
                    </div>
                    <div className="dia-close" onClick={this.props.handleClose}>
                      <div className="diaCloseCon">
                        <div className="diaClose">&#10005;</div>
                      </div>
                    </div>
                  </div>
                  <div className="tilebar">
                    <hr />
                  </div>
                  <form action="">
                    <div className="row book-form">
                      <div className="col-6">
                        <TextField
                          id="outlined-basic"
                          label="Title[Eng]"
                          variant="outlined"
                          onChange={(e) => this.handleChange(e)}
                          value={formData.video_title_en ?? ""}
                          name="video_title_en"
                          required
                        />
                      </div>
                      <div className="col-6">
                        <TextField
                          id="outlined-basic"
                          label="Title[Mal]"
                          variant="outlined"
                          onChange={(e) => this.handleChange(e)}
                          value={formData.video_title_ma ?? ""}
                          name="video_title_ma"
                          required
                        />
                      </div>

                      <div className="col-12">
                        <label className="control-label mylabel">Status</label>
                        <select
                          className="form-select input-group.mandatory"
                          aria-label="Default select example"
                          name="status"
                          required
                          value={formData.status ?? ""}
                          onChange={(e) => this.handleChange(e)}
                        >
                          <option selected></option>
                          <option value="1">Enable</option>
                          <option value="2">Disable</option>
                        </select>
                      </div>

                      <div className="col-12">
                        <TextField
                          id="outlined-basic"
                          label="Videos"
                          name="videos"
                          type="file"
                          accept=".mp4"
                          fileName={formData.file_name}
                          variant="outlined"
                          // value={formData.image ?? ""}
                          onChange={(e) => this.handleFileChange(e)}
                          required={formData.file_name ? false : true}
                        />
                      </div>

                      <div className="file-up-helper">
                        *Upload only MP4 and Size should be less than 20MB
                      </div>

                      {!view && (
                        <div className="col-12">
                          <CustomButton
                            label={edit ? "Update" : "Add"}
                            disabled={submitted}
                            onClick={(e) => this.handleSubmit(e)}
                          />
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default Dialog;
