import React, { useEffect, useState } from "react";
import CommonForm from "../../../../components/commonform/commonForm";
import { EmailValidate } from "../../../../components/formValidate";
import TextEditer from "../../../../components/inputs/texteditor";
import Snackbar from "../../../../components/snackbar/snackbar";
let reuired = [
  "company_name",
  "person_name",
  "address",
  "email",
  "abstract_project_proposal",
  "ex_man_power",
  "capital_investment",
  "upload_proposal",
];
let flag = false;
export default function BasicDetails(props) {
  console.log(props.formData);
  const [formData, setFormData] = useState(props.formData);
  const [keyvalue, setKeyvalue] = useState(0);
  const [count, setCount] = useState(1);
  const [error, setError] = useState({});
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  // useEffect(() => {
  //   setFormData(props.data);
  // }, [props.data]);
  console.log(formData);
  console.log(props);
  const textFieldChange = (e) => {
    if (e.target.name === "email" || e.target.name === "alternate_email") {
      let erroremail = !EmailValidate(e.target.value);

      setError({ ...error, [e.target.name]: erroremail });
    }
    let name = e.target.name;

    let value = e.target.value;
    let data = formData;
    data[name] = value;
    setFormData(data);
    setKeyvalue(keyvalue + 1);
  };
  const addNewProductProfile = (i) => {
    let data = { ...formData };

    data.product_profiles.push({ product_name: "", product_desc: "" });
    setFormData(data);
    setCount(count + 1);
  };
  const RemoveProduct = (i) => {
    let data = { ...formData };
    data.product_profiles.splice(i, 1);
    setCount(data);
    setCount(count + 1);
  };
  const handleMultiChange = (e, i) => {
    let data = { ...formData };

    data.product_profiles[i] = {
      ...data.product_profiles[i],
      [e.target.name]: e.target.value,
    };

    setFormData(data);
    setCount(count + 1);
  };
  const handleProductDesc = (val, i) => {
    console.log(val);
    console.log(i);
    let data = { ...formData };

    data.product_profiles[i] = {
      ...data.product_profiles[i],

      product_desc: val,
    };

    setFormData(data);
    setCount(count + 1);
  };
  const handleNextSubmit = (e) => {
    e.preventDefault();
    
    // Increment count
    setCount(count + 1);
    
    // Check if upload_proposal is provided
    if (
      formData.upload_proposal === null ||
      formData.upload_proposal === undefined ||
      formData.upload_proposal === ""
    ) {
      setSnackMessage("Please choose a valid Proposal file");
      setIsSnackError(true);
      setSnackOpen(true);
    } else {
      // Check if props.kerala is true
      if (props.kerala === true) {
        // Check if certification is not "Other"
        if (formData?.certification !== "Other") {
          props.action(0, formData);
        } else {
          // Check if otherCertification is provided if certification is "Other"
          if (formData?.otherCertification && formData?.otherCertification !== "") {
            props.action(0, formData);
          } else {
            // Show error message if otherCertification is not provided
            setSnackOpen(true);
            setSnackMessage("Please Mention the Certification");
            setIsSnackError(true);
          }
        }
      } else {
        // If props.kerala is false, directly call action
        props.action(0, formData);
      }
    }
  };
  
  
  const handleCheckChange = (e, item) => {
    flag = false;
    let data = [...props.domainData];

    data[item]["checked"] = e.target.checked;
    //setFormData({ ...formData, any_other_flag: false });
    if (props.domainData) {
      props.domainData.forEach((item) => {
        if (item.checked) flag = true;
      });
    }
    setCount(count + 1);
    props.setDomain(data);
  };
  const handleValid = () => {
    let flags = false;
    if (props.domainData) {
      props.domainData.forEach((item) => {
        if (item.checked) flags = true;
      });
    }
    return flags;
  };
  const handleOtherChange = (e) => {
    // let data = [...props.domainData];
    // data.forEach((item) => {
    //   item["checked"] = false;
    // });
    //
    // props.setDomain(data);
    setFormData({ ...formData, [e.target.name]: e.target.checked });
    // setCount(count + 1);
  };
  const handleFileChange = (e) => {
    let size = e.target.files[0].size;
    let type = e.target.files[0].name.split(".").pop();

    if (
      type?.toLowerCase() === "pdf" ||
      type?.toLowerCase() === "doc" ||
      type?.toLowerCase() === "docx"
    ) {
      let mb = size / Math.pow(1024, 2);

      if (mb < 5) {
        setFormData({
          ...formData,
          file_name: e.target.files[0].name,
          [e.target.name]: e.target.files[0],
        });
        if (props.update) {
          props.handlefile();
        }
      } else {
        setSnackMessage("File size is greater than 5MB");
        setIsSnackError(true);
        setSnackOpen(true);
        setFormData({
          ...formData,
          file_name: null,
          [e.target.name]: null,
        });
      }
    } else {
      setSnackMessage("File should be in .doc / .docx / .pdf format");
      setIsSnackError(true);
      setSnackOpen(true);
      setFormData({
        ...formData,
        file_name: null,
        [e.target.name]: null,
      });
    }
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    // if (!flag) {
    //   handleEventList();
    // }
  };
  useEffect(() => {
    setFormData({
      ...props.formData,
      // any_other_domain:
      //   props.formData.any_other_flag === 1 ||
      //   props.formData.any_other_flag === "1"
      //     ? props.formData.any_other_domain
      //       ? props.formData.any_other_domain
      //       : ""
      //     : "",
    });
    if (!formData.product_profiles) {
      setFormData({
        ...formData,
        product_profiles: [{ product_name: "", product_desc: "" }],
      });
    }
    setCount(count + 1);
  }, [props.formData]);
  const handlePhoneNumberChange = (e) => {
    //
    let name = e.target.name;
    let value = e.target.value;
    //
    //
    if (value.length <= 10) {
      let newValue = value.replace(/[^0-9]/g, "");
      let data = formData;
      data = {
        ...data,

        [name]: newValue,
      };
      setFormData(data);
    }
  };
  const handleFieldNumberChange = (e) => {
    let data = { ...formData };
    let name = e.target.name;
    let value = e.target.value;
    let newValue = value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters except for the decimal point
    newValue = newValue.match(/^\d{0,3}(?:\.\d{0,2})?$/); // Apply the regex pattern to ensure the format
    if (newValue) {
        data = { ...data, [name]: newValue[0] }; // If the newValue matches the pattern, update the data object
    }
    setFormData(data);
};

  
  const handleEmploymentNumberChange = (e) => {
    let data = { ...formData };
    let name = e.target.name;
    let value = e.target.value;
    let newValue = value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters except for the decimal point
    newValue = newValue.match(/^\d{0,4}(?:\.\d{0,2})?$/); // Apply the regex pattern to ensure the format
    if (newValue) {
        data = { ...data, [name]: newValue[0] }; // If the newValue matches the pattern, update the data object
    }
    setFormData(data);
  };
  const handleNumberChange = (e) => {
    let data = { ...formData };
    let name = e.target.name;
    let value = e.target.value;
    let newValue = value.replace(/[^0-9]/g, "");
    data = { ...data, [name]: newValue };
    setFormData(data);
  };
  const handleOtherCertificationChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  console.log(formData);
  console.log(props);
  return (
    <div className="add-per">
      <div className="view-container">
        <form onSubmit={handleNextSubmit}>
          <h6>
            <b>{props.title}</b>
          </h6>
          <hr />
          <div className="row">
            <div className="col-8 mb-2">
              <CommonForm
                fieldType="text"
                label="Company Name"
                required
                name="company_name"
                disabled={props.edit}
                value={formData.company_name ?? ""}
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>
            {props?.kerala &&
            <div className="col-8 mb-2">
              <CommonForm
                fieldType="text"
                label="Registration Number / CIN Number"
                required
                name="cin_number"
                disabled={props.edit}
                value={formData.cin_number ?? ""}
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>}

            <div className="col-8 mb-2">
              <CommonForm
                fieldType="text"
                label="Contact Person Name"
                required
                name="person_name"
                value={formData.person_name ?? ""}
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>

            <div className="col-8 mb-2">
              <CommonForm
                fieldType="text"
                label="Contact Person Designation"
                required
                name="person_designation"
                value={formData.person_designation ?? ""}
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>

            <div className="col-8 mb-2">
              <CommonForm
                fieldType="multiline"
                label="Address"
                required
                name="address"
                value={formData.address ?? ""}
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>

            <div className="col-8 mb-2">
              <CommonForm
                fieldType="email"
                label="Email"
                error={error.email}
                required
                disabled={props.edit}
                name="email"
                value={formData.email ?? ""}
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>
            <div className="col-8 mb-2">
              <CommonForm
                fieldType="text"
                label="Website Link"
                name="site_link"
                value={formData.site_link ?? ""}
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>
            <div className="col-8 mb-2">
              <CommonForm
                fieldType="text"
                label="Phone Number"
                maxLength="10"
                minLength="10"
                pattern="[0-9]{10}"
                value={formData?.mobile_number}
                required
                onChange={(e) => {
                  handlePhoneNumberChange(e);
                }}
                name="mobile_number"
                {...props}
              />
            </div>
            <div className="col-8 mb-2">
              <label className="control-label mylabel mb-2">
                Alternate Phone
              </label>

              <div className="d-flex align-items-end w-100">
                <div className="">
                  <CommonForm
                    fieldType="text"
                    error={error.country_code}
                    name="country_code"
                    maxLength="4"
                    value={formData.country_code ?? ""}
                    onChange={(e) => {
                      handleNumberChange(e);
                    }}
                    {...props}
                  />
                </div>
                <div className="p-1"></div>
                <div className="col-10">
                  <CommonForm
                    fieldType="text"
                    error={error.alternate_phone}
                    name="alternate_phone"
                    
                    maxLength="10"
                    value={formData.alternate_phone ?? ""}
                    onChange={(e) => {
                      handleNumberChange(e);
                    }}
                    {...props}
                  />
                </div>
              </div>
            </div>

            <div className="col-8 mb-2">
              <CommonForm
                fieldType="email"
                label="Alternative Email"
                error={error.alternate_email}
                name="alternate_email"
                value={
                  formData.alternate_email &&
                  formData.alternate_email !== "null"
                    ? formData.alternate_email
                    : ""
                }
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>

            <div className="col-8 mb-2 mt-2">
              <CommonForm
                fieldType="multiline"
                label="Abstract Company Profile"
                required
                name="abstract_project_proposal"
                value={formData.abstract_project_proposal ?? ""}
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>
          </div>

          <div className="col-8 mb-2">
            <div>
              {formData.product_profiles?.map((e, i) => (
                <div className="section-datas-cont" key={i}>
                  <div className="header-label">Product Profile</div>

                  <div className="section-Cont">
                    <CommonForm
                      fieldType="text"
                      label="Product Name"
                      required
                      name="product_name"
                      value={e?.product_name}
                      onChange={(e) => {
                        handleMultiChange(e, i);
                      }}
                      {...props}
                    />
                    <TextEditer
                      // fieldType="multiline"
                      label="Product Description (Required)"
                      required
                      name="product_desc"
                      value={e?.product_desc}
                      keyValue={i}
                      // key={`${i}key${props.keyValue}`}
                      onChange={handleProductDesc}
                      {...props}
                    />
                  </div>

                  <div className="more-btn-cnt">
                    {formData.product_profiles.length === i + 1 && (
                      <button
                        style={{ marginLeft: 5 }}
                        type="button"
                        {...props}
                        className="btn btn-primary"
                        onClick={(e) => addNewProductProfile(i)}
                      >
                        Add More
                      </button>
                    )}
                    {formData.product_profiles.length > 1 && (
                      <button
                        {...props}
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => RemoveProduct(i)}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="col-8 mb-2">
            <div>
              <div className="header-label">Domain Type</div>
              <div className="check-box-cont">
                {props.domainData &&
                  props.domainData.map((item, index) => (
                    <CommonForm
                      fieldType="checkbox"
                      value={item.checked}
                      required={!formData.any_other_flag && !handleValid()}
                      checked={item.checked}
                      onChange={(e) => handleCheckChange(e, index)}
                      label={item.domain_name}
                      {...props}
                    />
                  ))}

                <CommonForm
                  fieldType="checkbox"
                  onChange={handleOtherChange}
                  label="Any Other"
                  name="any_other_flag"
                  checked={formData.any_other_flag}
                  value={formData.any_other_flag}
                  {...props}
                />
              </div>

              {/* if any other field checked ,below is mandatory */}
              {formData.any_other_flag && formData.any_other_flag !== "0" && (
                <div>
                  <CommonForm
                    fieldType="text"
                    placeholder="Please specify the Domain.."
                    required
                    name="any_other_domain"
                    value={formData.any_other_domain ?? ""}
                    onChange={(e) => {
                      textFieldChange(e);
                    }}
                    {...props}
                  />
                </div>
              )}
              {/* ////////////////////////////// */}
            </div>
            {/* ///////////////////////////////// */}
            {/* file upload */}
          </div>

          <div className="col-8 mb-2">
            <div>
              {/* <div className="file-up-label-cont">Upload Proposal</div> */}

              <CommonForm
                fieldType="file"
                required={!formData.file_name ? true : false}
                name="upload_proposal"
                {...props}
                label="Upload Company Brochure / Leaflets"
                id="upload_proposal"
                fileName={formData.file_name}
                accept=".pdf,.docx,.doc"
                type="file"
                variant="outlined"
                onChange={handleFileChange}
              />

              <div className="file-up-helper">
                *Upload only .pdf , .doc, .docx and Size should be less than 5MB
              </div>
            </div>
          </div>

          <div className="col-8 mb-2">
            {/* ////////////////////////// */}
            {/* //////// Others///////////// */}

            <CommonForm
              fieldType="text"
              label="Capital Investment so far in Crores"
              required = {props?.india || props?.international ? false : true}
              // maxLength={3}
              name="capital_investment"
              value={formData.capital_investment ?? ""}
              onChange={(e) => {
                handleFieldNumberChange(e);
                // handleNumberChange(e)
                // handleEmploymentNumberChange(e)
              }}
              {...props}
            />
          </div>
          {props.kerala === true && (
            <>
            <div className="col-8 mb-2">
              {/* ////////////////////////// */}
              {/* //////// Others///////////// */}

              <CommonForm
                fieldType="text"
                label="Previous FY turnover Rs. in Crores"
                required 
                // maxLength={3}
                name="previous_fy_turnover"
                value={formData.previous_fy_turnover ?? ""}
                onChange={(e) => {
                  handleFieldNumberChange(e);
                }}
                {...props}
              />
            </div>
            <div className="col-8 mb-2">
          <CommonForm
            fieldType="text"
            label="Foreign Exchange (FE) Component"
            required
            // maxLength={4}
            name="fe_component"
            value={formData.fe_component ?? ""}
            onChange={(e) => {
              handleEmploymentNumberChange(e);
            }}
            {...props}
          />
          </div>
          </>
          )}

          <div className="col-8 mb-2">
            <CommonForm
              fieldType="text"
              label="  Direct Employment as on Date"
             
              required = {props?.india || props?.international ? false : true}
              // maxLength={4}
              name="ex_man_power"
              value={formData.ex_man_power ?? ""}
              onChange={(e) => {
                handleEmploymentNumberChange(e);
              }}
              {...props}
            />
          </div>
          {props.kerala === true && (
            <>
          <div className="col-8 mb-2">
          <CommonForm
            fieldType="text"
            label="Achievements of Companies"
            required
            // maxLength={4}
            name="achievements"
            value={formData.achievements ?? ""}
            onChange={(e) => {
              textFieldChange(e);
            }}
            {...props}
          />
          </div>
          
          <div className="col-8 mb-2">
          <div className="my-4 mylabel ">Direct Employment Split up</div>
          </div>
          <div className="col-8 mb-2">
          <CommonForm
            fieldType="text"
            label="Male"
            required
            // maxLength={4}
            name="dir_employ_male"
            value={formData.dir_employ_male ?? ""}
            onChange={(e) => {
              handleEmploymentNumberChange(e);
            }}
            {...props}
          />
          </div>
          <div className="col-8 mb-2">
          <CommonForm
            fieldType="text"
            label="Female"
            required
            // maxLength={4}
            name="dir_employ_female"
            value={formData.dir_employ_female ?? ""}
            onChange={(e) => {
              handleEmploymentNumberChange(e);
            }}
            {...props}
          />
          </div>
          <div className="col-8 mb-2">
          <CommonForm
            fieldType="text"
            label="Genderqueer"
            required
            // maxLength={4}
            name="dir_employ_others"
            value={formData.dir_employ_others ?? ""}
            onChange={(e) => {
              handleEmploymentNumberChange(e);
            }}
            {...props}
          />
          </div>
          <div className="col-8 mb-2">
          <CommonForm
            fieldType="text"
            label="SC/ST"
            required
            // maxLength={4}
            name="dir_employ_sc_st"
            value={formData.dir_employ_sc_st ?? ""}
            onChange={(e) => {
              handleEmploymentNumberChange(e);
            }}
            {...props}
          />
          </div>
      
          <div className="col-8 mb-2">
          <div className="mb-2 mylabel">Certifications</div>
          <select
            className="form-select selectbox "
            required
            // style={{ lineHeight: "25px!important" }}
            name="certification" // Ensure consistency with the state name
            value={formData.certification ?? ""}
            aria-label="Default select example"
            onChange={(e) => handleOtherCertificationChange(e)} // Use the correct handler
          >
            <option value="">Select</option>
            <option value="KS9100">KS9100</option>
            <option value="ISO9000/2050">ISO9000/2050</option>
            <option value="Z_Certfication">Z Certification</option>
            <option value="Other">Other</option>
          </select>

          {formData.certification === "Other" && (
            <div className="col-12 my-2">
              <label htmlFor="otherCertification">Other Certification</label>
              <input
                type="text"
                className="form-control my-2"
                style={{ borderLeft: "2px solid #ee7e7e" }}
                id="otherCertification"
                name="otherCertification" // Ensure consistency with the state name
                value={formData.otherCertification ?? ""}
                onChange={handleOtherCertificationChange} // Use the correct handler
              />
            </div>
          )}
         
          </div>
          </>) }
          

          {/* ///////////////////////////////////// */}
          {/* ///actions ////////////// */}
          <div className="mt-4">
            <div className="col-8 mb-2">
              <center>
                <button type="submit" className="primary-btn basic-submit">
                  SUBMIT
                </button>
              </center>
            </div>
          </div>
        </form>

        <Snackbar
          message={snackMessage}
          error={isSnackError}
          open={snackOpen}
          close={snackbarClose}
        />
      </div>
    </div>
  );
}
