import React, { useEffect, useState } from "react";
import "./view.css";

export default function Add() {
  const [eduQual, setEduQual] = useState([]);
  const [count, setCount] = useState(0);
  useEffect(() => {
    setEduQual([
      {
        exam: "",
        board: "",
        year: "",
        mark: "",
        upload: "",
      },
      setCount(0),
    ]);
  }, [eduQual, count]);

  const handleAddMore = (e, i) => {
    let data = {
      exam: "",
      board: "",
      year: "",
      mark: "",
      upload: "",
    };
    let eduData = eduQual;
    eduData.push(data);
    setCount(count + 1);
  };
  return (
    <div className="add-per">
      <div className="view-container">
        <h6>
          <b>Add Academic Institution</b>
        </h6>
        <hr />

        <div className="row">
          <div className="col-8 mb-2">
            <label for="exampleInputEmail1">Name of the Institution</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="col-8 mb-2">
            <label for="exampleInputEmail1">Registered office Address</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="col-5 mb-2">
            <label for="exampleInputEmail1">Date of Registration</label>
            <input
              type="date"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="col-3 mb-2">
            <label for="exampleInputEmail1">Total Number of Students</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="col-8">
            <label for="formFileSm" className="form-label mb-0">
              Upload Logo
            </label>
            <input
              className="form-control form-control-sm"
              id="formFileSm"
              name="upload"
              type="file"
            />
            <small className="pers">
              *Upload only .pdf, .doc, format and Size should be less than 1MB
            </small>
          </div>
          <div className="col-8 mb-2 mt-2">
            <label for="exampleInputEmail1">Headquarters Details</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="col-8 mb-2">
            <label for="exampleInputEmail1">
              Name and Designation of Authorized Person with contact details
            </label>
            <textarea
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="col-8">
            <label for="exampleInputEmail1" className="mb-2 mt-2">
              <b>Courses Offered</b>
            </label>
            <>
              <div className="row edu mb-1">
                <div className="col-12 pb-3">
                  <label for="exampleInputEmail1">Course Name</label>
                  <input
                    type="text"
                    className="form-control "
                    id="exampleInputEmail1"
                    name="exam"
                    aria-describedby="emailHelp"
                  />
                </div>
              </div>
            </>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-primary btn-sm d-flex justify-content-end"
              >
                Add More
              </button>
            </div>
          </div>

          <div className="col-8 mb-2">
            <label for="exampleInputEmail1">
              Brief note on Institution Activities
            </label>
            <textarea
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="col-8">
            <label for="formFileSm" className="form-label mb-0">
              Subnit Request Letter in institution letter Head of the
              Institution/Signing Authority.
            </label>
            <input
              className="form-control form-control-sm"
              id="formFileSm"
              name="upload"
              type="file"
            />
            <small className="pers">
              *Upload only .pdf, .doc, format and Size should be less than 1MB
            </small>
          </div>
          <div className="col-8">
            <center>
              <button type="button" className="btn btn-dark mt-3">
                Submit for Acceptance
              </button>
            </center>
          </div>
          {/* ---------------- */}
        </div>
      </div>
    </div>
  );
}
