import React from "react";
import CustomIcons from "../../icons/kIcons.component";
import "./button.css";

export default function SearchButton(props) {
  return (
    <div onClick={props.onClick ?? null} style={{ cursor: "pointer" }}>
      <span
        className="input-group-text border-0 search-style"
        id="search-addon"
      >
        <CustomIcons iconName="search" iconClass="search" />
      </span>
    </div>
  );
}
