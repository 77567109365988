import React, { useEffect, useState } from "react";
import ConfirmDialog from "../../../components/actionDialogs/confirmDialog";
import Loader from "../../../components/loader/loader";
import Snackbar from "../../../components/snackbar/snackbar";
import CustomTable from "../../../components/table/table";
import { admin_domains_delete, admin_domains_list } from "../admin.api";
import ManageDomain from "./manage/manage";
import "./view.css";
import jwtDecode from "jwt-decode";

export default function View(props) {
  const [isActive, setIsActive] = useState(true);
  let adminType = Number(jwtDecode(props.state.payload).user_type);
  const [data, setdata] = useState([]);
  const [addNew, setAddNew] = useState(false);
  const [view, setView] = useState(false);
  const [formData, setFormData] = useState({});
  const [activeData, setActiveData] = useState({});
  const [openAction, setOpenAction] = useState(false);
  const [edit, setEdit] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);

  const handleEventList = () => {
    setIsActive(true);
    admin_domains_list().then((res) => {
      setdata(res.data);
      setIsActive(false);
    });
  };
  const handleAddNew = () => {
    setAddNew(true);
    setView(false);
  };
  const handleView = (data) => {
    setFormData(data);
    setAddNew(true);
    setView(true);
  };
  const handleDelete = (data) => {
    setActiveData(data);
    setOpenAction(true);
  };
  const handleClose = () => {
    setAddNew(false);
    setEdit(false);
    setFormData({});
    handleEventList();
  };
  const handleCancelDelete = () => {
    setOpenAction(false);
  };
  const handleDeleteConfirm = (data) => {
    setOpenAction(false);
    admin_domains_delete(data.uuid)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
      });
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      handleEventList();
    }
  };
  const handleEdit = (data) => {
    setFormData(data);
    setView(false);
    setEdit(true);
    setAddNew(true);
  };
  useEffect(() => {
    handleEventList();
  }, []);

  return (
    <div>
      <Loader open={isActive} />
      <div className="view-container">
        <h6>
          <b>Domains</b>
        </h6>
        <hr />
        <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={(e) => handleAddNew(e)}
        >
          Add New Domain
        </button>
        {/* <button type="button" className="btn   btn-primary btn-sm domain-btn">
          New Domain Suggestion
        </button> */}
        <div className="col-7">
          <CustomTable
            {...props}
            columns={[{ title: "Domain Name", field: "domain_name" }]}
            actions={
              adminType === 2
                ? [
                    { type: "view", handleClick: handleView },
                    { type: "edit", handleClick: handleEdit },
                    { type: "delete", handleClick: handleDelete },
                  ]
                : [
                    { type: "view", handleClick: handleView },
                    { type: "edit", handleClick: handleEdit },
                  ]
            }
            data={data}
          />
        </div>
        {/* <CustomTable
          {...props}
          columns={[
            { title: "New Domain Suggestion", field: "domain" },
            { title: "Action", field: "action", type: "button" },
          ]}
          data={[
            {
              domain: "Electronic / Electrod Manufacturing",
              action: "delt btn",
            },
            {
              domain: "Electronic / Electrod Manufacturing",
              action: "delt btn",
            },
            {
              domain: "Electronic / Electrod Manufacturing",
              action: "delt btn",
            },
          ]}
        /> */}
      </div>
      {addNew && (
        <ManageDomain
          open={addNew}
          edit={edit}
          view={view}
          handleClose={handleClose}
          formData={formData}
        />
      )}
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      {openAction && (
        <ConfirmDialog
          open={openAction}
          title="Delete Domain ?"
          data={activeData}
          message="Are you sure to delete this Domain?"
          secondary_action="Cancel"
          primary_action="Delete"
          handleClose={handleCancelDelete}
          handleConfirm={handleDeleteConfirm}
        />
      )}
    </div>
  );
}
