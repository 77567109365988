// @flow
import * as React from "react";
import {
  AiFillBank,
  AiFillBell,
  AiFillWallet,
  AiFillEye,
  AiOutlineCalendar,
  AiOutlineForm,
  AiOutlineMail,
  AiOutlineUser,
  AiOutlineFile,
  AiTwotoneHome,
  AiOutlineCaretDown,
} from "react-icons/ai";
import {
  BsBack,
  BsBookFill,
  BsBriefcase,
  BsBriefcaseFill,
  BsChevronDown,
  BsFileEarmarkPdf,
  BsFileCheck,
  BsFillArrowRightCircleFill,
  BsFileEarmarkPersonFill,
  BsFileTextFill,
  BsTelephone,
  BsFillCalendar2EventFill,
  BsFillCalendar2WeekFill,
  BsFillChatRightQuoteFill,
  BsFillDiagram3Fill,
  BsFillFileEarmarkTextFill,
  BsBuilding,
  BsFillFlagFill,
  BsFillGrid1X2Fill,
  BsFillLayersFill,
  BsFillPersonPlusFill,
  BsFillXDiamondFill,
  BsGridFill,
  BsHouseFill,
  BsImages,
  BsLightbulbFill,
  BsLightningChargeFill,
  BsListTask,
  BsSearch,
  BsShieldFill,
  BsStickiesFill,
  BsDownload,
  BsTelephoneFill,
  BsFillPeopleFill,
  BsTools,
} from "react-icons/bs";
import { BiRupee, BiLeftArrow, BiRightArrow, BiDownload } from "react-icons/bi";
import { CgPhone } from "react-icons/cg";
import {
  FaBuilding,
  FaWallet,
  FaMoneyBillAlt,
  FaFileDownload,
  FaHandPointRight,
  FaGlobe,
  FaRegWindowClose,
} from "react-icons/fa";
import { GrMail, GrDocumentPdf } from "react-icons/gr";
import { FiDownload, FiArrowDown } from "react-icons/fi";
import { HiLocationMarker } from "react-icons/hi";
import { RiSearchFill } from "react-icons/ri";
import {
  MdEdit,
  MdClear,
  MdDeleteForever,
  MdOutlineArrowForwardIos,
  MdSchool,
} from "react-icons/md";

export default function CustomIcons(props) {
  let iconName = props.iconName;
  let iconClass = props.iconClass ?? "";
  return (
    <span>
      {iconName === "filedownload" && <FaFileDownload className={iconClass} />}
      {iconName === "searchIcon" && <RiSearchFill className={iconClass} />}

      {iconName === "arrowRight" && (
        <MdOutlineArrowForwardIos className={iconClass} />
      )}
      {iconName === "clearInput" && <MdClear className={iconClass} />}
      {iconName === "dashattachment" && <AiOutlineFile className={iconClass} />}
      {iconName === "down" && <AiOutlineCaretDown className={iconClass} />}
      {iconName === "school" && <MdSchool className={iconClass} />}
      {iconName === "rupee" && <BiRupee className={iconClass} />}
      {iconName === "location" && <HiLocationMarker className={iconClass} />}
      {iconName === "calendar" && <AiOutlineCalendar className={iconClass} />}
      {iconName === "phone" && <CgPhone className={iconClass} />}
      {iconName === "building1" && <BsBuilding className={iconClass} />}
      {iconName === "phone1" && <BsTelephoneFill className={iconClass} />}
      {iconName === "phone2" && <BsTelephone className={iconClass} />}
      {iconName === "pdf" && <BsFileEarmarkPdf className={iconClass} />}
      {iconName === "mail" && <GrMail className={iconClass} />}
      {iconName === "hand" && <FaHandPointRight className={iconClass} />}
      {iconName === "message" && <AiOutlineMail className={iconClass} />}
      {iconName === "news" && <AiOutlineForm className={iconClass} />}
      {iconName === "user" && <AiOutlineUser className={iconClass} />}
      {iconName === "bell" && <AiFillBell className={iconClass} />}
      {iconName === "view" && <AiFillEye className={iconClass} />}
      {iconName === "bank" && <AiFillBank className={iconClass} />}
      {iconName === "building" && <FaBuilding className={iconClass} />}
      {iconName === "people" && <BsFillPeopleFill className={iconClass} />}
      {iconName === "messageClose" && (
        <FaRegWindowClose className={iconClass} />
      )}
      {iconName === "edit" && <MdEdit className={iconClass} />}
      {iconName === "delete" && <MdDeleteForever className={iconClass} />}
      {iconName === "search" && <BsSearch className={iconClass} />}
      {iconName === "newcompany" && (
        <BsFillDiagram3Fill className={iconClass} />
      )}
      {iconName === "areamanage" && <BsFillGrid1X2Fill className={iconClass} />}
      {iconName === "home" && <AiTwotoneHome className={iconClass} />}
      {iconName === "house" && <BsHouseFill className={iconClass} />}

      {iconName === "slide" && <BsImages className={iconClass} />}
      {iconName === "download" && <FiDownload className={iconClass} />}
      {iconName === "activity" && <BsListTask className={iconClass} />}
      {iconName === "about" && <BsFillPeopleFill className={iconClass} />}
      {iconName === "maintext" && <BsFileTextFill className={iconClass} />}
      {iconName === "structure" && <BsGridFill className={iconClass} />}
      {iconName === "quote" && (
        <BsFillChatRightQuoteFill className={iconClass} />
      )}
      {iconName === "flag" && <BsFillFlagFill className={iconClass} />}
      {iconName === "domain" && <BsShieldFill className={iconClass} />}
      {iconName === "company" && <BsBriefcaseFill className={iconClass} />}
      {iconName === "application" && (
        <BsFillFileEarmarkTextFill className={iconClass} />
      )}
      {iconName === "companylist" && <BsBriefcase className={iconClass} />}
      {iconName === "intitutions" && <BsBookFill className={iconClass} />}
      {iconName === "resource" && (
        <BsFillPersonPlusFill className={iconClass} />
      )}
      {iconName === "expert" && <BsTools className={iconClass} />}
      {iconName === "market" && <BsLightbulbFill className={iconClass} />}
      {iconName === "proposals" && <BsFillXDiamondFill className={iconClass} />}
      {iconName === "technology" && <BsBack className={iconClass} />}
      {iconName === "ideas" && <BsLightningChargeFill className={iconClass} />}
      {iconName === "academic" && <BsFillLayersFill className={iconClass} />}
      {iconName === "events" && (
        <BsFillCalendar2EventFill className={iconClass} />
      )}
      {iconName === "eventlist" && (
        <BsFillCalendar2WeekFill className={iconClass} />
      )}
      {iconName === "directory" && <BsStickiesFill className={iconClass} />}
      {iconName === "advisors" && (
        <BsFileEarmarkPersonFill className={iconClass} />
      )}
      {iconName === "downarrow" && <BsChevronDown className={iconClass} />}
      {iconName === "book" && <BsFileCheck className={iconClass} />}
      {iconName === "sliderLeft" && <BiLeftArrow className={iconClass} />}
      {iconName === "sliderRight" && <BiRightArrow className={iconClass} />}
      {iconName === "wallet" && <FaWallet className={iconClass} />}
      {iconName === "globe" && <FaGlobe className={iconClass} />}
      {iconName === "money" && <FaMoneyBillAlt className={iconClass} />}
    </span>
  );
}
