import React, { useEffect, useState } from "react";
import "./style.css";
import { userPublications } from "../user.api";
import Loader from "../../../components/loader/loader";
import { NavItem } from "react-bootstrap";
import Swal from "sweetalert2";

export default function Publication() {
  const [data, setData] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  const getData = () => {
    setIsActive(true);
    userPublications().then((res) => {
      let tdata = res.data;
      // tdata.forEach((item) => {
      //   item["project_proposal_title"] = item.project_title;
      //   item["short_details_proposal"] = item.short_proposal_details;
      // });
      setData(tdata);
      setIsActive(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const openDetails = (image, Title, Description, Link) => {
    Swal.fire({
      imageUrl: result + image,
      imageHeight: 200,
      imageWidth: 200,
      title: Title,
      text: Description,
      showCloseButton: true,
      showConfirmButton: true,
      customClass: {
        title: "swal-publication",
        htmlContainer: "swal-publication-text",
        image: "swal-publication-image",
      },
      confirmButtonColor: "#2f2483",
      confirmButtonText: "Visit",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(Link)
        window.open(`${Link}`, "_blank")
      }
    });
  };
  return (
    <div className="pubbox">
      <Loader open={isActive} />
      <div>
        <div className="dash-banner ">
          <div className="tit-txt">Publications</div>
        </div>
      </div>
      <div className="container">
        <div className="col-12">
          <div className="row">
            {/* ------box----------- */}
            {data.map((item) => (
              <div className="col-md-4 publi">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-3 imgx">
                        <img src={`${result}${item.upload_image}`} alt="" />
                      </div>

                      <div className="col-9 abt ">
                        <h5
                          className="one-line"
                          role="button"
                          onClick={(e) => {
                            window.open(`${item.link}`, "_blank");
                          }}
                        >
                          {item.publication_title ?? ""}
                        </h5>
                        <small>Publisher</small>
                        <h6 className="mt-1 mb-2">
                          {" "}
                          {item.publisher ?? "Kerala Spacepark"}
                        </h6>
                        <p className="one-line ">{item.description ?? ""}</p>

                        <div className="mt-2 d-flex justify-content-end">
                          <a
                            href="#"
                            onClick={(e) => {
                              openDetails(
                                item.upload_image,
                                item.publication_title,
                                item.description,
                                item.link
                              );
                            }}
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {/* ------box end------------ */}
          </div>
        </div>
      </div>
    </div>
  );
}
