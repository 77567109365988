import { useState } from "react";

import { EmailValidate, FormValidate } from "../../../components/formValidate";
import TextField from "../../../components/inputs/textfield";
import Snackbar from "../../../components/snackbar/snackbar";
import "./admin.css";
import { authLogin } from "./auth.api";
let required = ["email", "password"];
export default function Adminlogin(props) {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [error, setError] = useState({});
  const [submit, setSubmit] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  // const authenticate = () => {
  //   localStorage.setItem("userType", "admin");
  //   props.adminLogin();
  // };
  const handleChange = (e) => {
    let erroremail = false;

    if (e.target.name === "email") {
      erroremail = !EmailValidate(e.target.value);

      setError({ ...error, email: erroremail });
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    setSubmit(true);
    const error = FormValidate(formData, required);
    setError(error);
    e.preventDefault();

    if (Object.keys(error).length === 0) {
      authLogin(formData)
        .then((res) => {
          setSubmit(false);
          sessionStorage.setItem("token", res.token)
          // if (localStorage.getItem("kspace_admin_token")) {
          props.adminLogin(res.token);
          props.history.push({
            pathname: "/admin-dashboard",
            // search: res.token,
            // state: { token: res.token },
          });
          // }
        })
        .catch((error) => {
          setSubmit(false);

          setSnackMessage(error.response.data.message);
          setIsSnackError(true);
          setSnackOpen(true);
        });
    } else {
      setSubmit(false);
      // setSnackData({
      //   open: true,
      //   message: "Please fill all mandatory fields",
      //   variant: "error",
      // });
    }
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
  };
  return (
    <div className="admin-login-page">
      <div className="">
        <img
          src="/resources/logo/logo.png"
          alt=""
          className="admin-login-logo"
        />
      </div>
      <div className="admin-login-container ">
        <div className="admin-login-title">ADMIN</div>
        <form
          className=""
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
        >
          <div className="login-input">
            <TextField
              {...props}
              type="text"
              required
              onChange={handleChange}
              label="Username"
              error={error.email}
              name="email"
              placeholder="Enter your username"
            />
          </div>
          <div className="login-input">
            <TextField
              {...props}
              label="Password"
              type="password"
              required
              name="password"
              error={error.password}
              onChange={handleChange}
              placeholder="Enter your password"
            // endicon="./assets/images/icons/show-password.svg"
            // eniconTitle="Show Password"
            />
          </div>
          <div className="butnCnt">
            <button
              disabled={submit}
              {...props}
              className="btn btn-primary"
              type="submit"
            // onClick={(e) => handleSubmit(e)}
            // onClick={(e) => handleSubmit(e)}
            >
              Login
            </button>
          </div>
        </form>
      </div>
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
    </div>
  );
}
