import React from "react";
import CustomIcons from "../icons/kIcons.component";
import "./style.css";

export default function SearchButton(props) {
  return (
    <div className="searchTextContainer">
      <input
        type={props.type ?? "text"}
        placeholder={props.placeholder ?? "Search..."}
        className="searchTextInput"
        onChange={(e) => {
          props.onChange(e);
        }}
        id="exampleInputEmail1"
        value={props.value ?? ""}
        aria-describedby="emailHelp"
      />
      {props.value !== null && props.value !== "" && props.value !== undefined && (
        <span onClick={(e) => props.clearSearch()}>
          <CustomIcons iconName="clearInput" iconClass={"clearInput"} />
        </span>
      )}
      <span onClick={(e) => props.onSearch()}>
        <CustomIcons iconName="searchIcon" iconClass={"defaultSearch"} />
      </span>
    </div>
  );
}
