import React, { useState } from "react";
export default function GalleryTile(props) {
  // console.log(props)
  let lang = props.state.language;

  const handlePreview = (i) => {
    props.handlePreview(`${props.count}`, true);

    // setActiveSelection(index);
  };
  return (
    <div
      className={`gallery__item gallery__item--${(props.count % 6) + 1}`}
      onClick={() => handlePreview()}
    >
      <React.Fragment>
        <img src={props.data[lang]?.images} className={"gallery__img curser-zoomin"} alt="" />
      </React.Fragment>
    </div>
  );
}
