import "./events.css";
import "../home/home.css";
import EventTiles from "../../../components/page/event/event-tile/event.tile.component";
import { public_event_list } from "../public.api";

import React, { useEffect, useState } from "react";
import Loader from "../../../components/loader/loader";
import BreadCrumbsComp from "../../../components/navigator/breadcrumbs";

export default function Events(props) {
  const [data, setData] = useState([]);
  const [url, setUrl] = useState("");
  const [isActive, setIsActive] = useState(true);
  let fromHome = sessionStorage.getItem("continueFrom");
  const fetchEvents = () => {
    public_event_list().then((res) => {
      setData(res.data);
      setUrl(res.current_url);
      setIsActive(false);
    });
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <>
      {fromHome === "events" && <BreadCrumbsComp section="home" />}
      <div>
        <Loader open={isActive} />
        <div>
          {/* ======================= Events  =================================================================== */}
          <div className="">
            <div
              className={
                fromHome === "events"
                  ? "header-banner-from-home "
                  : "header-banner"
              }
            >
              <div className="tit-txt">
                {props.state.language === "ma"
                  ? "വരാനിരിക്കുന്ന പരിപാടികൾ"
                  : "Upcoming Events "}
              </div>
            </div>
          </div>
          <div className="">
            <div className="">
              <div className="event-tile-container">
                {data &&
                  data.map((item) => (
                    <EventTiles
                      key={item.id}
                      {...props}
                      data={item}
                      url={url}
                      isBookable={true}
                    />
                  ))}
              </div>
            </div>
          </div>
          {/* =================================================================================================== */}
        </div>
      </div>
    </>
  );
}
