import React, { Component } from "react";
import "./dialog.css";
import TextField from "../../../../../components/inputs/textfield";

import CustomButton from "../../../../../components/inputs/button";
import { FormValidate } from "../../../../../components/formValidate";
import Snackbar from "../../../../../components/snackbar/snackbar";
import { admin_quotes_add } from "../../../admin.api";
let required = [
  "description_en",
  "description_ma",
  "designation_en",
  "designation_ma",
  "person_name_en",
  "person_name_ma",
  "small_image",
];
class Dialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      formData: props.formData,
      edit: props.edit,
      submitted: false,
      snackMessage: "",
      fileChanged: false,
      isSnackError: false,
      snackOpen: false,
    };
  }

  componentWillReceiveProps = (props) => {
    this.setState({
      open: props.open,
      edit: props.edit,
      formData: props.formData,
    });
  };
  handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({ formData: { ...this.state.formData, [name]: value } });
  };
  snackbarClose = (flag) => {
    this.setState({
      snackMessage: "",
      isSnackError: false,
      snackOpen: false,
    });
    if (!flag) {
      this.props.handleClose();
    }
  };
  handleSubmit = (e) => {
    const error = FormValidate(this.state.formData, required);
    const { edit, formData } = this.state;
    e.preventDefault();

    if (Object.keys(error).length === 0) {
      const form = new FormData();
      form.append("person_name_en", formData.person_name_en);
      form.append("person_name_ma", formData.person_name_ma);
      form.append("designation_en", formData.designation_en);
      form.append("designation_ma", formData.designation_ma);

      form.append("description_en", formData.description_en);
      form.append("description_ma", formData.description_ma);

      form.append(
        "small_image",
        this.state.fileChanged ? formData.small_image : null
      );
      if (formData.id) form.append("testimonial_id", formData.id);
      if (edit) {
        admin_quotes_add(form)
          .then((res) => {
            this.setState({
              submitted: true,

              snackMessage: res.message,
              isSnackError: false,
              snackOpen: true,
            });
          })
          .catch((error) => {
            this.setState({
              submitted: false,
              snackMessage: error.message,
              isSnackError: true,
              snackOpen: true,
            });
          });
      } else {
        admin_quotes_add(form)
          .then((res) => {
            this.setState({
              submitted: true,

              snackMessage: res.message,
              isSnackError: false,
              snackOpen: true,
            });
          })
          .catch((error) => {
            this.setState({
              submitted: false,
              snackMessage: error.message,
              isSnackError: true,
              snackOpen: true,
            });
          });
      }
    } else {
      this.setState({
        submitted: false,
        snackMessage: "Please fill all mandatory fields",
        isSnackError: true,
        snackOpen: true,
      });
    }
  };
  handleFileChange = (e) => {
    let flag = false;
    let type = e.target.files[0].name.split(".").pop();

    if (type === "png" || type === "jpeg" || type === "jpg") {
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;

        image.onload = function () {
          var height = this.height;
          var width = this.width;

          if (height === 400 || width === 400) {
            flag = true;
          }
        };
      };
      setTimeout(() => {
        if (flag) {
          this.setState({
            fileChanged: true,
            formData: {
              ...this.state.formData,
              [e.target.name]: e.target.files[0],
            },
          });
        } else {
          this.setState({
            snackMessage: "Incorrect image dimension",
            isSnackError: true,
            snackOpen: true,
          });
        }
      }, 200);
    } else {
      this.setState({
        snackMessage: "File should be in .jpg / .jpeg / .png format",
        isSnackError: true,
        snackOpen: true,
      });
    }
  };
  render() {
    const { submitted, formData, open, edit } = this.state;
    const { view } = this.props;
    return (
      <React.Fragment>
        {open && (
          <React.Fragment>
            <div className="overlay">
              <div className="big-dia-quotes dialog">
                <Snackbar
                  message={this.state.snackMessage}
                  error={this.state.isSnackError}
                  open={this.state.snackOpen}
                  close={this.snackbarClose}
                />
                <div className="row">
                  <div className="flexDisplay">
                    <div className="diaTitleCon">
                      {edit ? "Edit Messages" : view ? "Messages" : "Messages"}
                    </div>
                    <div className="dia-close" onClick={this.props.handleClose}>
                      <div className="diaCloseCon">
                        <div className="diaClose">&#10005;</div>
                      </div>
                    </div>
                  </div>
                  <div className="tilebar d-flex justify-content-start">
                    <hr />
                  </div>
                  <form action="">
                    <div className="book-form">
                      <div className="row ">
                        <div className="col-6">
                          <TextField
                            id="outlined-basic"
                            label="Person Name[Eng]"
                            variant="outlined"
                            disabled={view}
                            onChange={(e) => this.handleChange(e)}
                            value={formData.person_name_en ?? ""}
                            name="person_name_en"
                            required
                          />
                        </div>
                        <div className="col-6">
                          <TextField
                            id="outlined-basic"
                            label="Person Name[Mal]"
                            variant="outlined"
                            disabled={view}
                            onChange={(e) => this.handleChange(e)}
                            value={formData.person_name_ma ?? ""}
                            name="person_name_ma"
                            required
                          />
                        </div>
                        <div className="col-6">
                          <TextField
                            id="outlined-basic"
                            label="Designation[Eng]"
                            variant="outlined"
                            disabled={view}
                            onChange={(e) => this.handleChange(e)}
                            value={formData.designation_en ?? ""}
                            name="designation_en"
                            required
                          />
                        </div>
                        <div className="col-6">
                          <TextField
                            id="outlined-basic"
                            label="Designation[Mal]"
                            variant="outlined"
                            disabled={view}
                            onChange={(e) => this.handleChange(e)}
                            value={formData.designation_ma ?? ""}
                            name="designation_ma"
                            required
                          />
                        </div>
                        <div className="col-12">
                          <TextField
                            id="outlined-basic"
                            onChange={(e) => this.handleChange(e)}
                            value={formData.description_en ?? ""}
                            disabled={view}
                            multiline
                            label="Quote[Eng]"
                            name="description_en"
                            variant="outlined"
                            required
                          />
                        </div>
                        <div className="col-12">
                          <TextField
                            id="outlined-basic"
                            onChange={(e) => this.handleChange(e)}
                            value={formData.description_ma ?? ""}
                            multiline
                            disabled={view}
                            label="Quote[Mal]"
                            name="description_ma"
                            variant="outlined"
                            required
                          />
                        </div>

                        <div className="col-12">
                          <TextField
                            id="outlined-basic"
                            label="Image [width:400px , height:400px , format : .png , .jpg , .jpeg]"
                            name="small_image"
                            type="file"
                            disabled={view}
                            variant="outlined"
                            // value={formData.small_image ?? ""}
                            onChange={(e) => this.handleFileChange(e)}
                            // required
                          />
                        </div>
                      </div>
                      {!view && (
                        <div className="col-12">
                          <CustomButton
                            label={edit ? "Update" : "Add"}
                            disabled={submitted}
                            onClick={(e) => this.handleSubmit(e)}
                          />
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default Dialog;
