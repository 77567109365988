import React from "react";
import Carousel from "react-multi-carousel";
import "./multi.carousal.style.css";
import "react-multi-carousel/lib/styles.css";
// import useScreenBreakPoint from "../../../../../../hooks/useScreenBreakPoint"

function MultiCarousal({ children, ...props }) {
  // const width = useScreenBreakPoint();
  // console.log(props, children)
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1500 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
      // partialVisibilityGutter: 40,
    },
    small: {
      breakpoint: { max: 1500, min: 768 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
      // partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: { max: 768, min: 576 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
      // partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
      // partialVisibilityGutter: 30,
    },
  };
  const CustomRightArrow = ({ onClick }) => {
    return (
      <button
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left flaticon-back-5"
        onClick={() => onClick()}
      />
    );
  };
  const CustomLeftArrow = ({ onClick }) => {
    // onMove means if dragging or swiping in progress.
    return (
      <button
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right flaticon-next-7"
        onClick={() => onClick()}
      />
    );
  };
  // console.log(children);
  return (
    <div className="w-100">
      <div className="">
        <Carousel
          responsive={responsive}
          additionalTransfrom={0}
          arrows={true}
          swipeable={true}
          draggable={true}
          showDots={false}
          infinite={true}
          rtl="false"
          autoPlay={true}
          customLeftArrow={false}
          customRightArrow={false}
          pauseOnHover
          autoPlaySpeed={props.speed ?? 4000}
          slidesToSlide={1}
          keyBoardControl={true}
          customTransition="transform 1500ms ease-in-out"
          transitionDuration={500}
          containerClass="carousel-container multi "
          dotListClass="custom-dot-list-style"
          itemClass="d-flex align-items-center justify-content-center px-1"
          itemAriaLabel={props.section}
        >
          {children}
        </Carousel>
      </div>
    </div>
  );
}

export default MultiCarousal;
