import React from "react";
import { Route, Redirect } from "react-router-dom";
import Layout from "../../layout/userlayout/layout.component";

const VerifiedRoute = ({ component: Component, ...rest }) => {
  let valid = rest.state.isValid;
  let user = rest.state.isUserAuthenticated;
  let mobile_number = rest.phone;
  console.log(rest);
  return (
    <Route
      {...rest}
      render={(props) =>
        valid || user ? (
          <Layout {...props} {...rest} mobile_number={rest.phone}>
            <Component {...props} {...rest} mobile_number={rest.phone} />
          </Layout>
        ) : (
          <Redirect to="/registration" />
        )
      }
    />
  );
};

export default VerifiedRoute;
