import React, { useEffect, useState } from "react";
import RadioSelect from "../../../../components/inputs/radio";
import TextField from "../../../../components/inputs/textfield";
import Snackbar from "../../../../components/snackbar/snackbar";
import Loader from "../../../../components/loader/loader";
import { FormValidate } from "../../../../components/formValidate";
import { admin_tech_prop_add, tech_transfer_data } from "../../admin.api";
import "./view.css";

export default function AddSpaceparkAdvisors(props) {
  const [formData, setFormData] = useState({});
  let required = [
    "project_proposal_title",
    // "link",
    "organization_id",
    ...(formData.organization_id === "4" ? ["other_organization"] : []),
    "category_id",
    ...(formData.category_id === "3" ? ["other_category"] : []),
    "website_url"
  ];
  const [snackMessage, setSnackMessage] = useState("");
  const [dropdata, setDropdata] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    let data = formData;
    data = { ...data, [name]: value };
    setFormData(data);
  };

  const handleFileChange = (e) => {
    let size = e.target.files[0].size;
    let type = e.target.files[0].name.split(".").pop();

    if (type?.toLowerCase() === "jpg" || type?.toLowerCase() === "png") {
      let mb = size / Math.pow(1024, 2);

      if (mb < 5) {
        setFormData({
          ...formData,
          // file_name: e.target.files[0].name,
          [e.target.name]: e.target.files[0],
        });
      } else {
        setSnackMessage("File size is greater than 5MB");
        setIsSnackError(true);
        setFormData({
          ...formData,
          // file_name: e.target.files[0].name,
          [e.target.name]: null,
        });
        setSnackOpen(true);
      }
    } else {
      setSnackMessage("File should be in .jpg /.png format");
      setIsSnackError(true);
      setSnackOpen(true);
      setFormData({
        ...formData,
        // file_name: e.target.files[0].name,
        [e.target.name]: null,
      });
    }
  };
  const handleExpFileChange = (e) => {
    let size = e.target.files[0].size;
    let type = e.target.files[0].name.split(".").pop();

    if (type?.toLowerCase() === "pdf") {
      let mb = size / Math.pow(1024, 2);

      if (mb < 5) {
        setFormData({
          ...formData,
          // file_name: e.target.files[0].name,
          [e.target.name]: e.target.files[0],
        });
      } else {
        setSnackMessage("File size is greater than 5MB");
        setIsSnackError(true);
        setFormData({
          ...formData,
          // file_name: e.target.files[0].name,
          [e.target.name]: null,
        });
        setSnackOpen(true);
      }
    } else {
      setSnackMessage("File should be in .pdf format");
      setIsSnackError(true);
      setSnackOpen(true);
      setFormData({
        ...formData,
        // file_name: e.target.files[0].name,
        [e.target.name]: null,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if ( formData.website_url && formData.website_url !== "") {
      const urlRegexlink =
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;
      const urlRegex =
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;
     if(!urlRegex.test(formData?.website_url)){
        console.log(2, !urlRegex.test(formData?.website_url), formData)
        setSnackMessage("Please provide a valid website URL");
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
        return false;
      } else {

      }
    }

    const errors = FormValidate(formData, required);
    setIsActive(true);
    if (Object.keys(errors).length === 0) {
      const form = new FormData();
      if (formData.short_details_proposal) {
        form.append("short_details_proposal", formData.short_details_proposal);
      }
      if (formData.organization_id) {
        form.append("organization_id", formData.organization_id);
      }
      if (formData.other_organization) {
        form.append("other_organization", formData.other_organization);
      }
      if (formData.category_id) {
        form.append("category_id", formData.category_id);
      }
      if (formData.other_category) {
        form.append("other_category", formData.other_category);
      }
      if (formData.technology_exploratory_note) {
        form.append(
          "technology_exploratory_note",
          formData.technology_exploratory_note
        );
      }
      if (formData.website_url) {
        form.append("website_url", formData.website_url);
      }
      if (formData.file_upload_proposal) {
        form.append("file_upload_proposal", formData.file_upload_proposal);
      }
      form.append("project_proposal_title", formData.project_proposal_title);

      // form.append("link", formData.link);

      admin_tech_prop_add(form)
        .then((res) => {
          setSnackMessage(res.message);
          setIsSnackError(false);
          setSnackOpen(true);
          setIsActive(false);
        })
        .catch((error) => {
          setSnackMessage(error.message);
          setIsSnackError(true);
          setSnackOpen(true);
          setIsActive(false);
        });
      // console.log(formData);
    } else {
      setSnackMessage("Please fill all mandatory fields");
      setIsSnackError(true);
      setSnackOpen(true);
      setIsActive(false);
    }
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      props.history.push("/transfer-proposal-list");
    }
  };
  const getDropdownData = () => {
    tech_transfer_data()
      .then((res) => {
        setDropdata(res.data);
      })
      .catch((err) => {
        setSnackMessage(err.message);
        setIsSnackError(false);
        setSnackOpen(false);
      });
  };
  useEffect(() => {
    getDropdownData();
  }, []);
  return (
    <div className="add-per">
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      <Loader open={isActive} />
      <div className="view-container">
        <h6>
          <b>Add Technology Transfer Proposal</b>
        </h6>
        <hr />
        <div className="row">
          <div className="col-8 pb-3">
            <label className="control-label mylabel">Organization</label>
            <select
              className="form-select mandatory-select "
              aria-label="Default select example"
              name="organization_id"
              required
              // value={formData.main_category ?? ""}
              // disabled={view}
              onClick={(e) => handleChange(e)}
            >
              <option value={0}>Select Organization</option>
              {dropdata.map(
                (item) =>
                  item.type === "organization" && item.id !== 4 && (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  )
              )}
              <option key={4} value={4}>
                      {"Others"}
                    </option>
            </select>
          </div>
          {formData?.organization_id == 4 && (
            <div className="col-8 ">
              <TextField
                fullwidth
                label="Specify Your Organization"
                required
                value={formData.other_organization ?? ""}
                onChange={(e) => handleChange(e)}
                name="other_organization"
                id="other_organization"
                aria-describedby="emailHelp"
              />
            </div>
          )}
          <div className="col-8 pb-3">
            <label className="control-label mylabel">Category</label>
            <select
              className="form-select mandatory-select "
              aria-label="Default select example"
              name="category_id"
              required
              // value={formData.main_category ?? ""}
              // disabled={view}
              onClick={(e) => handleChange(e)}
            >
              <option value={0}>Select Category</option>
              {dropdata.map(
                (item) =>
                  item.type === "category" && item.id !== 3 &&(
                    <option key={item.id} value={item.id}>
                     {item.name}
                    </option>
                  )
              )}
                 <option key={3} value={3}>
                      {"Others"}
                    </option>
            </select>
          </div>
          {formData?.category_id == 3 && (
            <div className="col-8 ">
              <TextField
                fullwidth
                label="Specify Category"
                required
                value={formData.other_category ?? ""}
                onChange={(e) => handleChange(e)}
                name="other_category"
                id="other_category"
                aria-describedby="emailHelp"
              />
            </div>
          )}
          <div className="col-8 ">
            <TextField
              fullwidth
              label="Proposal Title"
              required
              value={formData.project_proposal_title ?? ""}
              onChange={(e) => handleChange(e)}
              name="project_proposal_title"
              id="project_proposal_title"
              aria-describedby="emailHelp"
            />
          </div>

          <div className="col-8">
            <TextField
              // required
              label="Proposal Description"
              onChange={(e) => handleChange(e)}
              name="short_details_proposal"
              value={formData.short_details_proposal}
              aria-describedby="emailHelp"
              multiline
              fullwidth
            />
          </div>

          <div className="col-8">
            <TextField
              // type="file"
              // className="form-control form-control-sm"
              label="Interest Exploratory Note"
              onChange={(e) => handleExpFileChange(e)}
              id="technology_exploratory_note"
              name="technology_exploratory_note"
              accept=".pdf"
              type="file"
            />
            <small className="pers">
              *Upload only .pdf format and Size should be less than 5MB
            </small>
          </div>
          <div className="col-8">
            <TextField
              // type="file"
              // className="form-control form-control-sm"
              label="Image of Proposal (If any)"
              onChange={(e) => handleFileChange(e)}
              id="file_upload_proposal"
              name="file_upload_proposal"
              accept=".jpg,.png"
              type="file"
            />
            <small className="pers">
              *Upload only .jpg, .png format and Size should be less than 5MB
            </small>
          </div>
          {/* <div className="col-8 ">
            <TextField
              fullwidth
              label="Proposal Link (example : https://example.com)"
              required
              value={formData.link ?? ""}
              onChange={(e) => handleChange(e)}
              name="link"
              id="link"
              aria-describedby="emailHelp"
            />
          </div> */}
          <div className="col-8 ">
            <TextField
              fullwidth
              label="Website URL (example : https://example.com)"
              required
              value={formData.website_url ?? ""}
              onChange={(e) => handleChange(e)}
              name="website_url"
              id="website_url"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="col-8">
            <center>
              <button
                type="button"
                className="btn btn-dark mt-3"
                onClick={(e) => handleSubmit(e)}
              >
                Submit for Acceptance
              </button>
            </center>
          </div>
          {/* ---------------- */}
        </div>
      </div>
    </div>
  );
}
