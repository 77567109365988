import React from "react";
import { Route } from "react-router-dom";
import Layout from "../../layout/userlayout/layout.component";

const PublicRoute = ({ component: Component, ...rest }) => {
  console.log(rest);
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props} {...rest} key={rest.location?.key}>
          {/* <ScrollToTop> */}
          <Component {...props} {...rest} key={rest.location?.key} />
          {/* <Footer /> */}
          {/* </ScrollToTop> */}
        </Layout>
      )}
    />
  );
};

export default PublicRoute;
