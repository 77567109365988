import React from "react";
import CommonForm from "../../../../components/commonform/commonForm";
import CustomIcons from "../../../../components/icons/kIcons.component";

import "./style.css";

export default function Addproposal(props) {
  return (
    <div className="proj">
      <div>
        <div className="dash-banner ">
          <div className="tit-txt"> Proposal Directory</div>
        </div>
      </div>
      <div className="container proposalbox">
        {/* <CustomIcons iconName="phone1" iconClass="daticon" /> */}
        <img src="../public/resources/pdf.png" alt="" />
        <div className="row">
          {/* --------card---------- */}
          <div className="col-md-6 col-sm-12 mb-3">
            <div className="card">
              <div className="d-flex">
                <div className="col-md-3 d-flex align-items-center justify-content-center pdf">
                  <div className="d-flex flex-column flx">
                    <center>
                      <img
                        src="/resources/img/pdf.png"
                        className="pdfimg"
                        alt=""
                      />
                      <button
                        type="button"
                        className="btn btn-primary btn-sm mt-2 vwbt"
                      >
                        View
                      </button>
                    </center>
                  </div>
                </div>
                <div className="col-md-9 pro-data">
                  <h5>
                    Cybertechnology in Space and Demo modeling of Robotics
                  </h5>
                  <small>Idea By</small>
                  <div className="d-flex mb-2">
                    <CustomIcons iconName="user" />{" "}
                    <p>Collage of Engineering</p>
                  </div>
                  <div className="d-flex mb-2">
                    <CustomIcons iconName="message" />{" "}
                    <p>akhildemotxt78@gmailcom</p>
                  </div>
                  <div className="d-flex mb-2">
                    <CustomIcons iconName="phone2" /> <p>564585647</p>
                  </div>
                  {/* <p> <CustomIcons iconName="message" iconClass="daticon" /><span>nvgadgiohdlfhoia</span></p>
                <p> <CustomIcons iconName="phone2" iconClass="daticon" /><span>nvgadgiohdlfhoia</span></p> */}
                  <h6>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna.
                    Lorem ipsum dolor sit amet, consectetur adipiscing.Lorem
                    ipsum dolor sit amet, consectetur adipiscing
                  </h6>
                </div>
              </div>
            </div>
          </div>
          {/* --------------------- */}
          {/* --------card---------- */}
          <div className="col-md-6 col-sm-12 mb-3">
            <div className="card">
              <div className="d-flex">
                <div className="col-md-3 d-flex align-items-center justify-content-center pdf">
                  <div className="d-flex flex-column flx">
                    <center>
                      <img
                        src="/resources/img/pdf.png"
                        className="pdfimg"
                        alt=""
                      />
                      <button
                        type="button"
                        className="btn btn-primary btn-sm mt-2 vwbt"
                      >
                        View
                      </button>
                    </center>
                  </div>
                </div>
                <div className="col-md-9 pro-data">
                  <h5>
                    Cybertechnology in Space and Demo modeling of Robotics
                  </h5>
                  <small>Idea By</small>
                  <div className="d-flex mb-2">
                    <CustomIcons iconName="user" />{" "}
                    <p>Collage of Engineering</p>
                  </div>
                  <div className="d-flex mb-2">
                    <CustomIcons iconName="message" />{" "}
                    <p>akhildemotxt78@gmailcom</p>
                  </div>
                  <div className="d-flex mb-2">
                    <CustomIcons iconName="phone2" /> <p>564585647</p>
                  </div>
                  {/* <p> <CustomIcons iconName="message" iconClass="daticon" /><span>nvgadgiohdlfhoia</span></p>
                <p> <CustomIcons iconName="phone2" iconClass="daticon" /><span>nvgadgiohdlfhoia</span></p> */}
                  <h6>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna.
                    Lorem ipsum dolor sit amet, consectetur adipiscing.Lorem
                    ipsum dolor sit amet, consectetur adipiscing
                  </h6>
                </div>
              </div>
            </div>
          </div>
          {/* --------------------- */}
          {/* --------card---------- */}
          <div className="col-md-6 col-sm-12 mb-3">
            <div className="card">
              <div className="d-flex">
                <div className="col-md-3 d-flex align-items-center justify-content-center pdf">
                  <div className="d-flex flex-column flx">
                    <center>
                      <img
                        src="/resources/img/pdf.png"
                        className="pdfimg"
                        alt=""
                      />
                      <button
                        type="button"
                        className="btn btn-primary btn-sm mt-2 vwbt"
                      >
                        View
                      </button>
                    </center>
                  </div>
                </div>
                <div className="col-md-9 pro-data">
                  <h5>
                    Cybertechnology in Space and Demo modeling of Robotics
                  </h5>
                  <small>Idea By</small>
                  <div className="d-flex mb-2">
                    <CustomIcons iconName="user" />{" "}
                    <p>Collage of Engineering</p>
                  </div>
                  <div className="d-flex mb-2">
                    <CustomIcons iconName="message" />{" "}
                    <p>akhildemotxt78@gmailcom</p>
                  </div>
                  <div className="d-flex mb-2">
                    <CustomIcons iconName="phone2" /> <p>564585647</p>
                  </div>
                  {/* <p> <CustomIcons iconName="message" iconClass="daticon" /><span>nvgadgiohdlfhoia</span></p>
                <p> <CustomIcons iconName="phone2" iconClass="daticon" /><span>nvgadgiohdlfhoia</span></p> */}
                  <h6>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna.
                    Lorem ipsum dolor sit amet, consectetur adipiscing.Lorem
                    ipsum dolor sit amet, consectetur adipiscing
                  </h6>
                </div>
              </div>
            </div>
          </div>
          {/* --------------------- */}
          {/* --------card---------- */}
          <div className="col-md-6 col-sm-12 mb-3">
            <div className="card">
              <div className="d-flex">
                <div className="col-md-3 d-flex align-items-center justify-content-center pdf">
                  <div className="d-flex flex-column flx">
                    <center>
                      <img
                        src="/resources/img/pdf.png"
                        className="pdfimg"
                        alt=""
                      />
                      <button
                        type="button"
                        className="btn btn-primary btn-sm mt-2 vwbt"
                      >
                        View
                      </button>
                    </center>
                  </div>
                </div>
                <div className="col-md-9 pro-data">
                  <h5>
                    Cybertechnology in Space and Demo modeling of Robotics
                  </h5>
                  <small>Idea By</small>
                  <div className="d-flex mb-2">
                    <CustomIcons iconName="user" />{" "}
                    <p>Collage of Engineering</p>
                  </div>
                  <div className="d-flex mb-2">
                    <CustomIcons iconName="message" />{" "}
                    <p>akhildemotxt78@gmailcom</p>
                  </div>
                  <div className="d-flex mb-2">
                    <CustomIcons iconName="phone2" /> <p>564585647</p>
                  </div>
                  {/* <p> <CustomIcons iconName="message" iconClass="daticon" /><span>nvgadgiohdlfhoia</span></p>
                <p> <CustomIcons iconName="phone2" iconClass="daticon" /><span>nvgadgiohdlfhoia</span></p> */}
                  <h6>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna.
                    Lorem ipsum dolor sit amet, consectetur adipiscing.Lorem
                    ipsum dolor sit amet, consectetur adipiscing
                  </h6>
                </div>
              </div>
            </div>
          </div>
          {/* --------------------- */}
          {/* --------card---------- */}
          <div className="col-md-6 col-sm-12 mb-3">
            <div className="card">
              <div className="d-flex">
                <div className="col-md-3 d-flex align-items-center justify-content-center pdf">
                  <div className="d-flex flex-column flx">
                    <center>
                      <img
                        src="/resources/img/pdf.png"
                        className="pdfimg"
                        alt=""
                      />
                      <button
                        type="button"
                        className="btn btn-primary btn-sm mt-2 vwbt"
                      >
                        View
                      </button>
                    </center>
                  </div>
                </div>
                <div className="col-md-9 pro-data">
                  <h5>
                    Cybertechnology in Space and Demo modeling of Robotics
                  </h5>
                  <small>Idea By</small>
                  <div className="d-flex mb-2">
                    <CustomIcons iconName="user" />{" "}
                    <p>Collage of Engineering</p>
                  </div>
                  <div className="d-flex mb-2">
                    <CustomIcons iconName="message" />{" "}
                    <p>akhildemotxt78@gmailcom</p>
                  </div>
                  <div className="d-flex mb-2">
                    <CustomIcons iconName="phone2" /> <p>564585647</p>
                  </div>
                  {/* <p> <CustomIcons iconName="message" iconClass="daticon" /><span>nvgadgiohdlfhoia</span></p>
                <p> <CustomIcons iconName="phone2" iconClass="daticon" /><span>nvgadgiohdlfhoia</span></p> */}
                  <h6>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna.
                    Lorem ipsum dolor sit amet, consectetur adipiscing.Lorem
                    ipsum dolor sit amet, consectetur adipiscing
                  </h6>
                </div>
              </div>
            </div>
          </div>
          {/* --------------------- */}
          {/* --------card---------- */}
          <div className="col-md-6 col-sm-12 mb-3">
            <div className="card">
              <div className="d-flex">
                <div className="col-md-3 d-flex align-items-center justify-content-center pdf">
                  <div className="d-flex flex-column flx">
                    <center>
                      <img
                        src="/resources/img/pdf.png"
                        className="pdfimg"
                        alt=""
                      />
                      <button
                        type="button"
                        className="btn btn-primary btn-sm mt-2 vwbt"
                      >
                        View
                      </button>
                    </center>
                  </div>
                </div>
                <div className="col-md-9 pro-data">
                  <h5>
                    Cybertechnology in Space and Demo modeling of Robotics
                  </h5>
                  <small>Idea By</small>
                  <div className="d-flex mb-2">
                    <CustomIcons iconName="user" />{" "}
                    <p>Collage of Engineering</p>
                  </div>
                  <div className="d-flex mb-2">
                    <CustomIcons iconName="message" />{" "}
                    <p>akhildemotxt78@gmailcom</p>
                  </div>
                  <div className="d-flex mb-2">
                    <CustomIcons iconName="phone2" /> <p>564585647</p>
                  </div>
                  {/* <p> <CustomIcons iconName="message" iconClass="daticon" /><span>nvgadgiohdlfhoia</span></p>
                <p> <CustomIcons iconName="phone2" iconClass="daticon" /><span>nvgadgiohdlfhoia</span></p> */}
                  <h6>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna.
                    Lorem ipsum dolor sit amet, consectetur adipiscing.Lorem
                    ipsum dolor sit amet, consectetur adipiscing
                  </h6>
                </div>
              </div>
            </div>
          </div>
          {/* --------------------- */}
          {/* --------card---------- */}
          <div className="col-md-6 col-sm-12 mb-3">
            <div className="card">
              <div className="d-flex">
                <div className="col-md-3 d-flex align-items-center justify-content-center pdf">
                  <div className="d-flex flex-column flx">
                    <center>
                      <img
                        src="/resources/img/pdf.png"
                        className="pdfimg"
                        alt=""
                      />
                      <button
                        type="button"
                        className="btn btn-primary btn-sm mt-2 vwbt"
                      >
                        View
                      </button>
                    </center>
                  </div>
                </div>
                <div className="col-md-9 pro-data">
                  <h5>
                    Cybertechnology in Space and Demo modeling of Robotics
                  </h5>
                  <small>Idea By</small>
                  <div className="d-flex mb-2">
                    <CustomIcons iconName="user" />{" "}
                    <p>Collage of Engineering</p>
                  </div>
                  <div className="d-flex mb-2">
                    <CustomIcons iconName="message" />{" "}
                    <p>akhildemotxt78@gmailcom</p>
                  </div>
                  <div className="d-flex mb-2">
                    <CustomIcons iconName="phone2" /> <p>564585647</p>
                  </div>
                  {/* <p> <CustomIcons iconName="message" iconClass="daticon" /><span>nvgadgiohdlfhoia</span></p>
                <p> <CustomIcons iconName="phone2" iconClass="daticon" /><span>nvgadgiohdlfhoia</span></p> */}
                  <h6>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna.
                    Lorem ipsum dolor sit amet, consectetur adipiscing.Lorem
                    ipsum dolor sit amet, consectetur adipiscing
                  </h6>
                </div>
              </div>
            </div>
          </div>
          {/* --------------------- */}
        </div>
      </div>
    </div>
  );
}
