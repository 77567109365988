import OTPInput from "otp-input-react";
import React, { useState, useEffect } from "react";
import Loader from "../../../components/loader/loader";

import Snackbar from "../../../components/snackbar/snackbar";
import { userRegConfirm, userReg } from "../login/auth.api";
import "./verify.css";
export default function Verify(props) {
  const [OTP, setOTP] = useState("");

  const [submit, setSubmit] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const activelocation = sessionStorage.getItem("active_path");
  const activePath = activelocation ?? "/";
  useEffect(() => {
    if (!props.phone) {
      props.history.push("/registration");
    }
  }, []);
  const manageLogin = (e) => {
    e.preventDefault();
    setSubmit(true);
    setIsActive(true);

    let data = {
      mobile_number: props.phone,
      otp_value: OTP,
    };
    userRegConfirm(data)
      .then((res) => {
        setSubmit(false);
        setIsActive(false);
        // if (localStorage.getItem("kspace_reg_token")) {
        props.valid();
        // window.open("/new-company", "_self");

        setTimeout(() => {
          props.history.push(activelocation);
        }, 1000);

        // localStorage.removeItem("active_path");
      })
      .catch((err) => {
        setSubmit(false);
        setSubmit(false);
        setIsActive(false);
        setSnackMessage(err.response.data.message);
        setIsSnackError(true);
        setSnackOpen(true);
      });
  };
  const resend = (e) => {
    e.preventDefault();
    let loginParam = { mobile_number: props.phone };
    setSubmit(true);
    setIsActive(true);
    userReg(loginParam)
      .then((res) => {
        setSnackMessage(res.message);
        setSubmit(false);
        setIsActive(false);
        setIsSnackError(false);
        setSnackOpen(true);
      })
      .catch((err) => {
        setSubmit(false);
        setSnackMessage(err.response.data.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      setOTP("");
    }
  };
  console.log(props);
  return (
    <div className="otp-login-container">
      <Loader opn={isActive} />
      <div className="otp-login-section">
        <div className="">
          <form onSubmit={manageLogin}>
            <div className="otp-login-body d-flex justify-content-center six align-items-center flex-column">
              <div>
                <img src="/resources/img/login/login-avatar.png" alt="" />
              </div>
              <div className="otp-number-label ">
                Enter the OTP received in your number
              </div>

              <OTPInput
                value={OTP}
                onChange={setOTP}
                autoFocus
                OTPLength={6}
                otpType="number"
                style={{ marginRight: "0px!important" }}
                disabled={false}
              />
            </div>
            <div className="otp-login-action">
              <button disabled={submit} className="otp-login-button">
                Verify
              </button>
            </div>
          </form>
          <div className="d-flex justify-content-around">
            <div
              className="otp-not-text"
              onClick={() => {
                props.history.push("/registration");
              }}
            >
              {" "}
              Re-enter your number
            </div>
            <div
              className="otp-resend-text"
              onClick={(e) => resend(e)}
              // onClick={() => {
              //   props.history.push("/login");
              // }}
            >
              {" "}
              Resend OTP
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
    </div>
  );
}
