import React, { useEffect, useState } from "react";
import ManageMaintext from "./manage/manage";
import CustomTable from "../../../../components/table/table";
import ConfirmDialog from "../../../../components/actionDialogs/confirmDialog";
import Snackbar from "../../../../components/snackbar/snackbar";
import { admin_about_list } from "../../admin.api";
import "./view.css";
import Loader from "../../../../components/loader/loader";

export default function View(props) {
  const [data, setdata] = useState([]);
  const [addNew, setAddNew] = useState(false);
  const [formData, setFormData] = useState({});
  const [edit, setEdit] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [activeData, setActiveData] = useState({});
  const [isActive, setIsActive] = useState(true);
  const [view, setView] = useState(false);
  const handleEventList = () => {
    setIsActive(true);
    admin_about_list().then((res) => {
      let tempData = res.data;
      if (tempData) {
        tempData.forEach((item, index) => {
          item["slno"] = index + 1;
        });
        setdata(tempData);
        setIsActive(false);
      }
    });
  };
  useEffect(() => {
    handleEventList();
  }, []);
  const handleClose = () => {
    setAddNew(false);
    setEdit(false);
    setFormData({});
    handleEventList();
  };
  const handleEdit = () => {
    setFormData(data[0]);
    setView(false);
    setEdit(true);
    setAddNew(true);
  };
  const handleAddNew = () => {
    setAddNew(true);
    setView(false);
  };
  const handleDelete = (data) => {
    setActiveData(data);
    setOpenAction(true);
  };
  const handleCancelDelete = () => {
    setOpenAction(false);
  };
  // const handleDeleteConfirm = (data) => {
  //   setOpenAction(false);
  //   admin_notification_delete(data.uuid)
  //     .then((res) => {
  //       setSnackMessage(res.message);
  //       setIsSnackError(false);
  //       setSnackOpen(true);
  //     })
  //     .catch((error) => {
  //       setSnackMessage(error.message);
  //       setIsSnackError(true);
  //       setSnackOpen(true);
  //     });
  // };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      // handleEventList();
    }
  };
  const handleView = (data) => {
    setFormData(data);
    setAddNew(true);
    setView(true);
  };
  return (
    <div>
      <Loader open={isActive} />
      <div className="view-container">
        <h6>
          <b>Main Text</b>
        </h6>
        <hr />
        <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={(e) => handleEdit(e)}
        >
          Edit Main-Text
        </button>
        <CustomTable
          {...props}
          columns={[
            { title: "About [Eng]", field: "contents_en" },
            { title: "About [Mal]", field: "contents_ma" },
          ]}
          data={data}
        />
        <CustomTable
          {...props}
          columns={[
            { title: "Vision Description [Eng]", field: "vission_en" },

            { title: "Vision Description [Mal]", field: "vission_ma" },
          ]}
          data={data}
        />
        <CustomTable
          {...props}
          columns={[
            { title: "Mission Description [Eng]", field: "mission_en" },
            { title: "Mission Description [Mal]", field: "mission_ma" },
          ]}
          data={data}
        />
        <CustomTable
          {...props}
          columns={[
            {
              title: "Immediate Mission Description [Eng]",
              field: "immediate_mission_en",
            },
            {
              title: "Immediate Mission Description [Mal]",
              field: "immediate_mission_ma",
            },
          ]}
          data={data}
        />
      </div>
      {addNew && (
        <ManageMaintext
          open={addNew}
          edit={edit}
          view={view}
          handleClose={handleClose}
          formData={formData}
        />
      )}
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      {openAction && (
        <ConfirmDialog
          open={openAction}
          title="Delete Main-Text ?"
          data={activeData}
          message="Are you sure to delete this maintext?"
          secondary_action="Cancel"
          primary_action="Delete"
          handleClose={handleCancelDelete}
          // handleConfirm={handleDeleteConfirm}
        />
      )}
    </div>
  );
}
