import React from "react";
import { Route, Redirect } from "react-router-dom";
import Layout from "../../layout/userlayout/layout.component";
import AdminLayout from "../../layout/adminlayout/layout.component";
import { Button } from "bootstrap";

const ErrorRoute = ({ ...rest }) => {
  let auth = rest.state.isUserAuthenticated;

  let admin = rest.state.isAdminAuthenticated;
  let ActiveLayout = Layout;
  if (admin) {
    ActiveLayout = Layout;
  } else {
    ActiveLayout = AdminLayout;
  }

  //
  return (
    <Route
      {...rest}
      render={(props) => (
        <ActiveLayout {...props} {...rest}>
          <div className="d-flex align-items-center flex-column jusify-content-center p-5">
            <div>
              <img src="/resources/img/40-min.png" class="w-100" />
            </div>
            <div>
              <button
                className="btn btn-primary"
                onClick={(e) =>
                  props.history.push(
                    admin ? "/admin-dashboard" : auth ? "/dashboard" : "/"
                  )
                }
              >
                Home
              </button>
            </div>
          </div>
        </ActiveLayout>
      )}
    />
  );
};

export default ErrorRoute;
