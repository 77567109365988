import React, { Component } from "react";
import "./dialog.css";
import TextField from "../../../../../components/inputs/textfield";

import CustomButton from "../../../../../components/inputs/button";
import { FormValidate } from "../../../../../components/formValidate";
import Snackbar from "../../../../../components/snackbar/snackbar";
import { admin_aboutus_update } from "../../../admin.api";
import Loader from "../../../../../components/loader/loader";
let required = [
  "immediate_mission_en",
  "immediate_mission_ma",

  "mission_en",
  "mission_ma",

  "vission_en",
  "vission_ma",

  "contents_en",
  "contents_ma",
];
class Dialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      formData: props.formData,
      edit: props.edit,
      isActive: false,
      submitted: false,
      snackMessage: "",
      isSnackError: false,
      snackOpen: false,
    };
  }

  componentWillReceiveProps = (props) => {
    this.setState({
      open: props.open,
      edit: props.edit,
      formData: props.formData,
    });
  };
  handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({ formData: { ...this.state.formData, [name]: value } });
  };
  snackbarClose = (flag) => {
    this.setState({
      snackMessage: "",
      isSnackError: false,
      snackOpen: false,
    });
    if (!flag) {
      this.props.handleClose();
    }
  };
  handleSubmit = (e) => {
    const error = FormValidate(this.state.formData, required);
    const { edit, formData } = this.state;
    let data = { formData };

    e.preventDefault();

    if (Object.keys(error).length === 0) {
      if (edit) {
        this.setState({ isActive: true });
        admin_aboutus_update(formData.uuid, formData)
          .then((res) => {
            this.setState({
              submitted: true,
              isActive: false,
              snackMessage: res.message,
              isSnackError: false,
              snackOpen: true,
            });
          })
          .catch((error) => {
            this.setState({
              submitted: false,
              isActive: false,
              snackMessage: error.message,
              isSnackError: true,
              snackOpen: true,
            });
          });
      }
    } else {
      this.setState({
        submitted: false,
        snackMessage: "Please fill all mandatory fields",
        isSnackError: true,
        snackOpen: true,
      });
    }
  };

  render() {
    const { submitted, formData, open, edit, isActive } = this.state;
    const { view } = this.props;
    return (
      <React.Fragment>
        {open && (
          <React.Fragment>
            <div className="overlay">
              <Loader open={isActive} />
              <div className="big-dia dialog">
                <Snackbar
                  message={this.state.snackMessage}
                  error={this.state.isSnackError}
                  open={this.state.snackOpen}
                  close={this.snackbarClose}
                />
                <div className="row">
                  <div className="flexDisplay">
                    <div className="diaTitleCon">
                      {edit
                        ? "Edit Main-Text"
                        : view
                        ? "Main-Text"
                        : "Add Main-Text"}
                    </div>
                    <div className="dia-close" onClick={this.props.handleClose}>
                      <div className="diaCloseCon">
                        <div className="diaClose">&#10005;</div>
                      </div>
                    </div>
                  </div>
                  <div className="tilebar d-flex justify-content-start">
                    <hr />
                  </div>
                  <form action="">
                    <div className="book-form">
                      <div className="row content-scroll">
                        <div className="col-12">
                          <TextField
                            id="outlined-basic"
                            onChange={(e) => this.handleChange(e)}
                            multiline
                            disabled={view}
                            label="About Us[Eng]"
                            name="contents_en"
                            value={formData.contents_en ?? ""}
                            variant="outlined"
                            required
                          />
                        </div>
                        <div className="col-12">
                          <TextField
                            id="outlined-basic"
                            onChange={(e) => this.handleChange(e)}
                            multiline
                            disabled={view}
                            label="About Us[Mal]"
                            name="contents_ma"
                            value={formData.contents_ma ?? ""}
                            variant="outlined"
                            required
                          />
                        </div>
                        <div className="col-6">
                          <TextField
                            id="outlined-basic"
                            onChange={(e) => this.handleChange(e)}
                            multiline
                            disabled={view}
                            label="Mission[Eng]"
                            name="mission_en"
                            value={formData.mission_en ?? ""}
                            variant="outlined"
                            required
                          />
                        </div>
                        <div className="col-6">
                          <TextField
                            id="outlined-basic"
                            onChange={(e) => this.handleChange(e)}
                            multiline
                            disabled={view}
                            label="Mission[Mal]"
                            name="mission_ma"
                            value={formData.mission_ma ?? ""}
                            variant="outlined"
                            required
                          />
                        </div>

                        <div className="col-6">
                          <TextField
                            id="outlined-basic"
                            onChange={(e) => this.handleChange(e)}
                            multiline
                            disabled={view}
                            label="Vision[Eng]"
                            name="vission_en"
                            value={formData.vission_en ?? ""}
                            variant="outlined"
                            required
                          />
                        </div>
                        <div className="col-6">
                          <TextField
                            id="outlined-basic"
                            onChange={(e) => this.handleChange(e)}
                            multiline
                            disabled={view}
                            label="Vision[Mal]"
                            name="vission_ma"
                            value={formData.vission_ma ?? ""}
                            variant="outlined"
                            required
                          />
                        </div>
                        <div className="col-6">
                          <TextField
                            id="outlined-basic"
                            onChange={(e) => this.handleChange(e)}
                            multiline
                            disabled={view}
                            label="Immediate Mission[Eng]"
                            name="immediate_mission_en"
                            value={formData.immediate_mission_en ?? ""}
                            variant="outlined"
                            required
                          />
                        </div>
                        <div className="col-6">
                          <TextField
                            id="outlined-basic"
                            onChange={(e) => this.handleChange(e)}
                            multiline
                            disabled={view}
                            label="Immediate Mission[Mal]"
                            name="immediate_mission_ma"
                            value={formData.immediate_mission_ma ?? ""}
                            variant="outlined"
                            required
                          />
                        </div>
                      </div>
                      {!view && (
                        <div className="col-12">
                          <CustomButton
                            label={edit ? "Update" : "Add"}
                            disabled={submitted}
                            onClick={(e) => this.handleSubmit(e)}
                          />
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default Dialog;
