import OTPInput from "otp-input-react";
import React, { useState, useEffect } from "react";
import Loader from "../../../components/loader/loader";

import Snackbar from "../../../components/snackbar/snackbar";
import { verifyOtp, userLogin } from "../login/auth.api";
import "./otp.css";
export default function Otp(props) {
  useEffect(() => {
    if (!props.phone) {
      props.history.push("/login");
    }
  }, []);
  const [OTP, setOTP] = useState("");

  const [submit, setSubmit] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const activelocation = sessionStorage.getItem("next_path");
  const activePath = activelocation ?? "/dashboard";
  const manageLogin = (e) => {
    e.preventDefault();
    setSubmit(true);

    let data = {
      mobile_number: props.phone,
      otp_value: OTP,
    };
    setIsActive(true);
    verifyOtp(data)
      .then((res) => {
        sessionStorage.setItem("token", res.token)
        console.log(res);
        setSubmit(false);
        setIsActive(false);
        sessionStorage.setItem("token", res.token)
        // if (localStorage.getItem("kspaceToken")) {
        props.login(res.token);
        // window.open("/dashboard", "_self");
        props.history.push(activePath);
        sessionStorage.removeItem("next_path");
        // }
      })
      .catch((err) => {
        setSubmit(false);
        setIsActive(false);

        setSubmit(false);
        setSnackMessage(err.response.data.message);
        setIsSnackError(true);
        setSnackOpen(true);
      });
  };
  const resend = (e) => {
    e.preventDefault();
    let loginParam = { mobile_number: props.phone };
    setSubmit(true);
    setIsActive(true);

    userLogin(loginParam)
      .then((res) => {
        setSnackMessage(res.message);
        setSubmit(false);
        setIsActive(false);

        setIsSnackError(false);
        setSnackOpen(true);
      })
      .catch((err) => {
        setSubmit(false);
        setSnackMessage(err.response.data.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      setOTP("");
    }
  };
  return (
    <div className="otp-login-container">
      <Loader open={isActive} />
      <div className="otp-login-section">
        <div className="">
          <form onSubmit={manageLogin}>
            <div className="otp-login-body d-flex justify-content-center align-items-center flex-column">
              <div>
                <img src="/resources/img/login/login-avatar.png" alt="" />
              </div>
              <div className="otp-number-label ">
                Enter the OTP received in your Number
              </div>

              <OTPInput
                value={OTP}
                onChange={setOTP}
                autoFocus
                OTPLength={6}
                otpType="number"
                disabled={false}
              />
            </div>
            <div className="otp-login-action">
              <button disabled={submit} className="otp-login-button">
                Verify
              </button>
            </div>
          </form>
          <div className="d-flex justify-content-around">
            <div
              className="otp-not-text"
              onClick={() => {
                props.history.push("/login");
              }}
            >
              {" "}
              Re-enter your number
            </div>
            <div className="otp-resend-text" onClick={(e) => resend(e)}>
              {" "}
              Resend OTP
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
    </div>
  );
}
