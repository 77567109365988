import React, { useEffect, useState } from "react";
import { Carousel, Placeholder } from "react-bootstrap";
import { public_banner_list } from "../../public.api";
import defaultdata from "./components/bannerDummy.json";
import BannerTile from "./components/bannerTile";
import NewsTile from "./components/newsTile";
import { user_news_list } from "../../../user/user.api";
import MultiCarousal from "./components/multicarousals/multi.carousal";
export default function NewsUpdates(props) {
  const [data, setdata] = useState([]);
  const [url, setUrl] = useState("");
  const [active, setActive] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleEventList = () => {
    setActive(true);
    user_news_list().then((res) => {
      setActive(false);
      setdata(res.data);
      setUrl(res.current_url);
      // props.checkLoaderStatus("bannerActive", false);
    });
  };
  useEffect(() => {
    handleEventList();
  }, []);
  const handlePrevious = () => {
    const count = data.length;
    const lastIndex = count - 1;
    let index = activeIndex - 1;
    if (activeIndex === 0) {
      setActiveIndex(lastIndex);
    } else {
      setActiveIndex(index);
    }
  };
  const handleNext = () => {
    const count = data.length;
    const lastIndex = count - 1;
    let index = activeIndex + 1;
    if (activeIndex === lastIndex) {
      setActiveIndex(0);
    } else {
      setActiveIndex(index);
    }
  };

  const mangeViewMore = () => {
    props.history.push("/news");
  };
  // const mangeViewMoreLink = () => {
  //   props.history.push("/news");
  // };
  return (
    <div className="w-100">
      <div className="scope-title pb-5 pt-5">
        {props.state.language === "ma"
          ? "വാർത്തകളും അപ്ഡേറ്റുകളും"
          : "News and Updates"}
      </div>
      <div className="d-flex justify-content-center w-100">
        {data.map((item, index) => (
          <div
            key={index}
            className="d-flex h-100"
            style={{ cursor: "pointer" }}
            // onClick={(e) => {
            //   mangeViewMoreLink(e);
            // }}
          >
            {(index <= 3 && item.news_enable_disable !== 0) && <NewsTile data={item} viewMore = {mangeViewMore}/>}
          </div>
        ))}
      </div>

      <div className="pad-50">
        <div className="all-to-center ">
          <button
            className="read-more-btn-gallery"
            onClick={(e) => {
              mangeViewMore(e);
            }}
          >
            All News and Updates
          </button>
        </div>
      </div>
    </div>
  );
}
