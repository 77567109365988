import React, { useState } from "react";

import ConfirmDialog from "../../../components/actionDialogs/confirmDialog";
import CustomTable from "../../../components/table/table";
import "./view.css";
import jwtDecode from "jwt-decode";
export default function View(props) {
  const [view, setView] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [openAction, setOpenAction] = useState(false);
  const [activeData, setActiveData] = useState({});
  let adminType = Number(jwtDecode(props.state.payload).user_type);

  const onSearch = () => {
    //
  };
  const handleEventList = () => {
    setIsActive(true);
    // admin_notification_list().then((res) => {
    //   let tdata = res.data;
    //   tdata.forEach((item, index) => {
    //     item["slno"] = index + 1;
    //   });
    // setdata(tdata);
    setIsActive(false);
    // });
  };
  const handleEdit = () => {
    let path = "/professional-edit";
    props.history.push(path);
  };
  const handleView = (data) => {
    let path = "/professional-view";
    props.history.push(path);
  };
  const handleAddNew = () => {
    let path = "/professional-add";
    props.history.push(path);
  };
  const handleDelete = (data) => {
    setActiveData(data);
    setOpenAction(true);
  };
  const handleDeleteConfirm = (data) => {
    setOpenAction(false);
    setIsActive(true);
  };

  const handleDownload = () => {};
  const handleCancelDelete = () => {
    setOpenAction(false);
  };
  return (
    <div>
      <div className="view-container">
        <h6>
          <b>Professional Bodies</b>
        </h6>
        <hr />
        {/* ---------admin--------- */}
        {adminType === 1 && (
          <>
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={(e) => handleAddNew(e)}
            >
              Add New
            </button>
            <CustomTable
              {...props}
              columns={[
                { title: "Logo", field: "logo" },
                { title: " Company Name", field: "name" },
                { title: "Website", field: "email" },
                { title: "Location", field: "location" },

                { title: "Status", field: "status", type: "statusType" },
              ]}
              data={[
                {
                  logo: "image",
                  name: "Spacex Antrix",
                  email: "www.spacex.com",
                  location: "Bangalore",
                  status: "Pending",
                },
              ]}
              actions={[
                { type: "view", handleClick: handleView },
                { type: "edit", handleClick: handleEdit },
                // { type: "delete", handleClick: handleEdit },
              ]}
            />
          </>
        )}
        {/* -------------super admin------ */}
        {adminType === 2 && (
          <>
            <CustomTable
              {...props}
              columns={[
                { title: "Logo", field: "logo" },
                { title: " Company Name", field: "name" },
                { title: "Website", field: "email" },
                { title: "Location", field: "location" },

                { title: "Status", field: "status", type: "status" },
              ]}
              data={[
                {
                  logo: "image",
                  name: "Spacex Antrix",
                  email: "www.spacex.com",
                  location: "Bangalore",

                  status: "pending",
                },
              ]}
              actions={[
                { type: "view", handleClick: handleView },
                // { type: "edit", handleClick: handleEdit },
                // { type: "delete", handleClick: handleDelete },
              ]}
            />
          </>
        )}
        {/* -------------super admin------ */}
        {adminType === 3 && (
          <>
            <CustomTable
              {...props}
              columns={[
                { title: "Logo", field: "logo" },
                { title: " Company Name", field: "name" },
                { title: "Website", field: "email" },
                { title: "Location", field: "location" },

                { title: "Status", field: "status", type: "status" },
              ]}
              data={[
                {
                  logo: "image",
                  name: "Spacex Antrix",
                  email: "www.spacex.com",
                  location: "Bangalore",

                  status: "pending",
                },
              ]}
              actions={[
                { type: "view", handleClick: handleView },
                // { type: "edit", handleClick: handleEdit },
                { type: "delete", handleClick: handleDelete },
              ]}
            />
          </>
        )}
      </div>
      {openAction && (
        <ConfirmDialog
          open={openAction}
          title="Delete Professional Body ?"
          data={activeData}
          message="Are you sure to delete this Professional Body?"
          secondary_action="Cancel"
          primary_action="Delete"
          handleClose={handleCancelDelete}
          handleConfirm={handleDeleteConfirm}
        />
      )}
    </div>
  );
}
