import "./events.css";
import "../home/home.css";
import Masonry from "react-responsive-masonry";
import { public_videos_list } from "../public.api";

import { useState, memo, useCallback, useEffect } from "react";
import Loader from "../../../components/loader/loader";
import VideoTile from "../../../components/page/home/gallery/videotile";
import BreadCrumbsComp from "../../../components/navigator/breadcrumbs";

const GalleryVideos = (props) => {
  let lang = props.state.language;
  const [data, setData] = useState([]);
  const [url, setUrl] = useState("");
  const [isActive, setIsActive] = useState(true);

  const fetchEvents = () => {
    public_videos_list().then((res) => {
      setData(res.data);
      setUrl(res.current_url);
      setIsActive(false);
    });
  };

  useEffect(() => {
    fetchEvents();
  }, []);
  const settingColumns = useCallback(() => {
    if (window.innerWidth >= 1400) return 4;
    if (window.innerWidth >= 800) return 3;
    if (window.innerWidth >= 500) return 2;
    return 1;
  }, []);
  const [column, setColumn] = useState(() => settingColumns());
  let fromHome = sessionStorage.getItem("continueFrom");
  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("resize", () => setColumn(() => settingColumns()));

    return window.removeEventListener("resize", () =>
      setColumn(() => settingColumns())
    );
  }, [setColumn, settingColumns]);
  return (
    <div>
      {fromHome === "gallery" && <BreadCrumbsComp section="home" />}
      <Loader open={isActive} />
      <div>
        {/* ======================= Events  =================================================================== */}
        <div className="container-fluid event-bg">
          <div className="row m-55">
            {/* <h3>Gallery</h3> */}
            <h3>Videos</h3>
          </div>
        </div>
        <div className=" mtb-25">
          <div className="container gal-i">
            <Masonry columnsCount={column} gutter="10px">
              {data &&
                data?.map((item, i) => (
                  <video controls key={i}>
                    <source src={item[lang]?.videos} type="video/mp4" />
                  </video>
                ))}
            </Masonry>
          </div>
        </div>
        {/* =================================================================================================== */}
      </div>
    </div>
  );
};
export default memo(GalleryVideos);
