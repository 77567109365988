import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import Underline from "../../../../components/page/home/underline/underline";
import { public_quotes_list } from "../../public.api";

export default function Personalities(props) {
  const [data, setdata] = useState([]);
  const [url, setUrl] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);

  const handlePreviousQuotes = () => {
    const count = data.length;
    const lastIndex = count - 1;
    let index = activeIndex - 1;
    if (activeIndex === 0) {
      setActiveIndex(lastIndex);
    } else {
      setActiveIndex(index);
    }
  };
  const handleNextQuotes = () => {
    const count = data.length;
    const lastIndex = count - 1;
    let index = activeIndex + 1;
    if (activeIndex === lastIndex) {
      setActiveIndex(0);
    } else {
      setActiveIndex(index);
    }
  };

  const handleDefaultQuotes = () => {
    setActiveIndex(0);
  };
  const handleEventList = () => {
    public_quotes_list().then((res) => {
      setdata(res.data);
      setUrl(res.current_url);
    });
  };
  useEffect(() => {
    handleEventList();
  }, []);
  let lang = props.state.language;
  //
  return (
    <div>
      <div className="new-messages">
        <div className="messages-main-title">
          <div> {lang === "ma" ? "സന്ദേശങ്ങൾ" : "Messages"}</div>
        </div>

        <Carousel className="w-100" controls={false}>
          {data.map((item, index) => (
            <Carousel.Item className="carlo-bg-quotes">
              <div className="quotes-new-inner">
                {/* <div className="quote-icon-container">
                  <img
                    src="/resources/img/quotes.png"
                    className="quotes-icon"
                    alt=""
                  />
                </div> */}
                <div className="msg-data-con">
                  <div className="d-flex pad-30">
                    <div className="msg-person-photo-cont">
                      <img
                        src={item[lang]?.image}
                        className="msg-person-photo"
                      />
                    </div>
                    <div className="msg-person-data-cont">
                      <div className="msg-person-name">{item[lang]?.Name}</div>
                      <div className="msg-person-designation">
                        {item[lang]?.Designation}
                      </div>
                    </div>
                  </div>
                  <div className="new-message-desc">
                    {item[lang]?.Description}
                  </div>
                </div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
        {/* <div className="d-flex">
              <div
                className="quote-actions"
                onClick={(e) => handlePreviousQuotes(e)}
              ></div>
              <div
                className="quote-actions default"
                onClick={(e) => handleDefaultQuotes(e)}
              ></div>
              <div
                className="quote-actions"
                onClick={(e) => handleNextQuotes(e)}
              ></div>
            </div> */}
      </div>
    </div>
  );
}
