import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import VideoTile from "../../../../components/page/home/gallery/videotile";
import { public_videos_list } from "../../public.api";

export default function VideoComp(props) {
  const [data, setdata] = useState([]);
  const [url, setUrl] = useState("");
  const [activeMenu, setActiveMenu] = useState("image");
  const [activeSelection, setActiveSelection] = useState(null);
  const handleEventList = () => {
    public_videos_list().then((res) => {
      setdata(res.data);
      setUrl(res.current_url);
    });
  };
  useEffect(() => {
    handleEventList();
  }, []);
  const handlePreview = (index) => {
    setActiveSelection(index);
  };

  const handleClosePreview = (index) => {
    setActiveSelection(null);
  };
  return (
    <div>
      <div className="">
        <div className="gallery-data-container">
          {/* <Carousel className="w-100" controls={false}> */}
          {data?.map((item, index) => (
            <>
              {index < 1 && (
                // <Carousel.Item>
                <VideoTile
                  data={item}
                  onClick={(e) => handlePreview(index)}
                  count={index}
                  {...props}
                  type={activeMenu}
                />
              )}
            </>
            // </Carousel.Item>
          ))}
          {/* </Carousel> */}
        </div>
      </div>
    </div>
  );
}
