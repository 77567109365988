import React, { useEffect, useState } from "react";
import CustomTable from "../../../../components/table/table";
import ConfirmDialog from "../../../../components/actionDialogs/confirmDialog";
import Snackbar from "../../../../components/snackbar/snackbar";
import Loader from "../../../../components/loader/loader";
import ManageScope from "./manage/manage";
import { admin_scopeact_list } from "../../admin.api";
import "./view.css";

export default function View(props) {
  const [data, setdata] = useState([]);
  const [addNew, setAddNew] = useState(false);
  const [formData, setFormData] = useState({});
  const [edit, setEdit] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [activeData, setActiveData] = useState({});
  const [view, setView] = useState(false);

  const handleEventList = () => {
    setIsActive(true);
    admin_scopeact_list().then((res) => {
      let tdata = res.data;
      tdata.forEach((item, index) => {
        item["slno"] = index + 1;
      });
      setdata(tdata);
      setIsActive(false);
    });
  };
  useEffect(() => {
    handleEventList();
  }, []);
  const handleClose = () => {
    setAddNew(false);
    setEdit(false);
    setFormData({});
    handleEventList();
  };
  const handleEdit = (data) => {
    setFormData(data);
    setView(false);
    setEdit(true);
    setAddNew(true);
  };
  const handleAddNew = () => {
    setAddNew(true);
    setView(false);
  };
  const handleDelete = (data) => {
    setActiveData(data);
    setOpenAction(true);
  };
  const handleCancelDelete = () => {
    setOpenAction(false);
  };
  // const handleDeleteConfirm = (data) => {
  //   setOpenAction(false);
  //   admin_notification_delete(data.uuid)
  //     .then((res) => {
  //       setSnackMessage(res.message);
  //       setIsSnackError(false);
  //       setSnackOpen(true);
  //     })
  //     .catch((error) => {
  //       setSnackMessage(error.message);
  //       setIsSnackError(true);
  //       setSnackOpen(true);
  //     });
  // };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      handleEventList();
    }
  };
  const handleView = (data) => {
    setFormData(data);
    setAddNew(true);
    setView(true);
  };

  return (
    <div>
      <Loader open={isActive} />
      <div className="view-container">
        <h6>
          <b>Scope of Activities</b>
        </h6>
        <hr />
        {/* <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={(e) => handleAddNew(e)}
        >
          Add New
        </button> */}
        <CustomTable
          {...props}
          columns={[
            { title: "Sl. No", field: "slno", type: "dynId" },
            { title: "Scopes", field: "title_en" },
          ]}
          data={data}
          actions={[
            { type: "view", handleClick: handleView },
            { type: "edit", handleClick: handleEdit },
            // { type: "delete", handleClick: handleDelete },
          ]}
        />
      </div>
      {addNew && (
        <ManageScope
          open={addNew}
          edit={edit}
          view={view}
          handleClose={handleClose}
          formData={formData}
        />
      )}
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      {openAction && (
        <ConfirmDialog
          open={openAction}
          title="Delete Activity ?"
          data={activeData}
          message="Are you sure , to delete this Activity?"
          secondary_action="Cancel"
          primary_action="Delete"
          handleClose={handleCancelDelete}
          // handleConfirm={handleDeleteConfirm}
        />
      )}
    </div>
  );
}
