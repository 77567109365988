import React from "react";
import { Route, Redirect } from "react-router-dom";
import AdminLayout from "../../layout/adminlayout/layout.component";

const AdminAuthRoute = ({ component: Component, ...rest }) => {
  let user = rest.state.isUserAuthenticated;
  let admin = rest.state.isAdminAuthenticated;
  return (
    <Route
      {...rest}
      render={(props) =>
        !admin && !user ? (
          <AdminLayout {...props} {...rest} key={rest.location?.key}>
            <Component {...props} {...rest} key={rest.location?.key} />
          </AdminLayout>
        ) : (
          <React.Fragment>
            {user ? (
              <Redirect to="/dashboard" />
            ) : (
              <Redirect to="/admin-dashboard" />
            )}
          </React.Fragment>
        )
      }
    />
  );
};

export default AdminAuthRoute;
