import React, { useEffect, useState } from "react";
import ConfirmDialog from "../../../components/actionDialogs/confirmDialog";
import Loader from "../../../components/loader/loader";
import CustomTable from "../../../components/table/table";
import {
  admin_eventbooking_list,
  admin_eventbooking_delete,
} from "../admin.api";
import ManageBook from "./booking/manage";
import "./view.css";
import jwtDecode from "jwt-decode";

export default function View(props) {
  const [data, setdata] = useState([]);
  const [addNew, setAddNew] = useState(false);
  const [formData, setFormData] = useState({});
  const [isActive, setIsActive] = useState(true);
  const [edit, setEdit] = useState(false);
  const [view, setView] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [activeData, setActiveData] = useState({});
  let adminType = Number(jwtDecode(props.state.payload).user_type);

  const handleEventList = () => {
    setIsActive(true);

    admin_eventbooking_list().then((res) => {
      let tempData = res.data;
      tempData.forEach((item, index) => {
        item["slno"] = index + 1;
      });
      setdata(tempData);
      setIsActive(false);
      //
    });
  };
  useEffect(() => {
    handleEventList();
  }, []);

  const handleAddNew = () => {
    setAddNew(true);
    setView(false);
  };
  const handleClose = () => {
    setAddNew(false);
    setEdit(false);
    setFormData({});
    handleEventList();
  };
  const handleEdit = (data) => {
    setFormData(data);
    setView(false);
    setEdit(true);
    setAddNew(true);
  };
  const handleView = (data) => {
    setAddNew(true);
    setFormData(data);
    setView(true);
  };
  const handleDelete = (data) => {
    setActiveData(data);
    setOpenAction(true);
  };
  const handleCancelDelete = () => {
    setOpenAction(false);
  };
  const handleDeleteConfirm = (data) => {
    console.log(data);
    setOpenAction(false);
    admin_eventbooking_delete(data.uuid)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
      });
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      handleEventList();
    }
  };
  return (
    <div>
      <Loader open={isActive} />
      <div className="view-container">
        <h6>
          <b>Event Booking</b>
        </h6>
        <hr />
        {/* ---------admin-------------- */}
        {adminType === 1 && (
          <CustomTable
            {...props}
            columns={[
              { title: "No", field: "slno" },
              { title: "Event Name", field: "event_name_en" },
              { title: "User Name", field: "full_name" },
              { title: "Address", field: "address" },
              { title: "Event Date", field: "event_date" },
              {
                title: "Status",
                field: "booking_status_text",
                type: "bookingStatus",
              },
            ]}
            data={data}
            actions={[
              { type: "view", handleClick: handleView },
              // { type: "edit", handleClick: handleEdit },
              // { type: "delete", handleClick: handleDelete },
            ]}
          />
        )}
        {/* ---------inter-admin-------------- */}
        {adminType === 3 && (
          <CustomTable
            {...props}
            columns={[
              { title: "No", field: "slno" },
              { title: "Event Name", field: "event_name_en" },
              { title: "User Name", field: "full_name" },
              { title: "Address", field: "address" },
              { title: "Event Date", field: "event_date" },
              {
                title: "Status",
                field: "booking_status_text",
                type: "bookingStatus",
              },
            ]}
            data={data}
            actions={[
              { type: "view", handleClick: handleView },
              // { type: "edit", handleClick: handleEdit },
              { type: "delete", handleClick: handleDelete },
            ]}
          />
        )}
        {/* ---------super-admin-------------- */}
        {adminType === 2 && (
          <CustomTable
            {...props}
            columns={[
              { title: "No", field: "slno" },
              { title: "Event Name", field: "event_name_en" },
              { title: "User Name", field: "full_name" },
              { title: "Address", field: "address" },
              { title: "Event Date", field: "event_date" },
              {
                title: "Status",
                field: "booking_status_text",
                type: "bookingStatus",
              },
            ]}
            data={data}
            actions={[
              { type: "view", handleClick: handleView },
              // { type: "edit", handleClick: handleEdit },
              // { type: "delete", handleClick: handleDelete },
            ]}
          />
        )}
      </div>
      {addNew && (
        <ManageBook
          open={addNew}
          adminType={adminType}
          edit={edit}
          view={view}
          handleClose={handleClose}
          formData={formData}
        />
      )}
      {openAction && (
        <ConfirmDialog
          open={openAction}
          title="Delete Booking ?"
          data={activeData}
          message="Are you sure to delete this Event Booking?"
          secondary_action="Cancel"
          primary_action="Delete"
          handleClose={handleCancelDelete}
          handleConfirm={handleDeleteConfirm}
        />
      )}
    </div>
  );
}
