import React from "react";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import jwtDecode from "jwt-decode";

import CustomButton from "../../../../../components/inputs/button";
import "./dialog.css";

export function Dialog(props) {
  let lang = props.state.language;
  const handleSubmit = () => {
    // props.history.push();
    props.handleClose();
  };

  const handleClose = () => {
    sessionStorage.removeItem("active_path");
    props.handleClose();
  };
  console.log(props.data);
  let userType = props.state.payload ? jwtDecode(props.state.payload) : {};
  console.log(userType);
  let activeUserType = userType?.user?.user_type || null;
  let activeUserStatus = userType?.user?.status || null;
  console.log(activeUserType);
  console.log(activeUserStatus);
  return (
    <React.Fragment>
      <CSSTransition
        in={props.open}
        timeout={1000}
        classNames="my-node"
        unmountOnExit
      >
        
        <div className="overlay">
          <div className="big-menu-dias">
            <div className="row">
              <div className="flexDisplay">
                <div className="diaTitleCon text-center">
                  <div className="dia-menu-title">
                    {lang === "ma" ? props.data.title_ma : props.data.title_en}
                  </div>
                </div>
                <div className="dia-menu-close" onClick={(e) => handleClose(e)}>
                  <div className="diaCloseCon">
                    <div className="diaClose">&#10005;</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <hr />
            </div>
            <div className="dia-body">
              <p className="dialog-para">
                {props?.data?.specialPermissions &&
                props?.data?.specialPermissions?.length > 0 ? (
                  <>
                    {props?.data?.specialPermissions?.some(
                      (s) => s == activeUserType
                    ) && activeUserStatus == 2  ? (
                      <>
                        {lang === "ma"
                          ? props.data.desc_ma
                          : props.data.desc_en}
                      </>
                    ) : (
                      <>
                        {lang === "ma"
                          ? props.data.error_en
                          : props.data.error_en}
                        {/* {props.data.specialPermissions.includes(8) ? (
                          <>
                            {lang === "ma"
                              ? "ഈ വിഭാഗം ആക്സസ് ചെയ്യാൻ നിങ്ങൾക്ക് അനുമതിയില്ല. അക്കാദമിക് സ്ഥാപനങ്ങൾക്ക് മാത്രമാണ് ഈ വിഭാഗത്തിലേക്ക് പ്രവേശനമുള്ളത്."
                              : "You don't have permission to access this section. Only Academic Institutions have access to this section. "}
                          </>
                        ) : (
                          <>
                            {lang === "ma"
                              ? "ഈ വിഭാഗം ആക്സസ് ചെയ്യാൻ നിങ്ങൾക്ക് അനുമതിയില്ല. കെ.എസ്.എ.പി.എസ്, കേരള കമ്പനികൾക്ക് മാത്രമാണ് ഈ വിഭാഗത്തിലേക്ക് പ്രവേശനമുള്ളത്."
                              : "You don't have permission to access this section. Only kSapce and Kerala companies have access to this section. "}
                          </>
                        )} */}
                      </>
                    )}
                  </>
                ) : (
                  <>{lang === "ma" ? props.data.desc_ma : props.data.desc_en}</>
                )}
              </p>
              {/* <>{lang === "ma" ? props.data.desc_ma : props.data.desc_en}</> */}
            </div>
            {/* {(props.state.isUserAuthenticated || props.data.temp) && ( */}
            <div className="icon-to-center">
              {props?.data?.specialPermissions &&
              props?.data?.specialPermissions?.length > 0 ? (
                <>
                  {props?.data?.specialPermissions?.some(
                    (s) => s == activeUserType
                  ) && activeUserStatus == 2 ? (
                    <Link to={props.data.path ?? "/"}>
                      <CustomButton
                        label="Continue"
                        onClick={(e) => handleSubmit(e)}
                      />
                    </Link>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Link to={props.data.path ?? "/"}>
                  <CustomButton
                    label="Continue"
                    onClick={(e) => handleSubmit(e)}
                  />
                </Link>
              )}
            </div>
            {/* // )} */}
            {/* )} */}
          </div>
        </div>
      </CSSTransition>
    </React.Fragment>
  );
}

export default Dialog;
