import React, { Component } from "react";
import EventTile from "../../../../components/page/event/event-tile/event.tile.component";
import { public_event_list } from "../../public.api";

class HomeEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      url: "",
      key: 0,
    };
  }
  componentDidMount = () => {
    this.fetchEvent();
  };
  fetchEvent = () => {
    public_event_list().then((res) => {
      this.setState({
        data: res.data,
        url: res.current_url,
      });
    });
  };
  mangeViewMore = () => {
    sessionStorage.setItem("continueFrom", "events");
    this.props.history.push("/events");
  };
  render() {
    const { data, url } = this.state;
    return (
      <>
        <div className="event-tiles-container">
          {data &&
            data.map(
              (item, index) =>
                index <= 2 && (
                  <EventTile
                    {...this.props}
                    data={item}
                    url={url}
                    fromHome={true}
                  />
                )
            )}
        </div>
        <div className="pad-50">
          <div className="all-to-center ">
            <button
              className="read-more-btn-gallery"
              onClick={(e) => {
                this.mangeViewMore(e);
              }}
            >
              All events
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default HomeEvents;
