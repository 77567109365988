import apis from "../../api/api.actions";
import { parseQueryParams } from "../../api/parseParams";
let adminpath = "v1/admin";
let superadmin = "v1/superadmin";
let interadminpath = "v1/intermediate";
let publicpath = "v1/common";
//// list /////
export const admin_event_list = (data) =>
  apis.getDatas(`${adminpath}/events`).then((res) => res);
export const is_email_registered = (data) =>
  apis.createData(`email-exists`, data).then((res) => res);

export const admin_gallery_list = (data) =>
  apis.getDatas(`${adminpath}/images`).then((res) => res);
export const admin_videos_list = (data) =>
  apis.getDatas(`${adminpath}/videos`).then((res) => res);
export const admin_eventbooking_list = () =>
  apis.getDatas(`${adminpath}/event-booking`).then((res) => res);
export const admin_tender_list = (data) =>
  apis.getDatas(`${adminpath}/tenders`).then((res) => res);
export const admin_career_list = (data) =>
  apis.getDatas(`${adminpath}/career/list`).then((res) => res);
export const admin_publications_list = (data) =>
  apis.getDatas(`${adminpath}/publications`).then((res) => res);
export const admin_publications_delete = (id) =>
  apis.deleteData(`${adminpath}/publications/${id}`).then((res) => res);
export const user_company_domain_list = (data) =>
  apis.getDatas(`${publicpath}/domains-types`).then((res) => res);
export const admin_scopeact_list = (data) =>
  apis.getDatas(`${adminpath}/scope-of-activity`).then((res) => res);
export const admin_banners_list = (data) =>
  apis.getDatas(`${adminpath}/banners`).then((res) => res);
export const admin_domains_list = (data) =>
  apis.getDatas(`${adminpath}/domains-types`).then((res) => res);
export const admin_qualification_list = (data) =>
  apis.getDatas(`${publicpath}/highest-qualification`).then((res) => res);
export const admin_notification_list = (data) =>
  apis.getDatas(`${adminpath}/notifications`).then((res) => res);
export const admin_policies_list = (data) =>
  apis.getDatas(`${adminpath}/policy`).then((res) => res);
export const admin_news_list = (data) =>
  apis.getDatas(`${adminpath}/newsupdate`).then((res) => res);
export const admin_incentives_list = (data) =>
  apis.getDatas(`${adminpath}/incentive`).then((res) => res);
export const admin_accomplishment_list = (data) =>
  apis.getDatas(`${adminpath}/our-accomplishments`).then((res) => res);
export const admin_quotes_list = (data) =>
  apis.getDatas(`${adminpath}/testimonials`).then((res) => res);
export const admin_about_list = (data) =>
  apis.getDatas(`${adminpath}/about-us`).then((res) => res);
export const admin_org_list = (data) =>
  apis.getDatas(`${adminpath}/organization-structure`).then((res) => res);
export const admin_orgs = (data) =>
  apis.getDatas(`${adminpath}/org-list`).then((res) => res);
export const admin_applicationss_list = (data, domain) =>
  apis
    .getDatas(
      `${adminpath}/application-request-list?status=${data ?? ""}${
        domain ?? ""
      }`
    )
    .then((res) => res);
export const admin_applications_view = (data) =>
  apis.getDatas(`${adminpath}/application-view/${data}`).then((res) => res);
export const admin_applications_search = (data) =>
  apis
    .getDatas(`${adminpath}/application_search${parseQueryParams(data)}`)
    .then((res) => res);

export const admin_app_submit = (data) =>
  apis
    .createData(`${adminpath}/application-status_update`, data)
    .then((res) => res);

export const common_file_upload = (data) =>
  apis.createData(`${publicpath}/file/upload`, data).then((res) => res);

export const admin_msg_status_change = (id) =>
  apis.createData(`${adminpath}/read-status/${id}`).then((res) => res);
export const admin_messsage_view = (data) =>
  apis
    .getDatas(`${adminpath}/admin_application_logs/${data}`)
    .then((res) => res);
export const superadmin_messsage_view = (data) =>
  apis
    .getDatas(`${superadmin}/admin_application_logs/${data}`)
    .then((res) => res);
export const superadmin_app_submit = (data) =>
  apis
    .createData(`${superadmin}/application-status_update`, data)
    .then((res) => res);
export const superadmin_applications_view = (data) =>
  apis.getDatas(`${superadmin}/application-view/${data}`).then((res) => res);
export const superadmin_app_list = (data) =>
  apis.getDatas(`${superadmin}/application-request-list`).then((res) => res);
export const superadmin_status = (data) =>
  apis.getDatas(`${superadmin}/superadmin-status/${data}`).then((res) => res);

export const admin_status = (data) =>
  apis.getDatas(`${adminpath}/admin-status/${data}`).then((res) => res);
export const dash_info = () =>
  apis.getDatas(`${adminpath}/user-dashboard`).then((res) => res);
export const admin_spacearea_list = (data) =>
  apis.getDatas(`${adminpath}/space-area-types/${data}`).then((res) => res);
export const admin_basearea_list = (data) =>
  apis.getDatas(`${adminpath}/base-area`).then((res) => res);
export const admin_location_list = (data) =>
  apis.getDatas(`${adminpath}/locations`).then((res) => res);

export const getUserStatuses = () =>
  apis.getDatas(`${adminpath}/user-status`).then((res) => res);

//////////////////////////////
///// create ///////////
export const admin_publications_create = (data) =>
  apis.createData(`${adminpath}/publications`, data).then((res) => res);
export const admin_spacearea_create = (data) =>
  apis.createData(`${adminpath}/space-area-types`, data).then((res) => res);
export const admin_domains_add = (data) =>
  apis.createData(`${adminpath}/domains-types`, data).then((res) => res);
export const admin_gallery_add = (data) =>
  apis.createData(`${adminpath}/images`, data).then((res) => res);
export const admin_videos_add = (data) =>
  apis.createData(`${adminpath}/videos`, data).then((res) => res);
export const admin_notification_add = (data) =>
  apis.createData(`${adminpath}/notifications`, data).then((res) => res);
export const admin_policy_add = (data) =>
  apis.createData(`${adminpath}/policy`, data).then((res) => res);
export const admin_news_add = (data) =>
  apis.createData(`${adminpath}/newsupdate`, data).then((res) => res);
export const admin_incentive_add = (data) =>
  apis.createData(`${adminpath}/incentive`, data).then((res) => res);
export const admin_policy_edit = (id, data) =>
  apis.createData(`${adminpath}/policy/${id}`, data).then((res) => res);
export const admin_news_edit = (id, data) =>
  apis.createData(`${adminpath}/newsupdate/${id}`, data).then((res) => res);
export const admin_incentives_edit = (id, data) =>
  apis.createData(`${adminpath}/incentive/${id}`, data).then((res) => res);
export const admin_banner_add = (data) =>
  apis.createData(`${adminpath}/banners`, data).then((res) => res);
export const admin_accomplishment_add = (data) =>
  apis.createData(`${adminpath}/our-accomplishments`, data).then((res) => res);
export const admin_quotes_add = (data) =>
  apis.createData(`${adminpath}/testimonials`, data).then((res) => res);
export const admin_spaceparkadvisers_add = (data) =>
  apis
    .createData(`${adminpath}/create/space-park-advisor`, data)
    .then((res) => res);

export const admin_scope_add = (data) =>
  apis.createData(`${adminpath}/scope-of-activity`, data).then((res) => res);
export const admin_org_add = (data) =>
  apis
    .createData(`${adminpath}/organization-structure`, data)
    .then((res) => res);
export const admin_orgstre_add = (data) =>
  apis.createData(`${adminpath}/main-category`, data).then((res) => res);
export const admin_event_add = (data) =>
  apis.createData(`${adminpath}/events`, data).then((res) => res);
// export const admin_event_booking_accept = (id) =>
//   apis.getDatas(`${adminpath}/booking-approval/${id}`).then((res) => res);

export const admin_event_booking_accept = (id) =>
  apis.getDatas(`${adminpath}/booking-status-update/${id}`).then((res) => res);
export const interadmin_event_booking_accept = (id) =>
  apis.getDatas(`${interadminpath}/booking-approval/${id}`).then((res) => res);
export const interadmin_event_booking_reject = (id) =>
  apis.getDatas(`${interadminpath}/booking-reject/${id}`).then((res) => res);
export const admin_event_booking_reject = (id) =>
  apis.getDatas(`${adminpath}/booking-reject/${id}`).then((res) => res);
export const tech_transfer_data = () =>
  apis.getDatas(`${publicpath}/category-organization`).then((res) => res);

export const admin_tender_add = (data) =>
  apis.createData(`${adminpath}/tenders`, data).then((res) => res);
export const admin_careers_add = (data) =>
  apis.createData(`${adminpath}/career/create`, data).then((res) => res);
export const admin_tech_prop_add = (data) =>
  apis
    .createData(`${adminpath}/technology-transfer/create`, data)
    .then((res) => res);
////////////////////////////
///// update ///////////

export const admin_spaceparkadvisers_edit = (id, data) =>
  apis
    .createData(`${adminpath}/edit/space-park-advisor/${id}`, data)
    .then((res) => res);
export const admin_spacearea_update = (id, data) =>
  apis
    .updateData(`${adminpath}/space-area-types/${id}`, data)
    .then((res) => res);
export const admin_domains_update = (id, data) =>
  apis.updateData(`${adminpath}/domains-types/${id}`, data).then((res) => res);
export const admin_qualifications_update = (id, data) =>
  apis.createData(`${publicpath}/highest-qualification/${id}`, data).then((res) => res);
export const admin_gallery_update = (id, data) =>
  apis.createData(`${adminpath}/images`, data).then((res) => res);
export const admin_videos_update = (id, data) =>
  apis.createData(`${adminpath}/videos`, data).then((res) => res);
export const admin_notification_update = (id, data) =>
  apis.updateData(`${adminpath}/notifications/${id}`, data).then((res) => res);
export const admin_aboutus_update = (id, data) =>
  apis.updateData(`${adminpath}/about-us/${id}`, data).then((res) => res);
export const admin_banner_update = (id, data) =>
  apis.updateData(`${adminpath}/banners/${id}`, data).then((res) => res);
export const admin_accomplishment_update = (id, data) =>
  apis
    .updateData(`${adminpath}/our-accomplishments/${id}`, data)
    .then((res) => res);

export const admin_tender_update = (id, data) =>
  apis.createData(`${adminpath}/tenders/${id}`, data).then((res) => res);
export const admin_career_update = (id, data) =>
  apis.createData(`${adminpath}/career/update/${id}`, data).then((res) => res);
//////////////////////////
///// delete ///////////
export const admin_spacearea_delete = (data) =>
  apis
    .deleteData(`${adminpath}/space-area-types/${data}`, data)
    .then((res) => res);
export const admin_domains_delete = (data) =>
  apis.deleteData(`${adminpath}/domains-types/${data}`).then((res) => res);
export const admin_gallery_delete = (id) =>
  apis.deleteData(`${adminpath}/images/${id}`).then((res) => res);
export const admin_videos_delete = (id) =>
  apis.deleteData(`${adminpath}/videos/${id}`).then((res) => res);
export const admin_notification_delete = (id) =>
  apis.deleteData(`${adminpath}/notifications/${id}`).then((res) => res);
export const admin_policy_delete = (id) =>
  apis.deleteData(`${adminpath}/policy/${id}`).then((res) => res);
export const admin_news_delete = (id) =>
  apis.deleteData(`${adminpath}/newsupdate/${id}`).then((res) => res);
export const admin_incentives_delete = (id) =>
  apis.deleteData(`${adminpath}/incentive/${id}`).then((res) => res);
export const admin_banner_delete = (id) =>
  apis.deleteData(`${adminpath}/banners/${id}`).then((res) => res);
export const admin_quotes_delete = (id) =>
  apis.deleteData(`${adminpath}/testimonials/${id}`).then((res) => res);
export const admin_org_delete = (id) =>
  apis
    .deleteData(`${adminpath}/organization-structure/${id}`)
    .then((res) => res);
export const admin_event_delete = (id) =>
  apis.deleteData(`${adminpath}/events-delete/${id}`).then((res) => res);
export const admin_eventbooking_delete = (id) =>
  apis.deleteData(`${interadminpath}/events-delete/${id}`).then((res) => res);
export const admin_tender_delete = (id) =>
  apis.deleteData(`${adminpath}/tenders/${id}`).then((res) => res);
export const admin_career_delete = (id) =>
  apis.deleteData(`${adminpath}/career/delete/${id}`).then((res) => res);
export const admin_advisors_delete = (data) =>
  apis
    .deleteData(`${superadmin}/delete/space-park-advisors/${data}`)
    .then((res) => res);

export const admin_expman_delete = (data) =>
  apis
    .deleteData(`${superadmin}/expert-man-power/delete/${data}`)
    .then((res) => res);
export const admin_cand_delete = (data) =>
  apis
    .deleteData(`${superadmin}/candidate/delete/${data}`)
    .then((res) => res);

export const admin_resper_delete = (data) =>
  apis
    .deleteData(`${superadmin}/resource-person/delete/${data}`)
    .then((res) => res);

export const admin_bussprop_delete = (data) =>
  apis
    .deleteData(`${superadmin}/business-idea/delete/${data}`)
    .then((res) => res);
export const admin_techprop_delete = (data) =>
  apis
    .deleteData(`${superadmin}/technology-transfer/delete/${data}`)
    .then((res) => res);
////////////////////////////////////////////////////////////////////////
/////////////////// Detail ////////////////////////////////////////////

export const admin_event_view = (data) =>
  apis.getDatas(`${adminpath}/events/${data}`).then((res) => res);

export const admin_company_details = (data) =>
  apis.getDatas(`${adminpath}/company/${data}`).then((res) => res);
export const update_company_registration = (id, data) =>
  apis.createData(`${adminpath}/update-company/${id}`, data).then((res) => res);
export const create_company_request = (data) =>
  apis.createData(`${adminpath}/create-company`, data).then((res) => res);
export const get_technology_proposal_list = (id) =>
  apis
    .getDatas(
      `${
        id === 1 ? adminpath : id === 2 ? superadmin : adminpath
      }/technology-transfer/list`
    )
    .then((res) => res);

export const get_project_proposal_list = (id) =>
  apis
    .getDatas(
      `${
        id === 1 ? adminpath : id === 2 ? superadmin : adminpath
      }/business-idea/list-proposal`
    )
    .then((res) => res);
export const get_project_proposal_details = (id) =>
  apis.getDatas(`${adminpath}/business-idea/details/${id}`).then((res) => res);

export const get_tech_proposal_details = (id) =>
  apis
    .getDatas(
      `${
        id === 1 ? adminpath : id === 2 ? superadmin : adminpath
      }/technology-transfer/view/${id}`
    )
    .then((res) => res);

export const get_advisorsList_list = (id) =>
  apis
    .getDatas(
      `${
        id === 1 ? adminpath : id === 2 ? superadmin : adminpath
      }/list/space-park-advisors`
    )
    .then((res) => res);

export const get_adviser_details = (id) =>
  apis
    .getDatas(
      `${
        id === 1 ? adminpath : id === 2 ? superadmin : adminpath
      }/view/space-park-advisor/${id}`
    )
    .then((res) => res);
export const update_adviser_status = (id, data) =>
  apis
    .updateData(`${superadmin}/update/space-park-advisor/${id}`, data)
    .then((res) => res);
export const update_tech_proposal_status = (admin, id, data) =>
  apis
    .updateData(
      `${
        admin === 1 ? adminpath : admin === 2 ? superadmin : adminpath
      }/technology-transfer/update/${id}`,
      data
    )
    .then((res) => res);

export const update_proposal_status = (data) =>
  apis
    .createData(`${adminpath}/business-idea/update-status`, data)
    .then((res) => res);
export const get_manPowerList_list = (id) =>
  apis
    .getDatas(
      `${
        id === 1 ? adminpath : id === 2 ? superadmin : adminpath
      }/expert-man-power/list`
    )
    .then((res) => res);
export const get_candidates_list = (id) =>
  apis.getDatas(`${adminpath}/candidate`).then((res) => res);
export const admin_candidates_list = (id) =>
  apis.getDatas(`${adminpath}/candidate`).then((res) => res);
export const admin_downloaded_candidates_list = (id) =>
  apis.getDatas(`${adminpath}/candidates/profiles/${id}`).then((res) => res);
export const get_companyProfile_list = (id) =>
  apis.getDatas(`${adminpath}/candidates/profiles`).then((res) => res);

export const expertManPowerCreateLookup = (data) =>
  apis.getDatas(`${publicpath}/expert-man-power/look-up`).then((res) => res);

export const expertManPowerCreate = (data) =>
  apis
    .createData(`${adminpath}/expert-man-power/create`, data)
    .then((res) => res);
export const get_manpower_details = (id) =>
  apis
    .getDatas(
      `${
        id === 1 ? adminpath : id === 2 ? superadmin : adminpath
      }/expert-man-power/view/${id}`
    )
    .then((res) => res);
export const get_candidates_details = (id) =>
  apis.getDatas(`${publicpath}/candidate/${id}`).then((res) => res);
export const get_admin_candidates_details = (id) =>
  apis.getDatas(`${adminpath}/candidate/${id}`).then((res) => res);
export const get_admin_downloaded_candidates_details = (data) =>
  apis.createData(`${adminpath}/candidates/detail`, data).then((res) => res);

export const update_manpower_status = (data) =>
  apis.updateData(`${adminpath}/em-status`, data).then((res) => res);
export const update_jobseeker_status = (data, id) =>
  apis.updateData(`${adminpath}/candidate-certificate/status/${id}
`, data).then((res) => res);
export const update_candidates_status = (data, id) =>
  apis
    .updateData(`${adminpath}/candidate-masterupdate/${id}`, data)
    .then((res) => res);
export const update_admin_candidates_status = (data, id) =>
  apis
    .updateData(`${adminpath}/candidate-update/${id}`, data)
    .then((res) => res);
export const admin_candidates_reminder = (data, id) =>
  apis
    .createData(`${adminpath}/candidates/profiles/reminder/${id}`, data)
    .then((res) => res);
export const admin_candidates_delete = (id) =>
  apis
    .deleteData(`${adminpath}/candidates/profiles/delete/${id}`)
    .then((res) => res);
export const admin_candidates_confirm = (id) =>
  apis
    .createData(`${adminpath}/candidates/profiles/confirmation/${id}`)
    .then((res) => res);

export const adminresourcePersonalCreate = (data) =>
  apis
    .createData(
      `${adminpath}/resource-person/create`,
      data
      // {
      // headers: { Authorization: null },
      // }
    )
    .then((res) => res);

export const get_resList_list = (id) =>
  apis
    .getDatas(
      `${
        id === 1 ? adminpath : id === 2 ? superadmin : adminpath
      }/resource-person/list`
    )
    .then((res) => res);

export const get_resourse_details = (id) =>
  apis
    .getDatas(
      `${
        id === 1 ? adminpath : id === 2 ? superadmin : adminpath
      }/resource-person/view/${id}`
    )
    .then((res) => res);

export const update_resourse_status = (data) =>
  apis.updateData(`${adminpath}/rp-status`, data).then((res) => res);
///////////////////////////////////////////////////////////////////////////

export const user_company_list = (data) =>
  apis
    .getDatas(`v1/admin/companies${parseQueryParams(data)}`)
    .then((res) => res);

export const user_company_status = (data) =>
  apis.updateData(`${adminpath}/company-status`, data).then((res) => res);

export const institutions_list = (data) =>
  apis.getDatas(`${adminpath}/institutions`).then((res) => res);
export const institutions_detail = (data) =>
  apis.getDatas(`${adminpath}/institution/${data}`).then((res) => res);
export const institutions_status_update = (data) =>
  apis.updateData(`${adminpath}/institution-status`, data).then((res) => res);

export const studentProposalList = (data) =>
  apis.getDatas(`${adminpath}/student-proposals`).then((res) => res);
export const studentProposaldetails = (data) =>
  apis.getDatas(`${adminpath}/proposal/${data}`).then((res) => res);

export const student_prop_status_update = (data) =>
  apis
    .createData(`${adminpath}/student-proposals-status`, data)
    .then((res) => res);

export const super_comp_delete = (data) =>
  apis.deleteData(`${superadmin}/company/${data}`).then((res) => res);
export const admin_csv = (data) =>
  apis.createData(`${adminpath}/upload-companies`, data).then((res) => res);

export const super_newcomp_delete = (data) =>
  apis
    .deleteData(`${superadmin}/application-delete/${data}`)
    .then((res) => res);

export const super_insti_delete = (data) =>
  apis.deleteData(`${superadmin}/institution/${data}`).then((res) => res);

export const studentInternList = (data) =>
  apis.getDatas(`${adminpath}/internship-proposals`).then((res) => res);
export const studentInterndetails = (data) =>
  apis.getDatas(`${adminpath}/intern-proposal/${data}`).then((res) => res);

export const studentintern_status_update = (data) =>
  apis
    .createData(`${adminpath}/internship-proposals-status`, data)
    .then((res) => res);

export const admin_intern_delete = (id) =>
  apis.deleteData(`${superadmin}/internship/${id}`).then((res) => res);

export const admin_student_prop_delete = (id) =>
  apis.deleteData(`${superadmin}/student-proposal/${id}`).then((res) => res);
