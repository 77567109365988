import React, { Component } from "react";
import { Link } from "react-router-dom";
import breadcrumbConfig from "./breadcrumbConfig";
import "./breadcrumb.css";

class BreadCrumbsComp extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    return (
      //col-md-6 to col-md-12
      <div className="breadcrumb-wrapper">
        <ol className="breadcrumb">
          {this.props.section ? (
            breadcrumbConfig[this.props.section].map((url, index) => (
              <li
                key={index}
                className={
                  "breadcrumb-item" + (url.isActive ? " selactive" : "")
                }
              >
                {url.path !== null &&
                url.path !== undefined &&
                url.path !== "" ? (
                  <Link to={url.path}>{url.name}</Link>
                ) : (
                  <a style={{ textDecoration: "none", cursor: "default" }}>
                    {url.name}
                  </a>
                )}
              </li>
            ))
          ) : (
            <></>
          )}
        </ol>
      </div>
    );
  }
}

export default BreadCrumbsComp;
