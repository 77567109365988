import React from "react";
import "./style.css";

const ProgressBar = ({ progress, iconName, label }) => {
  let progreslen = 100;
  progreslen = progress && progress.length;

  let proTot = 10 ** progreslen - 1;
  let prog = (progress / proTot) * 100;
  //
  // const Parentdiv = {
  //   height: "8px",
  //   display: "flex",
  //   width: "200px",
  //   // alignItems: "center",
  //   backgroundColor: "whitesmoke",
  //   borderRadius: 40,
  //   // margin: 50,
  // };

  const Childdiv = {
    height: "100%",
    width: `${prog}%`,
    backgroundColor: "#009FE3",
    borderRadius: 40,
    textAlign: "right",
  };

  const progCont = {
    // alignItems: "center",
    margin: "5px 0px",
    // justifyContent: "center",
    display: "flex",
  };
  const icon = {
    height: "22px",
    lineHeight: "22px",
    color: "grey",
    paddingRight: "10px",
  };

  return (
    <div>
      <div className="d-flex">
        <div className="progressInd">{`${progress}`}</div>
      </div>
      <div style={progCont}>
        <div className="parentdiv">
          <div style={Childdiv}></div>
        </div>{" "}
      </div>
      <div className="label" title={label}>
        {label}
      </div>
    </div>
  );
};

export default ProgressBar;
