import React, { Component } from "react";
import {
  EmailValidate,
  FormValidate,
} from "../../../../../components/formValidate";
import CustomButton from "../../../../../components/inputs/button";
import Snackbar from "../../../../../components/snackbar/snackbar";
import { user_event_booking } from "../../../../../pages/user/user.api";
import TextField from "../../../../inputs/textfield";
import Loader from "../../../../loader/loader";
import "./dialog.css";

let required = ["full_name", "contact_no", "address", "email"];
let required1 = [
  "full_name",
  "contact_no",
  "address",
  "email",
  "reference_no",
  "paid_amount",
];

class BookingDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      formData: {},
      edit: props.edit,
      submitted: false,
      snackMessage: "",
      emailError: false,
      isActive: false,
      isSnackError: false,
      snackOpen: false,
      event: props.event,
    };
  }

  componentWillReceiveProps = (props) => {
    this.setState({
      open: props.open,
      edit: props.edit,
      formData: props.formData,
      event: props.event,
    });
  };
  handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    if (e.target.name === "email") {
      let erroremail = !EmailValidate(e.target.value);

      this.setState({
        emailError: erroremail,
      });
    }
    this.setState({ formData: { ...this.state.formData, [name]: value } });
  };
  snackbarClose = (flag) => {
    this.setState({
      snackMessage: "",
      isSnackError: false,
      snackOpen: false,
    });
    if (!flag) {
      this.props.handleClose();
    }
  };
  handleSubmit = (e) => {
    const { emailError, event } = this.state;
    let reqType = event.paid === "0" ? required : required1;
    const errors = FormValidate(this.state.formData, reqType);

    const { edit, formData } = this.state;
    e.preventDefault();

    if (Object.keys(errors).length === 0) {
      if (!emailError) {
        if (formData.contact_no.length === 10) {
          let data = formData;
          data = { ...data, event_id: this.state.event.id };
          this.setState({ isActive: true, submitted: true });
          user_event_booking(data)
            .then((res) => {
              this.setState({
                submitted: true,
                isActive: false,
                snackMessage: res.message,
                isSnackError: false,
                snackOpen: true,
              });
            })
            .catch((error) => {
              this.setState({
                submitted: false,
                isActive: false,
                snackMessage: error.message,
                isSnackError: true,
                snackOpen: true,
              });
            });
        } else {
          this.setState({
            submitted: false,
            snackMessage: "Enter a valid 10 digit Contact Number",
            isSnackError: true,
            snackOpen: true,
          });
        }
      } else {
        this.setState({
          submitted: false,
          snackMessage: "Please enter a valid email address",
          isSnackError: true,
          snackOpen: true,
        });
      }
    } else {
      this.setState({
        submitted: false,
        snackMessage: "Please fill all mandatory fields",
        isSnackError: true,
        snackOpen: true,
      });
    }
  };
  handlePhoneNumberChange = (e) => {
    //
    let name = e.target.name;
    let value = e.target.value;
    //
    //
    let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    this.setState({ formData: { ...this.state.formData, [name]: newValue } });
  };
  render() {
    const { submitted, Message, isActive, isError, open, formData, event } =
      this.state;
    //
    return (
      <React.Fragment>
        {open && (
          <React.Fragment>
            <div className="overlay">
              <div className="big-dia-book">
                <Snackbar
                  message={this.state.snackMessage}
                  error={this.state.isSnackError}
                  open={this.state.snackOpen}
                  close={this.snackbarClose}
                />
                <Loader open={isActive} />
                <div className="row">
                  <div className="book-head">Book an Event</div>
                  <div className="dia-close" onClick={this.props.handleClose}>
                    <div className="diaCloseCon">
                      <div className="diaClose">&#10005;</div>
                    </div>
                  </div>
                  <div className="tilebar-book">
                    <hr />
                  </div>
                  <form action="">
                    <div className="book-form">
                      <div className="col-12">
                        <TextField
                          id="outlined-basic"
                          label="Full Name"
                          variant="outlined"
                          onChange={(e) => this.handleChange(e)}
                          value={formData.full_name ?? ""}
                          name="full_name"
                          required
                        />
                      </div>
                      <div className="col-12">
                        <TextField
                          id="outlined-basic"
                          label="Contact Number"
                          variant="outlined"
                          value={formData.contact_no ?? ""}
                          name="contact_no"
                          required
                          maxLength="10"
                          minLength="10"
                          pattern="[0-9]{10}"
                          onChange={(e) => this.handlePhoneNumberChange(e)}
                          type="text"
                        />
                      </div>
                      <div className="col-12">
                        <TextField
                          id="outlined-basic"
                          label="Email"
                          variant="outlined"
                          onChange={(e) => this.handleChange(e)}
                          value={formData.email ?? ""}
                          name="email"
                          type="email"
                          // maxLength="11"
                          required
                        />
                      </div>
                      <div className="col-12">
                        <TextField
                          id="outlined-basic"
                          label="Address"
                          variant="outlined"
                          onChange={(e) => this.handleChange(e)}
                          value={formData.address ?? ""}
                          name="address"
                          multiline
                          required
                        />
                      </div>
                      {event.paid !== "0" ? (
                        <div className="col-12">
                          <TextField
                            id="outlined-basic"
                            label="Reference Number"
                            variant="outlined"
                            onChange={(e) => this.handleChange(e)}
                            value={formData.reference_no ?? ""}
                            name="reference_no"
                            required
                          />
                        </div>
                      ) : null}
                      {event.paid !== "0" ? (
                        <div className="col-12">
                          <TextField
                            id="outlined-basic"
                            label="Paid Amount"
                            type="number"
                            variant="outlined"
                            onChange={(e) => this.handleChange(e)}
                            value={formData.paid_amount ?? ""}
                            name="paid_amount"
                            required
                          />
                        </div>
                      ) : null}
                      {event.paid !== "0" ? (
                        <div className="col-12">
                          <TextField
                            id="outlined-basic"
                            label="Fee to be Paid"
                            variant="outlined"
                            value={event.paid}
                            disabled
                          />
                        </div>
                      ) : null}
                      <div className="col-12">
                        <CustomButton
                          label="Book"
                          disabled={submitted}
                          onClick={(e) => this.handleSubmit(e)}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default BookingDialog;
