import apis from "../../api/api.actions";
let publicpath = "v1/common";
export const public_banner_list = (data) =>
  apis.getDatas(`${publicpath}/banners`).then((res) => res);
export const public_news_list = (data) =>
  apis.getDatas(`${publicpath}/notifications`).then((res) => res);
export const public_scope_list = (data) =>
  apis.getDatas(`${publicpath}/scope-of-activity`).then((res) => res);

export const public_event_list = (data) =>
  apis.getDatas(`${publicpath}/events`).then((res) => res);
export const public_event_details = (id) =>
  apis.getDatas(`${publicpath}/events/${id}`).then((res) => res);
export const public_news_details = (id) =>
  apis.getDatas(`${publicpath}/notifications/${id}`).then((res) => res);

export const public_about_list = (data) =>
  apis.getDatas(`${publicpath}/about-us`).then((res) => res);

export const public_gallery_list = (data) =>
  apis.getDatas(`${publicpath}/images`).then((res) => res);
export const public_videos_list = (data) =>
  apis.getDatas(`${publicpath}/videos`).then((res) => res);
export const public_orgstruc_list = (data) =>
  apis.getDatas(`${publicpath}/organization-structure`).then((res) => res);

export const public_quotes_list = (data) =>
  apis.getDatas(`${publicpath}/testimonials`).then((res) => res);
export const public_accomplishments_list = (data) =>
  apis.getDatas(`${publicpath}/accomplishments`).then((res) => res);

export const admin_scopeact_list = (data) =>
  apis.getDatas(`${publicpath}/scope-of-activity`).then((res) => res);
export const admin_banners_list = (data) =>
  apis.getDatas(`${publicpath}/banners`).then((res) => res);
export const admin_domains_list = (data) =>
  apis.getDatas(`${publicpath}/domains-types`).then((res) => res);

export const spaceParkAdvisorsList = (data) =>
  apis.getDatas(`${publicpath}/space-park-advisors`).then((res) => res);
